import { handleActions } from "redux-actions";

import * as actionCreators from "../actionCreators/miscellaneous";

const defaultState = {
  message: "",
  isVisible: false,
};

const miscellaneousReducer = handleActions(
  {
    [actionCreators.doManageNotification]: (state, { payload }) => {
      return { ...state, isVisible: payload.isVisible, message: payload.message };
    },
  },
  defaultState
);

export default miscellaneousReducer;
