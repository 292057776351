import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import img from '../../assets/images/glass.png';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import './index.css';
import routes from '../../config/routes';

function NoOrders() {
  return (
    <div className="no-orders">
      <img src={img} alt="magnifying-glass" className="no-order-img" />
      <h3 className="no-orders-text">No Orders Found!</h3>
      <Button
        className="btn btn-primary btn-no-order"
        component={RouterLink}
        to={routes.dashboard.order}
      >
        GO TO DASHBOARD
      </Button>
    </div>
  );
}

export default NoOrders;
