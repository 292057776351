import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import img from '../../assets/images/shopping-cart.png';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import './index.css';
import routes from '../../config/routes';

function EmptyCart() {
  return (
    <div className="empty-cart">
      <img src={img} alt="Shopping Cart" className="empty-cart-img" />
      <h3 className="empty-cart-text">Your Cart is Currently Empty!</h3>
      <Button
        className="btn btn-primary btn-empty-cart"
        component={RouterLink}
        to={routes.dashboard.order}
      >
        GO TO DASHBOARD
      </Button>
    </div>
  );
}

export default EmptyCart;
