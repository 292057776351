export const Items = [
  {
    name: 'Breakfast',
    // description: 'YOUR FAVOURITE MEAL SERVED ALL DAY LONG.',
    image: 'images/menu/breakfast.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/breakfast.png',
      },
      {
        menuImg: 'images/menu/menuItems/breakfast2.png',
      },
    ],
  },
  {
    name: 'Bruschetta',
    //  description: "THERE'S ALWAYS A NEW FLAVOUR TO EXPLORE.",
    image: 'images/menu/toast.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/bruschetta.png',
      },
    ],
  },
  {
    name: 'Pizza',
    //  description: 'BITE SIZE PIZZA? COMING RIGHT UP.',
    image: 'images/menu/pizza.png',
    menuItem: [{ menuImg: 'images/menu/menuItems/pizza.png' }],
  },
  {
    name: 'Sandwich',
    //  description: 'HUMBLE YUMMY FLAVOURS.',
    image: 'images/menu/sandwich.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/sandwich.png',
      },
    ],
  },
  {
    name: 'Burger',
    //  description: 'EVERY MEAT LOVERS FAVOURITE.',
    image: 'images/menu/burger.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/burger.png',
      },
    ],
  },
  {
    name: 'Salad',
    //  description: 'FRESHNESS WITH FLAVOUR GUARANTEED.',
    image: 'images/menu/salad.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/salad.png',
      },
    ],
  },
  {
    name: 'Pasta',
    //  description: 'LOOKS AS GOOD AS IT TASTES!',
    image: 'images/menu/pasta.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/pasta.png',
      },
    ],
  },
  {
    name: 'Main Course',
    //  description: '',
    image: 'images/menu/main-course.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/main-course.png',
      },
    ],
  },
  {
    name: 'International street Food',
    //  description: '',
    image: 'images/menu/intl-street-food-main.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/intl-street-food.png',
      },
      {
        menuImg: 'images/menu/menuItems/intl-street-food2.png',
      },
    ],
  },
  {
    name: 'Hot Beverage',
    //  description: '',
    image: 'images/menu/hot-beverage.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/hot-beverage.png',
      },
    ],
  },
  {
    name: 'Cold Beverage',
    //  description: 'SLUSHY OR COLD COFFEE?',
    image: 'images/menu/cold-drink.png',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/cold-drink.png',
      },
    ],
  },
  /* {
    name: 'Beverages',
    description: 'SLUSHY OR COLD COFFEE?',
    image: 'images/menu/beverages.jpg',
    menuItem: [
      {
        menuImg: 'images/menu/menuItems/beverages.jpg',
      },
       {
        menuImg: 'images/menu/menuItems/beverage2.jpg',
      },
    ],
  },
   {
     name: 'Desserts',
     description: 'SWEET TOOTH CRAVINGS SATISFIED.',
     image: 'images/menu/dessert.jpg',
     menuItem: [
       {
         menuImg: 'images/menu/menuItems/desserts.jpg',
       },
     ],
   },*/
];
