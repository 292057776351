export const READ_ADDRESSES_REQUESTED = 'addresses/READ_ADDRESSES_REQUESTED';
export const READ_ADDRESSES_SUCCEEDED = 'addresses/READ_ADDRESSES_SUCCEEDED';
export const READ_ADDRESSES_FAILED = 'addresses/READ_ADDRESSES_FAILED';

export const ADD_ADDRESS_REQUESTED = 'addresses/ADD_ADDRESS_REQUESTED';
export const ADD_ADDRESS_SUCCEEDED = 'addresses/ADD_ADDRESS_SUCCEEDED';
export const ADD_ADDRESS_FAILED = 'addresses/ADD_ADDRESS_FAILED';

export const REMOVE_ADDRESS_REQUESTED = 'addresses/REMOVE_ADDRESS_REQUESTED';
export const REMOVE_ADDRESS_SUCCEEDED = 'addresses/REMOVE_ADDRESS_SUCCEEDED';
export const REMOVE_ADDRESS_FAILED = 'addresses/REMOVE_ADDRESS_FAILED';

export const UPDATE_ADDRESS_REQUESTED = 'addresses/UPDATE_ADDRESS_REQUESTED';
export const UPDATE_ADDRESS_SUCCEEDED = 'addresses/UPDATE_ADDRESS_SUCCEEDED';
export const UPDATE_ADDRESS_FAILED = 'addresses/UPDATE_ADDRESS_FAILED';

export const SET_ACTIVE_ADDRESS = 'addresses/SET_ACTIVE_ADDRESS';
export const CLEAR_ACTIVE_ADDRESS = 'addresses/CLEAR_ACTIVE_ADDRESS';

export const OPEN_ADDRESS_DIALOG = 'addresses/OPEN_ADDRESS_DIALOG';
export const CLOSE_ADDRESS_DIALOG = 'addresses/CLOSE_ADDRESS_DIALOG';
