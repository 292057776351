import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Divider, withStyles, withWidth } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallIcon from '@material-ui/icons/Call';
import ChatIcon from '@material-ui/icons/Chat';

import routes from '../../config/routes';
import { Copyright } from '@material-ui/icons';

const LinkText = withStyles({
  root: {
    color: '#f8e9d9',
    fontSize: '0.875rem',
    fontWeight: 400,
  },
})(Typography);

const LinkTitle = withStyles({
  root: {
    color: '#FFF',
    fontSize: '1rem',
    fontWeight: 600,
  },
})(Typography);

const LinkGrid = withStyles({
  root: {
    paddingBottom: '0.25rem',
  },
})(Grid);

const styles = (theme) => {
  return {
    link: {
      color: '#f8e9d9',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    iconLink: {
      color: '#f8e9d9',
      marginRight: '0.5rem',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    iconWhite: {
      color: '#f8e9d9',
      marginRight: '4px',
    },
    textWhite: {
      color: '#f8e9d9',
    },
    footer: {
      backgroundColor: '#623912',
    },
    line: {
      backgroundColor: '#f8e9d9',
      width: '80%',
      margin: '0 auto',
      opacity: '0.1',
    },
    copyRight: {
      margin: '5px',
    },
    credits: {
      fontSize: '14px',
      padding: '5px',
      color: '#f8e9d9',
    },
    copyrightIcon: {
      fontSize: '14px !important',
      color: '#f8e9d9',
    },
    lastLine: {
      fontSize: '15px',
      padding: '5px',
      color: '#fff',
      fontStyle: 'italic',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        textAlign: 'center',
        margin: '0 auto',
      },
    },
  };
};

const AppFooter = ({ width, classes }) => {
  return (
    <Box className={clsx('w-full pt-16 sm:pt-32 pb-4', classes.footer)}>
      <Grid
        container
        direction={width === 'xs' ? 'column' : 'row'}
        justify="space-evenly"
        alignItems="center"
        className="p-3 w-screen"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          className={`flex flex-col ${width === 'xs' ? 'items-center' : 'items-start'} `}
        >
          <Box className="flex flex-col items-center p-4">
            <Link to={routes.public.home}>
              <img src="/images/logo-white.png" alt="Myles High Bakehouse" />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container justify="space-between" alignItems="flex-start" className=" pt-4">
            <Grid item xs={6} sm={6} md={4} className="pb-4">
              <Grid container direction="column" alignItems="flex-start">
                <LinkGrid item>
                  <LinkTitle>Customer</LinkTitle>
                </LinkGrid>
                <LinkGrid item>
                  <Link className={classes.link} to={routes.public.privacyPolicy}>
                    <LinkText>Privacy policy</LinkText>
                  </Link>
                </LinkGrid>
                <LinkGrid item>
                  <HashLink
                    className={classes.link}
                    to={routes.public.termsAndConditions + '#termAndConditions'}
                  >
                    <LinkText>Terms and Conditions</LinkText>
                  </HashLink>
                </LinkGrid>
                <LinkGrid item>
                  <HashLink
                    className={classes.link}
                    to={routes.public.termsAndConditions + '#deliveryTiming'}
                  >
                    <LinkText>Shipping Policy</LinkText>
                  </HashLink>
                </LinkGrid>
                <LinkGrid item>
                  <Link className={classes.link} to={routes.public.disclaimer}>
                    <LinkText>Disclaimer</LinkText>
                  </Link>
                </LinkGrid>
                <LinkGrid item>
                  <Link className={classes.link} to={routes.public.cancellationAndRefnds}>
                    <LinkText>Cancellations and refunds</LinkText>
                  </Link>
                </LinkGrid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={6} md={3}>
              <Grid container direction="column" alignItems="flex-start">
                <LinkGrid item>
                  <LinkTitle>Contact</LinkTitle>
                </LinkGrid>

                <LinkGrid item>
                  <LinkText>
                    <a className={classes.link} href="/contact/">
                      <ChatIcon className={classes.iconWhite} />
                      Contact us
                    </a>
                  </LinkText>
                </LinkGrid>

                <LinkGrid item>
                  <a className={classes.iconLink} href="https://www.facebook.com/BakehouseGoa">
                    <FacebookIcon className={classes.iconWhite} />
                    Facebook
                  </a>
                </LinkGrid>
                <LinkGrid item>
                  <a className={classes.iconLink} href="https://www.instagram.com/bakehousegoa/">
                    <InstagramIcon className={classes.iconWhite} />
                    Instagram
                  </a>
                </LinkGrid>
                <LinkGrid item>
                  <a className={classes.iconLink} href="mailto:bakehouse@myleshigh.in">
                    <MailOutlineIcon className={classes.iconWhite} />
                    bakehouse@myleshigh.in
                  </a>
                </LinkGrid>
                <LinkGrid item>
                  <a className={classes.iconLink} href="tel:+918322437978">
                    <CallIcon className={classes.iconWhite} />
                    +91 832 - 2437978
                  </a>
                </LinkGrid>
                <LinkGrid item>
                  <a className={classes.iconLink} href="tel:+918322438117">
                    <CallIcon className={classes.iconWhite} />
                    +91 832 - 2438117
                  </a>
                </LinkGrid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <LinkGrid item>
                <LinkTitle>Address</LinkTitle>
              </LinkGrid>
              <Typography className={classes.textWhite}>
                Myles High Health Concepts Pvt. Ltd.
              </Typography>
              <Typography className={classes.textWhite}>Myles High Corporate Hub </Typography>
              <Typography className={classes.textWhite}>Ground floor </Typography>
              <Typography className={classes.textWhite}>Patto Plaza, Panaji </Typography>
              <Typography className={classes.textWhite}>Goa 403001 India</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.line} />
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.copyright}
      >
        <Grid item>
          <Typography className={classes.credits}>Copyright</Typography>
        </Grid>
        <Grid item>
          <Copyright className={classes.copyrightIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.credits}>
            2021 All Rights Reserved.
            {/* Developed by{' '}
            <HyperLink href="https://octalogic.in/" target="_blank" rel="noopener noreferrer">
              Octalogic Tech
            </HyperLink> */}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.copyright}
      >
        <Grid item>
          <Typography className={classes.lastLine}>
            "All Glory to Almighty Father, to His Son and to the Holy Spirit for giving us this day
            our Daily Bread"
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withWidth()(withStyles(styles)(AppFooter));
