import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import NotificationContainer from '../../containers/NotificationContainer';
import Header from '../../components/Header';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.light,
  },
  content: {
    flex: 1,
    // zIndex: '1111',
    // marginTop: '-4rem',
  },
}));

const LandingPageLayout = ({ component: Component, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Route
      render={(props) => (
        <div className={classes.container}>
          <Header />
          <div className={classes.content}>
            <Component {...props} />
          </div>
          <NotificationContainer />
        </div>
      )}
      {...rest}
    />
  );
};

export default LandingPageLayout;
