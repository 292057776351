import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/orders';

export const doReadOrders = createAction(actionTypes.READ_ORDERS_REQUESTED);
export const doReadOrdersSuccess = createAction(actionTypes.READ_ORDERS_SUCCEEDED);
export const doReadOrdersFailure = createAction(actionTypes.READ_ORDERS_FAILED);

export const doResendInvoice = createAction(actionTypes.RESEND_INVOICE_REQUESTED);
export const doResendInvoiceSuccess = createAction(actionTypes.RESEND_INVOICE_SUCCEEDED);
export const doResendInvoiceFailure = createAction(actionTypes.RESEND_INVOICE_FAILED);

export const doSendSummary = createAction(actionTypes.SEND_SUMMARY_REQUESTED);
export const doSendSummarySuccess = createAction(actionTypes.SEND_SUMMARY_SUCCEEDED);
export const doSendSummaryFailure = createAction(actionTypes.SEND_SUMMARY_FAILED);

export const doSortOrders = createAction(actionTypes.SORT_ORDERS);
export const doRefreshOrders = createAction(actionTypes.REFRESH_ORDERS);
