import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doReadLocations } from '../../redux/actionCreators/locations';
import { doLoginSuccess, doLogout } from '../../redux/actionCreators/authentication';
import { doReadCart } from '../../redux/actionCreators/cart';
import firebase from '../../config/firebase';
import { fetchUserData } from '../../services/APIService';

const Auth = (props) => {
  const dispatch = useDispatch();

  useEffect(
    function checkAuth() {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser) => {
        unsubscribe();

        if (firebaseUser) {
          const token = await firebaseUser.getIdToken();
          localStorage.setItem('token', token);
          const {
            data: {
              data: { user },
            },
          } = await fetchUserData();

          dispatch(doLoginSuccess(user));
          dispatch(doReadCart());
        } else {
          dispatch(doLogout());
        }

        dispatch(doReadLocations());
      });

      return function cleanup() {
        unsubscribe();
      };
    },
    [dispatch],
  );

  return <Fragment>{props.children}</Fragment>;
};

export default Auth;
