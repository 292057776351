import { Box, Button, Chip, Grid, makeStyles, Typography, withWidth } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'swiper/swiper.scss';

import LandingPageFooter from '../../components/LandingPageFooter';

import muiTheme from '../../config/theme/landingTheme';
import routes from '../../config/routes';

import { useDispatch } from 'react-redux';
import { doSetSelCategory } from '../../redux/actionCreators/categories';

const styles = makeStyles((theme) => ({
  coverImage: {
    width: '100vw',
    objectFit: 'cover',
    maxWidth: '100%',
  },
  headingText: {
    alignItems: 'center',
    color: '#e3c57a',
    fontWeight: '700',
    textAlign: 'center',
  },
  bodyText: {
    alignItems: 'center',
    color: '#623912',
    fontWeight: '500',
    letterSpacing: '1px',
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '20px',
  },
  bodyText2: {
    alignItems: 'center',
    color: '#623912',
    fontWeight: '500',
    letterSpacing: '1px',
    textAlign: 'justify',
  },
  categoryImageBox: {
    width: '100%',
  },
  categoryImage: {
    // objectFit: 'cover',
    width: '100%',
    borderRadius: '5%',
    height: '20rem',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  categoryImageName: {
    padding: '0.5rem 0',
  },
  categoryImageNamePill: {
    width: '100%',
    background: '#E2D1A8',
    marginTop: '1rem',
    padding: '1.5rem 0 1.5rem',
    fontSize: '1.5rem',
    borderRadius: '24px',
    fontFamily: 'Glacial Indifference',
    color: '#8E6A4E',
    cursor: 'pointer',
  },
  logo: {
    width: '100vw',
    height: '45rem',
    background: 'url("images/DessertTableCoverhd.jpg") center no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      background: 'url("images/bakehouse-cover-mobile.jpeg") center no-repeat',
      backgroundSize: 'cover',
      width: '100vw',
      height: '25rem',
    },
    [theme.breakpoints.down('sm')]: {
      background: 'url("images/bakehouse-cover-mobile.jpeg") center no-repeat',
      backgroundSize: 'cover',
      width: '100vw',
      height: '15rem',
    },
  },
  occasion: {
    width: '100%',
    height: '20rem',
    alignItems: 'center',
    background: 'url("images/cake-6.jpg") center no-repeat',
  },
  bannerContainer: {
    position: 'relative',
  },
  bannerBtn: {
    margin: 'auto',
    position: 'absolute',
    top: '82%',

    [theme.breakpoints.down('xs')]: {
      top: '85%',
    },
  },
  orderBtn: {
    width: '8rem',
    color: '#ffffff',
    textTransform: 'none',
    fontSize: '1.2rem',
    height: '2.5rem',
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    borderRadius: '40px',
    background: '#EDAC2F',
    textAlign: 'center',
    '&:hover': {
      background: '#EDAC2F',
    },

    [theme.breakpoints.down('xs')]: {
      width: '6rem',
      height: '1.5rem',
      fontSize: '0.8rem',
    },

    [theme.breakpoints.between('sm', 'md')]: {
      width: '8rem',
      height: '3rem',
      fontSize: '1rem',
    },
  },
}));

const CategoryImage = ({ src, name }) => {
  const classes = styles();
  return (
    <Box className={classes.categoryImageBox}>
      <img src={src} alt="food" className={classes.categoryImage} />
      <Chip className={classes.categoryImageNamePill} label={name} />
      {/* <Typography className={classes.categoryImageName} align="center">{name}</Typography> */}
    </Box>
  );
};

const LandingPage = ({ width }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();

  const setCat = (type) => {
    // if (type === '') {
    //   history.push(routes.dashboard.order);
    //   return;
    // }
    dispatch(doSetSelCategory([type]));
    history.push(routes.dashboard.order);
  };
  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={`${classes.bannerContainer} p-0 pb-2 pt-5 m-0 w-screen relative`}
        >
          <Button onClick={() => setCat('tea cakes & cookies')}>
            <Grid item xs={12} className="p-0 pt-5 w-screen">
              <img src="images/CheesecakeBanner.png" alt="Cover" className={classes.coverImage} />
            </Grid>
            <Grid className={classes.bannerBtn}>
              {/* <Button className={classes.orderBtn} onClick={() => history.push(routes.dashboard.menu)}>
              Order Now
            </Button> */}
            </Grid>
          </Button>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className="p-5 w-screen"
        >
          <Grid item>
            <Typography
              variant={width !== 'xs' ? 'h2' : 'h4'}
              className={classes.headingText}
              gutterBottom
            >
              The heart of every Celebration{' '}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12} className="mb-4">
            <Typography className={classes.bodyText} gutterBottom>
              All your daily <b>bakery</b> needs now provided <b>online</b> and <b>delivered</b> to
              your location. Buy your
              <b> local & artisan</b> bread or order a <b>cake</b> for your special occasion. Make
              every <b>breakfast </b>
              wholesome and every event special with <b>Myles High Bakehouse</b>.
            </Typography>
          </Grid>

          <Grid item className="mb-10">
            <Grid container direction="row" justify="center" spacing={4} alignItems="center">
              <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setCat('cakes')}>
                <CategoryImage src={'images/Cakes.png'} name="Cakes" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setCat('bread')}>
                <CategoryImage src={'images/Bread.png'} name="Bread" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setCat('desserts')}>
                <CategoryImage src={'images/Desserts.jpg'} name="Desserts" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setCat('tea cakes & cookies')}>
                <CategoryImage src={'images/TeaCakeCookies.png'} name="Tea Cakes & Cookies" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setCat('savouries')}>
                <CategoryImage src={'images/Savouries.png'} name="Savouries" />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} onClick={() => setCat('platters')}>
                <CategoryImage src={'images/Platter.jpg'} name="Platters" />
              </Grid>
            </Grid>
          </Grid>

          {/*  <Grid item style={{ width: '100%' }}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={12} lg={5} style={{ width: '100%' }}>
                <Grid container direction="column" alignItems="flex-start" spacing={2}>
                  <Grid item>
                    <Typography variant="h4" className={classes.headingText}>
                      A Great Place To Meet & Eat{' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.bodyText2}>
                      Myles High Bakehouse is our very own Goan bakery established in 2007. Ever
                      since then it has been known to provide the highest quality products with
                      sincere service to its clients and guests. We are very well recognized for our
                      custom cakes which we cater for birthdays, weddings and all other events in
                      between. The wide variety of artisan bread baked by us is also of top-notch
                      quality.
                      <br />
                      <br />
                      Our outlet in Patto Panjim provides the freshest quality salads and
                      drool-worthy butter croissants, burgers, sandwiches, etc. all of which are
                      baked in-house. We pride ourselves in providing mouthwatering dishes from
                      around the world and offer our services from homemakers to the best hoteliers.
                      <br />
                      <br />
                      Myles high Bakehouse is a brand for everyone.
                    </Typography>
                  </Grid>
                  { <Grid item style={{ padding: '1rem 0', width: '100%' }}>
                    <Button
                      component={Link}
                      to={routes.dashboard.order}
                      variant="contained"
                      color="primary"
                      style={{ width: '100%' }}
                    >
                      Order now
                    </Button>
                  </Grid> }
                </Grid>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box className={classes.occasion} />
              </Grid>
            </Grid>
          </Grid>
          </Grid> */}
        </Grid>
        <LandingPageFooter />
      </CssBaseline>
    </MuiThemeProvider>
  );
};

export default withWidth()(LandingPage);
