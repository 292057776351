import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { doReadOrders, doRefreshOrders, doSortOrders } from '../../redux/actionCreators/orders';
import { doReadCart } from '../../redux/actionCreators/cart';
import { doReadItems } from '../../redux/selectors/items';
import {
  selectAllOrders,
  selectOrdersIsLoading,
  selectNoMoreData,
} from '../../redux/selectors/orders';
import { selectUserData } from '../../redux/selectors/authentication';

// Mui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Custom Components
import OrderCard from '../../components/Order/Card';
import NoOrders from '../../components/NoOrders';

import { ORDER_DATE, ORDER_DATE_ASC, PRICE, PRICE_ASC, DEFAULT } from '../../config/constants';

const OrdersPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState(DEFAULT);
  const [skipOrder, setSkipOrder] = useState(0);

  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  const isLoading = useSelector(selectOrdersIsLoading);
  const noMoreData = useSelector(selectNoMoreData);
  const userData = useSelector(selectUserData);

  const finalOrders =
    userData.role === 'corporate'
      ? orders.filter((item) => item.customerId === userData.id)
      : orders;

  useEffect(
    function loadOrders() {
      dispatch(doReadCart());
      dispatch(doRefreshOrders());
    },
    [dispatch],
  );

  useEffect(
    function loadOrders() {
      dispatch(doReadOrders({ pagination: true, skip: skipOrder }));
    },
    [dispatch, skipOrder],
  );

  useEffect(
    function sortOrders() {
      dispatch(doSortOrders(sortBy));
    },
    [dispatch, sortBy],
  );

  // const handleMenuClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeSort = (sort) => {
    setSortBy(sort);
    handleMenuClose();
  };

  const items = useSelector(doReadItems);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        {/* <Typography variant="h6" component="h1">
          Your Orders
        </Typography> */}
        <h6 style={{ display: orders.length === 0 ? 'none' : null }}>Your Orders</h6>

        {/* <Button
          aria-controls="sort-order-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          hidden={orders.length === 0}
        >
          Sort By: {sortBy}
        </Button> */}

        <Menu
          id="sort-order-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => changeSort(ORDER_DATE_ASC)}>{ORDER_DATE_ASC}</MenuItem>
          <MenuItem onClick={() => changeSort(ORDER_DATE)}>{ORDER_DATE}</MenuItem>
          <MenuItem onClick={() => changeSort(PRICE_ASC)}>{PRICE_ASC}</MenuItem>
          <MenuItem onClick={() => changeSort(PRICE)}>{PRICE}</MenuItem>
        </Menu>
      </Box>

      {isLoading ? (
        <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size="3rem" color="primary" />
        </Box>
      ) : (
        <div>
          <Box p={2}>
            {finalOrders.map((order) => (
              <OrderCard {...order} key={order.id} items={items} />
            ))}
            {finalOrders.length === 0 ? <NoOrders /> : null}
            {/* {orders.length === 0 ? <Typography>No orders found!</Typography> : null} */}
          </Box>
          <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '30px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSkipOrder(skipOrder + 10)}
              hidden={orders.length === 0 || noMoreData}
            >
              Load 10 more orders
            </Button>
          </Grid>
        </div>
      )}
    </Box>
  );
};

export default OrdersPage;
