import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme) => ({
  filter: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
}));

const Filters = ({
  categories,
  priceRange,
  setPriceRange,
  categoriesSelected,
  setCategoriesSelected,
  defaultPriceRange,
  isOnlyVeg,
  setOnlyVeg,
  sortOptions,
  setSortOption,
}) => {
  const classes = useStyles();
  const marks = [
    {
      value: defaultPriceRange.min,
      label: `₹${defaultPriceRange.min}`,
    },
    {
      value: defaultPriceRange.max,
      label: `₹${defaultPriceRange.max}`,
    },
  ];

  const handleChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const [categoriesSelectedOptions, setCategoriesSelectedOptions] = useState([]);

  useEffect(() => {
    setCategoriesSelectedOptions(categoriesSelected);
  }, [categories, categoriesSelected]);

  const priceText = (value) => {
    return `₹${value}`;
  };

  const handleVegetarianChange = (event) => {
    setOnlyVeg(event.target.checked);
  };

  const clearPrice = () => {
    setPriceRange([defaultPriceRange.min, defaultPriceRange.max]);
  };

  const categoryDropDownOptions = categories.map((category) => {
    return { label: category.name, value: category.id };
  });

  const sortDropDownOptions = sortOptions.map((sortOption) => {
    return { label: sortOption.label, value: sortOption.key };
  });

  return (
    <div className="w-screen">
      <Grid container className="mt-4 w-full">
        <Grid item xs={6} md={3} lg={3} className="px-3">
          <Grid container justify="space-between">
            <Grid item>Categories</Grid>
          </Grid>
          <Select
            isMulti
            name="category"
            value={categoriesSelectedOptions}
            options={categoryDropDownOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedCategories) => {
              const selectedValues = selectedCategories.map((selectedCategory) => {
                return selectedCategory;
              });
              setCategoriesSelectedOptions(selectedCategories);
              setCategoriesSelected(selectedValues);
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3} className="px-3">
          <Grid container justify="space-between">
            <Grid item>Sort By</Grid>
          </Grid>
          <Select
            defaultValue={[sortDropDownOptions[0]]}
            name="sort"
            options={sortDropDownOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedSortOption) => {
              setSortOption({ key: selectedSortOption.value, label: selectedSortOption.label });
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={3} className={`px-3 ${classes.filter}`}>
          <Grid container justify="space-between">
            <Grid item>Price ( {`₹${priceRange[0]} - ₹${priceRange[1]}`} )</Grid>
            <Grid item>
              <Tooltip title="Clear Price Filters" aria-label="add">
                <IconButton aria-label="clear" size="small" onClick={() => clearPrice()}>
                  <ReplayIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <div className={classes.container}>
            <Slider
              value={priceRange}
              onChange={handleChange}
              aria-labelledby="range-slider"
              getAriaValueText={priceText}
              valueLabelFormat={priceText}
              valueLabelDisplay="auto"
              marks={marks}
              step={10}
              min={defaultPriceRange.min}
              max={defaultPriceRange.max}
            />
          </div>
        </Grid>
        <Grid item xs={6} md={3} lg={3} className={`px-3 ${classes.filter}`}>
          <Grid container justify="space-between">
            <Grid item>Vegetarian</Grid>
          </Grid>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox name="vegetarian" checked={isOnlyVeg} onChange={handleVegetarianChange} />
              }
              label="Vegetarian"
            />
          </FormGroup>
        </Grid>
      </Grid>

      {/* <Hidden lgUp>
        <Box className="px-4 py-2">
          <List className={classes.root}>
            <Grid item xs={12} sm={12} className="my-2">
              <Grid container justify="space-between">
                <Grid item>Categories</Grid>
              </Grid>
              <Select
                isMulti
                name="category"
                value={categoriesSelectedOptions}
                options={categoryDropDownOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedCategories) => {
                  const selectedValues = selectedCategories.map((selectedCategory) => {
                    return selectedCategory;
                  });
                  setCategoriesSelectedOptions(selectedCategories);
                  setCategoriesSelected(selectedValues);
                }}
              />
            </Grid>
            <Box className="my-2">
              <Grid container justify="space-between">
                <Grid item>Price</Grid>
                <Grid item>
                  <Tooltip title="Clear Price Filters" aria-label="add">
                    <IconButton aria-label="clear" size="small" onClick={() => clearPrice()}>
                      <ReplayIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <div>
                <Typography variant="caption">{`₹${priceRange[0]} - ₹${priceRange[1]}`}</Typography>
                <Slider
                  value={priceRange}
                  onChange={handleChange}
                  aria-labelledby="range-slider"
                  getAriaValueText={priceText}
                  valueLabelFormat={priceText}
                  valueLabelDisplay="auto"
                  marks={marks}
                  step={10}
                  min={defaultPriceRange.min}
                  max={defaultPriceRange.max}
                />
              </div>
            </Box>

            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="vegetarian"
                    checked={isOnlyVeg}
                    onChange={handleVegetarianChange}
                  />
                }
                label="Vegetarian"
              />
            </FormGroup>
            <Grid item xs={12} sm={12}>
              <Grid container justify="space-between">
                <Grid item>Sort By</Grid>
              </Grid>
              <Select
                defaultValue={[sortDropDownOptions[0]]}
                name="sort"
                options={sortDropDownOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedSortOption) => {
                  setSortOption({ key: selectedSortOption.value, label: selectedSortOption.label });
                }}
              />
            </Grid>
          </List>
        </Box>
      </Hidden> */}
    </div>
  );
};

Filters.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
};

Filters.defaultProps = {
  categories: [],
};

export default Filters;
