import React, { Fragment, useState } from 'react';
import { IconButton, Snackbar, TextField } from '@material-ui/core';
// import { TextField as TextFieldFormik } from 'formik-material-ui';
import '../../pages/UserProfile/index.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import { Formik, Form, Field } from 'formik';
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import InputLabel from '@material-ui/core/InputLabel';
// import style from '../../pages/Register/style.module.scss';
// import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
// Redux
import { useDispatch } from 'react-redux';
// import changePasswordSchema from './config';
import {
  doUpdateProfile,
  // doChangePassword,
  // doLogout,
} from '../../redux/actionCreators/authentication';

import { auth } from '../../config/firebase';
import { Close } from '@material-ui/icons';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfileSetting = ({ userData, authToken }) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [isVisible, setVisible] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (event) => {
    dispatch(doUpdateProfile({ [event.target.name]: event.target.value }));
  };

  // const handleSubmit = (values, { setSubmitting }) => {
  //   dispatch(doChangePassword({ password: values.password }));
  //   setSubmitting(false);
  //   setTimeout(function () {
  //   }, 1000);
  //   setTimeout(function () {
  //     dispatch(doLogout());
  //     history.push('/');
  //   }, 2000);
  // };

  const handlePasswordReset = async () => {
    const { email } = userData;
    try {
      await auth.sendPasswordResetEmail(email);
      setVisible(true);
      setMessage('Password reset link sent to the email!');
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleClose = () => {
    setVisible(false);
    setMessage('');
  };

  // const [openChangePasswordDialog, setChangePasswordDialog] = React.useState(false);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isVisible}
        autoHideDuration={3000}
        onClose={() => handleClose()}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Fragment>
        <TextField
          autoFocus
          margin="normal"
          name="firstName"
          label="First Name"
          type="text"
          defaultValue={userData.firstName}
          variant="outlined"
          onChange={handleChange}
        />
        <span className="profile-break" />
        <TextField
          margin="normal"
          name="lastName"
          label="Last Name"
          type="text"
          defaultValue={userData.lastName}
          variant="outlined"
          onChange={handleChange}
        />
        <span className="profile-break" />
        <TextField
          margin="normal"
          name="username"
          label="Username"
          type="text"
          defaultValue={userData.username}
          variant="outlined"
          disabled
          InputProps={{
            endAdornment: userData.isEmailVerfied ? (
              <InputAdornment>
                <CheckCircleIcon />
              </InputAdornment>
            ) : null,
          }}
        />
        <br />
        <TextField
          margin="normal"
          name="number"
          label="Contact Number"
          type="text"
          defaultValue={userData.phone}
          variant="outlined"
          disabled
          InputProps={{
            endAdornment: userData.isPhoneVerfied ? (
              <InputAdornment>
                <CheckCircleIcon />
              </InputAdornment>
            ) : null,
          }}
        />
        <br />
        <button onClick={() => handlePasswordReset()} className="btn btn-primary">
          Change Password
        </button>
      </Fragment>

      {/* <Dialog
        open={openChangePasswordDialog}
        onClose={() => setChangePasswordDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            password: '',
            confirm_password: '',
          }}
          validationSchema={changePasswordSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form className={style.container__form}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Change Password
                </Typography>
                <br />
                <InputLabel style={{ marginTop: '12px' }} htmlFor="password">
                  Enter New Password
                </InputLabel>
                <Field
                  component={TextFieldFormik}
                  name="password"
                  type="password"
                  label="Password"
                  fullWidth
                  variant="filled"
                />
                <br />
                <InputLabel style={{ marginTop: '12px' }} htmlFor="confirm-password">
                  Confirm New Password
                </InputLabel>
                <Field
                  component={TextFieldFormik}
                  name="confirm_password"
                  type="password"
                  label="Password"
                  fullWidth
                  variant="filled"
                />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitForm}
                  fullWidth
                  style={{ marginTop: '12px' }}
                >
                  Change Password
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog> */}
    </div>
  );
};

export default ProfileSetting;
