export const AUTH_LOGIN_REQUESTED = 'auth/AUTH_LOGIN_REQUESTED';
export const AUTH_LOGIN_SUCCEEDED = 'auth/AUTH_LOGIN_SUCCEEDED';
export const AUTH_LOGIN_FAILED = 'auth/AUTH_LOGIN_FAILED';

export const AUTH_REGISTER_REQUESTED = 'auth/AUTH_REGISTER_REQUESTED';
export const AUTH_REGISTER_SUCCEEDED = 'auth/AUTH_REGISTER_SUCCEEDED';
export const AUTH_REGISTER_FAILED = 'auth/AUTH_REGISTER_FAILED';

export const AUTH_LOGOUT_REQUESTED = 'auth/AUTH_LOGOUT_REQUESTED';
export const AUTH_LOGOUT_SUCCEEDED = 'auth/AUTH_LOGOUT_SUCCEEDED';
export const AUTH_LOGOUT_FAILED = 'auth/AUTH_LOGOUT_FAILED';

export const AUTH_EMAIL_VERIFICATION_REQUESTED = 'auth/AUTH_EMAIL_VERIFICATION_REQUESTED';
export const AUTH_EMAIL_VERIFICATION_SUCCEEDED = 'auth/AUTH_EMAIL_VERIFICATION_SUCCEEDED';
export const AUTH_EMAIL_VERIFICATION_FAILED = 'auth/AUTH_EMAIL_VERIFICATION_FAILED';

export const AUTH_REGISTRATION_PAYMENT_REQUESTED = 'auth/AUTH_REGISTRATION_PAYMENT_REQUESTED';
export const AUTH_REGISTRATION_PAYMENT_SUCCEEDED = 'auth/AUTH_REGISTRATION_PAYMENT_SUCCEEDED';
export const AUTH_REGISTRATION_PAYMENT_FAILED = 'auth/AUTH_REGISTRATION_PAYMENT_FAILED';

export const AUTH_PAYMENT_NULLIFIER = 'auth/AUTH_PAYMENT_NULLIFIER';

export const AUTH_EMAIL_VERIFICATION_STATUS = 'auth/AUTH_EMAIL_VERIFICATION_STATUS';

export const AUTH_REFRESH_FIREBASE_TOKEN = 'auth/AUTH_REFRESH_FIREBASE_TOKEN';
export const AUTH_REFRESH_FIREBASE_TOKEN_SUCCEEDED = 'auth/AUTH_REFRESH_FIREBASE_TOKEN_SUCCEEDED';
export const AUTH_REFRESH_FIREBASE_TOKEN_FAILED = 'auth/AUTH_REFRESH_FIREBASE_TOKEN_FAILED';

export const UPDATE_PROFILE_REQUESTED = 'auth/UPDATE_PROFILE_REQUESTED';
export const UPDATE_PROFILE_SUCCEEDED = 'auth/UPDATE_PROFILE_SUCCEEDED';
export const UPDATE_PROFILE_FAILED = 'auth/UPDATE_PROFILE_FAILED';

export const CHANGE_PASSWORD_REQUESTED = 'auth/CHANGE_PASSWORD_REQUESTED';
export const CHANGE_PASSWORD_SUCCEEDED = 'auth/CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_PASSWORD_FAILED = 'auth/CHANGE_PASSWORD_FAILED';

export const ENABLE_OTP_FIELD = 'auth/ENABLE_OTP_FIELD';
export const DISABLE_OTP_FIELD = 'auth/DISABLE_OTP_FIELD';
