import React, { useEffect } from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import './index.css';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ProfileSetting from '../../components/Profile';
import Address from '../../components/Checkout/checkoutAddress';
import { useDispatch } from 'react-redux';
import { doReadAddresses } from '../../redux/actionCreators/addresses';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Profile({ userData, userAddress, authToken }) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(
    function loadAddresses() {
      if (userData) {
        dispatch(doReadAddresses(userData.id));
      }
    },
    [dispatch, userData],
  );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-3 leftProfile col-12">
          <hr className="profile-rule" />
          <img
            src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/delish-churro-cupcakes-pinterest-still005-1556565661.jpg?crop=1.00xw:0.669xh;0,0.131xh&resize=480:*"
            alt="profile"
            className="profile-img"
          />
          <h1 className="username">{userData.firstName + ' ' + userData.lastName}</h1>
          <button className="btn btn-block btn-primary edit-profile-btn">EDIT PROFILE</button>
        </div>

        <div className="col-lg-8 rightProfile col-12">
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Profile Setting" {...a11yProps(0)} />
              <Tab label="Address" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <ProfileSetting userData={userData} authToken={authToken} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Address userData={userData} />
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Profile;
