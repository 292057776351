import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/locations';
import * as actionTypes from '../actionTypes/locations';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';

function* readLocations({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(APIService.readLocations);
    yield put(actionCreators.doReadLocationsSuccess(data));
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't fetch locations" }));
    yield put(actionCreators.doReadLocationsFailure(error));
  }
}

function* readPickupLocation() {
  try {
    const {
      data: { data },
    } = yield call(APIService.readPickupLocation);
    yield put(actionCreators.doReadPickupLocationSuccess(data));
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't fetch location" }));
    yield put(actionCreators.doReadPickupLocationFailure(error));
  }
}

const locationsSaga = [
  takeLatest(actionTypes.READ_LOCATIONS_REQUESTED, readLocations),
  takeLatest(actionTypes.READ_PICKUP_LOCATION_REQUESTED, readPickupLocation),
];

export default locationsSaga;
