import React from 'react';
import { connect } from 'react-redux';
// import PropTypes from "prop-types";
import PropTypes from 'prop-types';

import { doRemoveCart, doUpdateCart } from '../../redux/actionCreators/cart';
import { doReadCartItems } from '../../redux/selectors/cart';
import Cart from '../../pages/Cart';

const CartContainer = ({ updateFromCartHandler, removeFromCartHandler, cartData }) => {
  return (
    <Cart
      updateFromCartHandler={updateFromCartHandler}
      removeFromCartHandler={removeFromCartHandler}
      cartData={cartData}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCartHandler: (payload) => dispatch(doRemoveCart(payload)),
    updateFromCartHandler: (payload) => dispatch(doUpdateCart(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    cartData: doReadCartItems(state),
  };
};

CartContainer.propTypes = {
  updateFromCartHandler: PropTypes.func.isRequired,
  removeFromCartHandler: PropTypes.func.isRequired,
  cartData: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
