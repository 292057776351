import React from 'react';
import { connect } from 'react-redux';
// import PropTypes from "prop-types";
import PropTypes from 'prop-types';
import {
  selectUserData,
  selectUserAddress,
  selectAuthToken,
} from '../../redux/selectors/authentication';
import Profile from '../../pages/UserProfile';

const ProfileContainer = ({ userData, userAddress, authToken }) => {
  return <Profile userData={userData} userAddress={userAddress} authToken={authToken} />;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state, ownProps) => {
  return {
    userData: selectUserData(state),
    userAddress: selectUserAddress(state),
    authToken: selectAuthToken(state),
  };
};

ProfileContainer.propTypes = {
  userData: PropTypes.object,
  userAddress: PropTypes.array,
  authToken: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
