import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/categories';
import * as actionTypes from '../actionTypes/categories';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';

function* readCategories({ payload }) {
  // yield put(misc.doToggleLoadingBar({ isLoadingVisible: true }));
  try {
    const {
      data: { data },
    } = yield call(APIService.readCategories);
    yield put(actionCreators.doReadCategoriesSuccess(data));
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't fetch categories" }));
    yield put(actionCreators.doReadCategoriesFailure(error));
  } finally {
    // yield put(misc.doToggleLoadingBar({ isLoadingVisible: false }));
  }
}

const categoriesSaga = [takeLatest(actionTypes.READ_CATEGORIES_REQUESTED, readCategories)];

export default categoriesSaga;
