import React, { useEffect, useState } from 'react';
// import PropTypes from "prop-types";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
// import LinesEllipsis from 'react-lines-ellipsis';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import calculateDeliveryDate from '../../utils/calculateDeliveryDate';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  cardFooter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  price: {
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  veg: {
    color: green[500],
    [theme.breakpoints.down('xs')]: {
      width: '15px',
      height: '15px',
    },
  },
  nonVeg: {
    width: '20px',
    height: '20px',
    // color: red[500],
    [theme.breakpoints.down('xs')]: {
      width: '15px',
      height: '15px',
    },
  },
  itemText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

const ItemCard = ({
  data,
  setSelectedItem,
  setModalOpen,
  addItemToCart,
  updateItemFromCart,
  cartData,
  authToken,
  manageNotification,
  refreshAuthToken,
  handleOpenLoginModal,
  disableAddToCart,
  setDisableAddToCart,
}) => {
  const classes = useStyles();
  const [cartQuantity, setCartQuantity] = useState(0);
  const theme = useTheme();

  // Helpers
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(
    function CheckItemQuantityInCart() {
      const itemInCart = cartData.filter((cartItem) => cartItem.itemId === data.itemId);
      if (itemInCart.length > 0) setCartQuantity(itemInCart[0].quantity);
      else setCartQuantity(0);
    },
    [cartData, data.itemId],
  );

  const resetAddButton = () => {
    setDisableAddToCart(false);
  };

  const handleCardClick = (param) => {
    setModalOpen(true);
    setSelectedItem(param);
  };

  const quickAddToCart = (event, item) => {
    event.stopPropagation();
    if (disableAddToCart) {
      return;
    }
    setDisableAddToCart(true);

    if (authToken === null) {
      handleOpenLoginModal();
    } else {
      let itemUpdateStatus = false;
      let midObject = {};
      if (cartData.length === 0) {
        midObject = {
          ...item,
          quantity: 1,
        };

        addItemToCart({
          ...item,
          quantity: 1,
          deliveryDate: calculateDeliveryDate(item, 1),
          resetAddButton,
        });
      } else {
        for (let i = 0; i < cartData.length; i++) {
          if (cartData[i].uuId === item.uuId) {
            itemUpdateStatus = true;
            midObject = {
              ...item,
              quantity: cartData[i].quantity + 1,
            };

            const arr = cartData.filter((prev) => prev.uuId !== item.uuId);
            arr.push(midObject);

            updateItemFromCart({
              ...item,
              quantity: cartData[i].quantity + 1,
              deliveryDate: calculateDeliveryDate(item, cartData[i].quantity + 1),
              resetAddButton,
            });
            break;
          }
        }

        if (itemUpdateStatus) {
        } else {
          midObject = {
            ...item,
            quantity: 1,
          };

          addItemToCart({
            ...item,
            quantity: 1,
            deliveryDate: calculateDeliveryDate(item, 1),
            resetAddButton,
          });
        }
      }
    }
  };

  return (
    <>
      <Grid item xs={6} sm={4} md={4} lg={3}>
        <Card
          className={classes.card}
          elevation={0}
          onClick={() => {
            handleCardClick(data);
          }}
        >
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={
                data.images && data.images.length > 0 && data.images[0]
                  ? `${data.images[0].key}`
                  : ''
              }
              title={data.name}
            />
            <CardContent>
              <Grid container justify="space-between">
                <Typography className={classes.itemText} variant="body1">
                  {data.name}
                </Typography>
                {/* <FiberManualRecordIcon
                  className={data.veg || data.isVeg ? classes.veg : classes.nonVeg}
                /> */}
                {(authToken ? data.veg : data.isVeg) ? (
                  <FiberManualRecordIcon className={data.veg || data.isVeg ? classes.veg : ''} />
                ) : (
                  <>
                    <img src="/non-veg.svg" alt="non-veg" className={classes.nonVeg} />
                  </>
                )}
              </Grid>

              {/* <LinesEllipsis
                text={data.description}
                style={{ whiteSpace: 'pre-wrap' }}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="letters"
              /> */}
            </CardContent>
          </CardActionArea>
          <CardActions className={classes.cardFooter}>
            {disableAddToCart ? (
              <CircularProgress size={20} />
            ) : (
              <Badge badgeContent={cartQuantity} color="primary">
                <Button
                  size="small"
                  color="primary"
                  onClick={(event) => {
                    quickAddToCart(event, data);
                  }}
                  disabled={disableAddToCart}
                >
                  {isXsDown ? 'Add' : 'Add to cart'}
                </Button>
              </Badge>
            )}

            <Typography variant="h6" className={classes.price}>
              {`₹${data.price}`}
              {data.unit ? `/${data.unit}` : ''}
            </Typography>
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

ItemCard.propTypes = {};

ItemCard.defaultProps = {};

export default ItemCard;
