import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  li: {
    color: 'black',
    marginBottom: '0.5rem',
    fontSize: '1rem',
  },
  container: {
    padding: '4rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0rem',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid
        container
        style={{ width: '100%', margin: 0 }}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
        className={classes.container}
      >
        <Grid item>
          <Typography variant="h5" style={{ textDecoration: 'underline' }}>
            MYLES HIGH BAKEHOUSE - PRIVACY POLICY{' '}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ol>
            <li className={classes.li}>
              <a className={classes.link} href="Myleshigh.com/Bakehouse">
                Myleshigh.com/Bakehouse
              </a>{' '}
              <span className={classes.li}>
                is the official website of M/s Myles High Health Concepts Private Limited which is
                registered with government of India.
              </span>
            </li>
            <li className={classes.li}>
              <a className={classes.link} href="    BakehouseGoa.com">
                BakehouseGoa.com
              </a>{' '}
              <span className={classes.li}>
                is the official online Store of M/s Myles High Health Concepts Private Limited which
                is registered with government of India.
              </span>
            </li>
            <Typography>
              We respect the Privacy of our users and protect them with the Privacy policies stated
              here.
            </Typography>
            <li className={classes.li}>
              The information Myles High Bakehouse collects is for following reasons:
              <ol type="a">
                <li>Delivering of order.</li>
                <li>Authenticating the user details.</li>
                <li>For Promotion of Myles High Bakehouse.</li>
                <li>For Intimating about Offers/Discounts.</li>
              </ol>
            </li>
            <li className={classes.li}>
              The information that we collect is mainly{' '}
              <ol type="a">
                <li>Name</li>
                <li>Address</li>
                <li>Phone number</li>
                <li>Email address</li>
                <li>Payment details</li>
                <li>Age</li>
                <li>Sex</li>
              </ol>
            </li>
            <li className={classes.li}>
              When placing an order, information you provide will be shared with our banking
              services and distribution providers to ensure your order can be successfully processed
              and delivered. We will only share relevant information with these parties; all such
              providers are under obligation to ensure your information is kept private.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse will not email you in the future unless you have given us your
              consent (unless it is to confirm orders or delivery or Feedback)
            </li>
            <li className={classes.li}>
              No sensitive information about the users will be demanded.
            </li>
            <li className={classes.li}>
              For each new visitor to the website we automatically log certain information about how
              you are using our website. If your computer browser is set up to accept them, we use a
              feature known as a "cookie" which contains information that is stored on your computer
              hard drive for record keeping purposes. Cookies allow us to make our website more
              useful based on stored information about your preferences when you visit our website.
              If you do not wish for information to be recorded in this way, you are able to
              configure your browser to refuse cookies or to notify you when you have received one.
            </li>
            <li className={classes.li}>
              The Customer's consent for sharing of information with its distributors and Banking
              firms is taken as implicit.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse works closely with a third-party delivery service provider to
              bring you high quality services & products. The information you enter for deliveries
              is shared with such third party. They have access to personal information needed to
              perform their functions, but may not use it for other purposes.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse may amend its policy from time to time. If we make any
              substantial changes in the way we use your personal information we will notify you by
              email or an announcement on the website.
            </li>
            <li className={classes.li}>
              If you have any questions/comments about our privacy, please email us at
              Bakehouse@MylesHigh.in
            </li>
            <Typography style={{ marginTop: '1rem' }}>
              Thank you for visiting Myles High Bakehouse.{' '}
            </Typography>
            <Typography variant="h6">-Myles High Group </Typography>
          </ol>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PrivacyPolicy;
