import React from 'react';

// MUI
import { Grid, Typography } from '@material-ui/core';

// masonry
import Masonry from 'react-masonry-css';
// gallery items
import { items } from './demoData';
import GalleryComp from '../../components/Gallery';
// Styles
import { useStyles } from './style';
import LandingPageFooter from '../../components/LandingPageFooter';

function Gallery() {
  // Hooks
  const classes = useStyles();

  const breakpoints = {
    default: 4,
    1460: 3,
    1080: 2,
  };
  return (
    <>
      <Grid
        container
        className={classes.root}
        direction="column"
        justify="center"
        alignItems="center"
      >
        {/* banner */}
        <Grid item xs={12}>
          <img className={classes.bannerImg} src="/images/gallery/gallery-cover.jpg" alt="banner" />
        </Grid>
        {/* Title */}
        <Grid item xs={12}>
          <Grid item container direction="column" justify="center" alignItems="center" spacing={3}>
            <Grid item>
              <Typography className={classes.title} variant="h1">
                Our Creations
              </Typography>
              <Typography className={classes.text}>Here's some of our finest work.</Typography>
              <Typography className={classes.text}>Feel inspired?</Typography>
              <Typography className={classes.text}>
                Meet our team and create your own masterpiece.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* Gallery */}
              <div>
                <Masonry
                  breakpointCols={breakpoints}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {items.map(({ image }, idx) => (
                    <GalleryComp id={idx + 1} image={image} />
                  ))}
                </Masonry>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LandingPageFooter />
    </>
  );
}

export default Gallery;
