import React from 'react';
import { makeStyles, Grid, Hidden } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import AboutUs from '../../components/About';
import LandingPageFooter from '../../components/LandingPageFooter';

const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: '100vw',
    objectFit: 'cover',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
  },
  content: {
    backgroundImage: `url(/images/about-1.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
  },
  aboutCont: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '95px',
    },
  },
}));

const About = () => {
  const classes = useStyles();
  return (
    <>
      <Hidden smDown>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} className="p-0 w-screen">
            <Swiper spaceBetween={20} slidesPerView={1}>
              <SwiperSlide>
                <img src="/images/about-1.jpg" alt="Cover" className={classes.coverImage} />
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </Hidden>

      {/* About componet */}

      <Grid container className={classes.aboutCont} justify="center" alignItems="center">
        <Grid item xs={12}>
          <AboutUs />
        </Grid>
      </Grid>

      {/* cover 2 */}
      <Hidden smDown>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} className="p-0 w-screen">
            <Swiper spaceBetween={20} slidesPerView={1}>
              <SwiperSlide>
                <img src="/images/about-2.jpg" alt="Cover" className={classes.coverImage} />
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </Hidden>
      <LandingPageFooter />
    </>
  );
};

export default About;
