import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/cart';

export const doReadCart = createAction(actionTypes.READ_CART_REQUESTED);
export const doReadCartSuccess = createAction(actionTypes.READ_CART_SUCCEEDED);
export const doReadCartFailure = createAction(actionTypes.READ_CART_FAILED);

export const doAddCart = createAction(actionTypes.ADD_CART_REQUESTED);
export const doAddCartSuccess = createAction(actionTypes.ADD_CART_SUCCEEDED);
export const doAddCartFailure = createAction(actionTypes.ADD_CART_FAILED);

export const doRemoveCart = createAction(actionTypes.REMOVE_CART_REQUESTED);
export const doRemoveCartSuccess = createAction(actionTypes.REMOVE_CART_SUCCEEDED);
export const doRemoveCartFailure = createAction(actionTypes.REMOVE_CART_FAILED);

export const doUpdateCart = createAction(actionTypes.UPDATE_CART_REQUESTED);
export const doUpdateCartSuccess = createAction(actionTypes.UPDATE_CART_SUCCEEDED);
export const doUpdateCartFailure = createAction(actionTypes.UPDATE_CART_FAILED);

export const doUpdateMultipleCart = createAction(actionTypes.UPDATE_MULTIPLE_CART_REQUESTED);
export const doUpdateMultipleCartSuccess = createAction(actionTypes.UPDATE_MULTIPLE_CART_SUCCEEDED);
export const doUpdateMultipleCartFailure = createAction(actionTypes.UPDATE_MULTIPLE_CART_FAILED);

export const doClearCart = createAction(actionTypes.CLEAR_CART);
export const doSetDeliveryType = createAction(actionTypes.SET_DELIVERY_TYPE);
export const doSetPaymentType = createAction(actionTypes.SET_PAYMENT_TYPE);

export const doAddMultipleToCart = createAction(actionTypes.ADD_MULTIPLE_TO_CART_REQUESTED);
export const doAddMultipleToCartSuccess = createAction(actionTypes.ADD_MULTIPLE_TO_CART_SUCCEEDED);
export const doAddMultipleToCartFailure = createAction(actionTypes.ADD_MULTIPLE_TO_CART_FAILED);
