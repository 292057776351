import React, { useEffect, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { getDay } from 'date-fns';
import routes from '../../config/routes';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import Checkout from './checkout';
import Address from './checkoutAddress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import './styles.css';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { doUpdateCart, doRemoveCart } from '../../redux/actionCreators/cart';
import { selectDeliveryType, selectPaymentType } from '../../redux/selectors/cart';
import { doReadAddresses, doClearActiveAddress } from '../../redux/actionCreators/addresses';
import { selectUserData } from '../../redux/selectors/authentication';

import calculateDeliveryDate from '../../utils/calculateDeliveryDate';
import calculateMaxOrderable from '../../utils/calculateMaxOrderable';

import { deliveryTypes } from '../../config/constants';

//custom
import EmptyCart from '../../components/EmptyCart';
import { isItemMinOrderQtyFulfilled } from '../../utils/order';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    flexGrow: 1,
  },
  appbar: {
    color: theme.palette.secondary.light,
  },
  minOrderQty: {
    paddingLeft: 32,
    fontWeight: 600,
  },
  minOrderQtyEmpty: {
    paddingLeft: 96,
    fontWeight: 600,
  },
}));

export default function SimpleTable(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const userData = useSelector(selectUserData);
  const isCorporate = userData?.role === 'corporate';
  // const isRetail = userData?.role === 'customer';

  const todaysDate = new Date();
  const isSaturday = getDay(todaysDate) === 6;

  const userLocation = userData?.location?.route?.name.toLowerCase();
  const isSouth = userLocation?.search('south') !== -1;

  const [openNotificationModal, setNotificationModalOpen] = React.useState(
    (isCorporate && isSouth && isSaturday) || (!isCorporate && isSaturday),
  );
  const [openMaxQuantityModal, setMaxQuantityModalOpen] = React.useState(false);

  const deliveryType = useSelector(selectDeliveryType);
  const paymentType = useSelector(selectPaymentType);

  const [openAlert, setOpenAlert] = React.useState(userData.blockFlag === 1 ? true : false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(
    function loadAddresses() {
      if (userData) {
        dispatch(doReadAddresses(userData.id));
      }
    },
    [dispatch, userData],
  );

  useEffect(
    function clearActiveOrders() {
      dispatch(doClearActiveAddress());
    },
    [dispatch],
  );

  const handleCancel = (item) => {
    dispatch(doRemoveCart(item));
  };

  // function handleBackspace(e) {
  //   if(String(itemQuantity).length === 1)
  //   {
  //     if (e.keyCode === 8) {
  //       e.preventDefault();
  //       setItemQuantity(1);
  //     }
  //   }
  // }

  return (
    <div>
      {props.items.length === 0 ? (
        <Box p={2}>
          <EmptyCart />
        </Box>
      ) : (
        <>
          <Box p={2}>
            <div className="row font-weight">
              <div className="col-12 col-xl-8">
                <div className={classes.root}>
                  <AppBar position="static" className={classes.appbar}>
                    <Tabs value={value} onChange={handleChange} aria-label="Cart/Address Tab">
                      <Tab label="Cart Items" {...a11yProps(0)} />
                      <Tab
                        label="Address"
                        {...a11yProps(1)}
                        disabled={deliveryType !== deliveryTypes.delivery}
                      />
                    </Tabs>
                  </AppBar>

                  {/* Cart Items Table */}
                  <TabPanel value={value} index={0}>
                    <p style={{ color: 'red', fontWeight: 'bold' }}>
                      We do not undertake deliveries on Sunday
                    </p>
                    <div className="checkout-container">
                      <Paper>
                        <Hidden xsDown>
                          <Grid container className="row-wrapper">
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                              <Typography variant="subtitle2">Item</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                              <Typography align="center" variant="subtitle2">
                                Quantity
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                              <Typography variant="subtitle2">Delivery date</Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                              <Typography variant="subtitle2"> Total Price</Typography>
                            </Grid>
                            <Grid item xs={12} sm={1} md={1} lg={1}>
                              <Typography variant="subtitle2"></Typography>
                            </Grid>
                          </Grid>
                        </Hidden>
                        <div>
                          {props.items.map((row) => {
                            return (
                              <Formik
                                initialValues={{
                                  deliveryDate: row.deliveryDate,
                                  quantity: row.quantity,
                                }}
                                enableReinitialize
                                onSubmit={(values) => {
                                  dispatch(
                                    doUpdateCart({
                                      ...row,
                                      quantity: values.quantity,
                                      deliveryDate: values.deliveryDate,
                                    }),
                                  );
                                }}
                              >
                                {({ values, setFieldValue, submitForm }) => {
                                  const addToCart = () => {
                                    const newQuantity = parseInt(values.quantity) + 1;
                                    setFieldValue('quantity', newQuantity);
                                    setFieldValue(
                                      'deliveryDate',
                                      calculateDeliveryDate(row, newQuantity),
                                    );
                                    submitForm();
                                  };

                                  // function disableSundays(date) {
                                  //   const shouldBlockSunday = (isCorporate && isSouth && isSaturday) || (!isCorporate && isSaturday);

                                  //   return deliveryType === deliveryTypes.delivery &&
                                  //     shouldBlockSunday
                                  //     ? date.getDay() === 0
                                  //     : false;
                                  // }

                                  function disableSundaysInDatePicker(date) {
                                    return date.getDay() === 0 ? true : false;
                                  }

                                  const removeFromCart = () => {
                                    const newQuantity = parseInt(values.quantity) - 1;
                                    setFieldValue('quantity', newQuantity);
                                    setFieldValue(
                                      'deliveryDate',
                                      calculateDeliveryDate(row, newQuantity),
                                    );
                                    submitForm();
                                  };
                                  return (
                                    <Fragment>
                                      <Grid container className="row-wrapper">
                                        <Grid
                                          item
                                          xs={12}
                                          sm={3}
                                          md={3}
                                          lg={3}
                                          container
                                          justify="flex-start"
                                        >
                                          <Grid item xs={4} md={4} lg={4}>
                                            <Box px={1}>
                                              <img
                                                src={
                                                  row.images &&
                                                  row.images.length > 0 &&
                                                  row.images[0]
                                                    ? `${row.images[0].key}`
                                                    : ''
                                                }
                                                // alt={row.name}
                                                alt=""
                                                className="checkout-item-img"
                                              />
                                            </Box>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={8}
                                            md={8}
                                            lg={8}
                                            container
                                            alignContent="center"
                                          >
                                            <Typography variant="body2">{row.name}</Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={3}
                                          md={3}
                                          lg={3}
                                          container
                                          justify="center"
                                          alignContent="flex-end"
                                        >
                                          <IconButton
                                            color="primary"
                                            aria-label="remove from shopping cart"
                                            onClick={() => removeFromCart(row)}
                                            disabled={values.quantity === 1}
                                          >
                                            <RemoveIcon />
                                          </IconButton>
                                          <span style={{ width: '35px' }}>
                                            <input
                                              value={values.quantity}
                                              style={{
                                                width: '35px',
                                                padding: '10px 0px 10px 5px',
                                                borderBottom: '2px solid #f2bd82',
                                                borderTop: 'none',
                                                borderLeft: 'none',
                                                borderRight: 'none',
                                                outline: 'none',
                                                textAlign: 'center',
                                              }}
                                              type="number"
                                              className="quantity-input"
                                              min={1}
                                              max={calculateMaxOrderable(row)}
                                              onChange={(e) => {
                                                setFieldValue('quantity', e.target.value);
                                                const maxOrderable = calculateMaxOrderable(row);
                                                if (e.target.value > maxOrderable) {
                                                  setMaxQuantityModalOpen(true);
                                                  setFieldValue('quantity', maxOrderable);
                                                  const newDeliveryDate = calculateDeliveryDate(
                                                    row,
                                                    maxOrderable,
                                                  );
                                                  setFieldValue('deliveryDate', newDeliveryDate);
                                                } else {
                                                  const newDeliveryDate = calculateDeliveryDate(
                                                    row,
                                                    e.target.value,
                                                  );
                                                  setFieldValue('deliveryDate', newDeliveryDate);
                                                }
                                              }}
                                              onBlur={() => {
                                                submitForm();
                                              }}
                                            />
                                          </span>
                                          <IconButton
                                            color="primary"
                                            aria-label="add to shopping cart"
                                            onClick={() => addToCart(row)}
                                            disabled={
                                              values.quantity === calculateMaxOrderable(row)
                                            }
                                          >
                                            <AddIcon />
                                          </IconButton>
                                          {isItemMinOrderQtyFulfilled(row) ? (
                                            <Typography
                                              variant="caption"
                                              display="block"
                                              color="secondary"
                                              className={classes.minOrderQtyEmpty}
                                            ></Typography>
                                          ) : (
                                            <Typography
                                              variant="caption"
                                              display="block"
                                              color="secondary"
                                              className={classes.minOrderQty}
                                            >
                                              Min. Qty: {row.minOrderQty}
                                            </Typography>
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={3}
                                          md={3}
                                          lg={3}
                                          container
                                          justify="center"
                                        >
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                              margin="normal"
                                              format="dd/MM/yyyy"
                                              minDate={calculateDeliveryDate(row, values.quantity)}
                                              shouldDisableDate={disableSundaysInDatePicker}
                                              value={values.deliveryDate}
                                              onChange={(newDate) => {
                                                setFieldValue('deliveryDate', newDate);
                                                setTimeout(() => {
                                                  submitForm();
                                                }, 500);
                                              }}
                                              KeyboardButtonProps={{
                                                'aria-label': 'change delivery date',
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={2}
                                          md={2}
                                          lg={2}
                                          container
                                          alignContent="center"
                                        >
                                          <Typography
                                            variant="body1"
                                            align="center"
                                            className="width-full price-text"
                                          >
                                            {`Rs. ${(row.price * row.quantity).toFixed(2)}`}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={1}
                                          md={1}
                                          lg={1}
                                          container
                                          justify="center"
                                        >
                                          <IconButton
                                            color="primary"
                                            aria-label="add to shopping cart"
                                            onClick={() => handleCancel(row)}
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                      <Hidden smUp>
                                        <hr></hr>
                                      </Hidden>
                                    </Fragment>
                                  );
                                }}
                              </Formik>
                            );
                          })}
                        </div>
                      </Paper>
                    </div>
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <Address deliveryType={deliveryType} checkout userData={userData} />
                  </TabPanel>
                </div>
              </div>
              <div className="col-12 col-xl-4 checkout-div">
                <Button
                  className="btn btn-primary btn-back-to-menu back-btn text-white"
                  fullWidth
                  component={RouterLink}
                  to={routes.dashboard.order}
                >
                  Add more Items
                </Button>
                <div className="checkout-box">
                  <Checkout
                    userData={userData}
                    items={props.items}
                    deliveryType={deliveryType}
                    paymentType={paymentType}
                    setValue={setValue}
                  />
                </div>
              </div>
            </div>
          </Box>
          <Dialog
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h5 style={{ color: '#b25d30' }}>{'Credit Limit Restriction'}</h5>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {userData.blockMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleCloseAlert} color="primary" autoFocus>
                            console.log("🚀 ~ file: index.js ~ line 453 ~ {props.items.map ~ row", row)
                              console.log("🚀 ~ file: index.js ~ line 403 ~ {props.items.map ~ row", row)
                              console.log("🚀 ~ file: index.js ~ line 403 ~ {props.items.map ~ row", row)
            Okay
          </Button> */}
              <button className="btn btn-primary" onClick={handleCloseAlert}>
                Okay
              </button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openNotificationModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">No Sunday Deliveries</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Unfortunately, we will not be able to make any deliveries on{' '}
                <strong> Sunday </strong> so if you see <strong> Sunday's </strong> delivery date
                please expect your order to reach you by <strong> Monday </strong>
              </DialogContentText>
              <Typography variant="caption" color="secondary">
                * Delivery date is subject to quantity ordered
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setNotificationModalOpen(false);
                }}
                variant="contained"
                color="primary"
              >
                I Understand
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openMaxQuantityModal}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Maximum Quantity for Item</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You cannot place order for that quantity. We have set the quantity to the max
                orderable quantity. Please review quantities before checking out
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setMaxQuantityModalOpen(false);
                }}
                color="primary"
              >
                I Understand
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}
