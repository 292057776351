import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/authentication';

const defaultState = {
  isLoading: false,
  userData: null,
  otp: {
    isDisable: false,
  },
};

const authenticationReducer = handleActions(
  {
    [actionCreators.doLogin]: (state) => {
      return { ...state, isLoading: true };
    },
    [actionCreators.doLoginSuccess]: (state, { payload }) => {
      return {
        ...state,
        userData: payload,
      };
    },
    [actionCreators.doLoginFailure]: (state) => {
      return { ...state, isLoading: false };
    },

    [actionCreators.doRefreshAuthToken]: (state) => state,
    [actionCreators.doRefreshAuthTokenSuccess]: (state, { payload }) => {
      return {
        ...state,
        authToken: payload,
      };
    },
    [actionCreators.doRefreshAuthTokenFailure]: (state) => {
      return { ...state, authToken: null };
    },
    [actionCreators.doLogoutSuccess]: (state) => {
      return { ...defaultState };
    },
    [actionCreators.doUpdateProfileSuccess]: (state, { payload }) => ({
      ...state,
      userData: { ...state.userData, ...payload },
    }),

    [actionCreators.doRegister]: (state) => {
      return { ...state, isLoading: true };
    },
    [actionCreators.doRegisterSuccess]: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    [actionCreators.doRegisterFailure]: (state) => {
      return { ...state, isLoading: false };
    },

    [actionCreators.doEnableOtpField]: (state) => {
      return { ...state, otp: { ...state.otp, isDisable: false } };
    },
    [actionCreators.doDisableOtpField]: (state) => {
      return { ...state, otp: { ...state.otp, isDisable: true } };
    },
  },
  defaultState,
);

export default authenticationReducer;
