import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/categories';

const defaultState = {
  categoriesData: [],
  selectedCategory: null,
};

const categoriesReducer = handleActions(
  {
    [actionCreators.doReadCategories]: (state) => {
      return state;
    },
    [actionCreators.doReadCategoriesSuccess]: (state, { payload }) => {
      return {
        ...state,
        categoriesData: payload,
      };
    },
    [actionCreators.doReadCategoriesFailure]: (state) => {
      return state;
    },

    [actionCreators.doSetSelCategory]: (state, { payload }) => {
      return {
        ...state,
        selectedCategory: payload,
      };
    },
  },
  defaultState,
);

export default categoriesReducer;
