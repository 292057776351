import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Dashboard from '../../pages/Dashboard';

import { doAddCart, doRemoveCart, doUpdateCart } from '../../redux/actionCreators/cart';
import { doManageNotification } from '../../redux/actionCreators/miscellaneous';
import { doRefreshAuthToken } from '../../redux/actionCreators/authentication';
import { doReadCategories } from '../../redux/selectors/categories';
import { doReadItems } from '../../redux/selectors/items';
import { doReadCartItems } from '../../redux/selectors/cart';
import { selectAuthToken } from '../../redux/selectors/authentication';

const DashboardContainer = ({
  itemsData,
  categoriesData,
  addToCartHandler,
  cartData,
  removeFromCartHandler,
  updateFromCartHandler,
  authToken,
  manageNotification,
  refreshAuthToken,
}) => {
  return (
    <Dashboard
      items={itemsData}
      categories={categoriesData}
      addToCartHandler={addToCartHandler}
      cartData={cartData}
      removeFromCartHandler={removeFromCartHandler}
      updateFromCartHandler={updateFromCartHandler}
      authToken={authToken}
      manageNotification={manageNotification}
      refreshAuthToken={refreshAuthToken}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCartHandler: (payload) => dispatch(doAddCart(payload)),
    removeFromCartHandler: (payload) => dispatch(doRemoveCart(payload)),
    updateFromCartHandler: (payload) => dispatch(doUpdateCart(payload)),
    manageNotification: (payload) => dispatch(doManageNotification(payload)),
    refreshAuthToken: () => dispatch(doRefreshAuthToken()),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    authToken: selectAuthToken(state),
    categoriesData: doReadCategories(state),
    itemsData: doReadItems(state),
    cartData: doReadCartItems(state),
  };
};

DashboardContainer.propTypes = {
  itemsData: PropTypes.arrayOf(PropTypes.object),
  categoriesData: PropTypes.arrayOf(PropTypes.object),
  cartData: PropTypes.arrayOf(PropTypes.object),
  addToCartHandler: PropTypes.func.isRequired,
  removeFromCartHandler: PropTypes.func.isRequired,
  updateFromCartHandler: PropTypes.func.isRequired,
  authToken: PropTypes.string,
  manageNotification: PropTypes.func.isRequired,
  refreshAuthToken: PropTypes.func.isRequired,
};

DashboardContainer.defaultProps = {
  itemsData: [],
  categoriesData: [],
  cartData: [],
  authToken: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
