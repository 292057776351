/* eslint-disable no-console */
import React, { useState } from 'react';
import { productrequest } from '../../services/APIService';

// Formik
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

// Mui
import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './styles';
import * as Yup from 'yup';
// Styles

// validation schema
const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid Email').required('Required'),
  contact: Yup.string().min(10, 'Invalid Phone number').required('Required'),
  message: Yup.string().min(10, 'Message too Short!').required('Message cannot be Empty.'),
});

function ContactComp() {
  // HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const [isVisible, setVisible] = useState(false);
  const [message, setMessage] = useState('');

  // Helpers
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  // Handlers
  const handleContactForm = async (values, { setSubmitting, setStatus, resetForm }) => {
    const email = `${
      process.env.NODE_ENV === 'production' ? 'bakehouse@myleshigh.in' : 'tanushree@octalogic.in'
    }`;
    const subject = `Myles High Website - ${values.subject ? values.subject : ''}`;
    const body = `
    Hello, ${'\n'}I am ${values.name}. 
    ${values.message}.${'\n'}You can contact me at: ${values.email} or ${
      values.contact
    }.${'\n'}Thank you,${'\n'}${values.name}
   `;

    await productrequest(email, subject, body)
      .then((res) => {
        if (res) {
          setMessage(res.data.data);
          resetForm({});
          setStatus({ success: true });
        }
      })
      .catch((err) => {
        setStatus({ success: false });
        setSubmitting(false);
        setMessage(err.message);
      });

    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setMessage('');
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isVisible}
        autoHideDuration={6000}
        onClose={() => handleClose()}
        message={message}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />

      <Grid
        container
        direction="column"
        justify="center"
        alignItems={isSmDown ? 'center' : 'flex-end'}
        className={classes.container}
      >
        <Grid item className={classes.gridItem}>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h4" className={classes.typographyStyle}>
                Get in touch with us!
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: '20px' }}>
              <Typography className={classes.text}>
                Dear Customers, you are the supporting force behind whatever we do. Feel free to
                contact us for any queries or for any other information. We are always happy to help
                you and serve you, you are our first priority.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            name: '',
            email: '',
            subject: '',
            contact: '',
            message: '',
          }}
          validateOnBlur={true}
          onSubmit={handleContactForm}
          validationSchema={ContactSchema}
        >
          {({ handleSubmit, isSubmitting }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {/* 6x2 */}
                <Grid item className={classes.gridItem}>
                  <Grid item container spacing={3} direction="row">
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="name"
                        type="text"
                        label="Name"
                        fullWidth
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3} direction="row">
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="subject"
                        type="text"
                        label="Subject"
                        fullWidth
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="contact"
                        type="number"
                        label="Phone"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          className: classes.inputField,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3}>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        multiline
                        rows={6}
                        maxRows={8}
                        name="message"
                        type="text"
                        label="Message"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          className: classes.textArea,
                        }}
                        InputLabelProps={{
                          className: classes.inputLabel,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.btnGrid}>
                  <Button
                    type="submit"
                    style={{ border: '1px solid black', marginTop: '10px' }}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </>
  );
}

export default ContactComp;
