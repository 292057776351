import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/cart';
import { deliveryTypes, paymentTypes } from '../../config/constants';

const defaultState = {
  cartData: [],
  deliveryType: deliveryTypes.delivery,
  paymentType: paymentTypes.online,
};

const itemsReducer = handleActions(
  {
    // Read from Cart
    [actionCreators.doReadCartSuccess]: (state, { payload }) => {
      return {
        ...state,
        cartData: payload,
      };
    },
    [actionCreators.doReadCartFailure]: (state) => {
      return state;
    },
    // Add To Cart
    [actionCreators.doAddCartSuccess]: (state, { payload }) => {
      return {
        ...state,
        cartData: [...state.cartData, payload],
      };
    },
    [actionCreators.doAddCartFailure]: (state) => {
      return state;
    },
    // Add Multiple To Cart
    [actionCreators.doAddMultipleToCartSuccess]: (state, { payload }) => {
      return {
        ...state,
        cartData: [...state.cartData, payload],
      };
    },
    [actionCreators.doAddMultipleToCartFailure]: (state) => {
      return state;
    },
    // Update Cart
    [actionCreators.doUpdateCartSuccess]: (
      state,
      {
        payload: {
          data: { data },
        },
      },
    ) => {
      return {
        ...state,
        cartData: state.cartData.map((cartItem) =>
          cartItem.itemId === data.itemId
            ? { ...cartItem, quantity: data.quantity, deliveryDate: data.deliveryDate }
            : cartItem,
        ),
      };
    },
    [actionCreators.doUpdateCartFailure]: (state) => {
      return state;
    },
    [actionCreators.doUpdateMultipleCartSuccess]: (state, { payload }) => {
      return {
        ...state,
        cartData: state.cartData.map((cartItem) =>
          cartItem.uuId === payload.uuId
            ? { ...cartItem, quantity: payload.quantity, deliveryDate: payload.deliveryDate }
            : cartItem,
        ),
      };
    },
    [actionCreators.doUpdateMultipleCartFailure]: (state) => {
      return state;
    },
    // Remove Cart
    [actionCreators.doRemoveCartSuccess]: (state, { payload }) => {
      return {
        ...state,
        cartData: state.cartData.filter((item) => item.uuId !== payload.uuId),
      };
    },
    [actionCreators.doRemoveCartFailure]: (state) => {
      return state;
    },
    // Clear Cart locally
    [actionCreators.doClearCart]: (state) => {
      return {
        ...state,
        cartData: [],
      };
    },
    // Delivery Type
    [actionCreators.doSetDeliveryType]: (state, { payload }) => ({
      ...state,
      deliveryType: payload,
    }),
    // Payment Type
    [actionCreators.doSetPaymentType]: (state, { payload }) => ({
      ...state,
      paymentType: payload,
    }),
  },
  defaultState,
);

export default itemsReducer;
