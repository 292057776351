export const READ_ORDERS_REQUESTED = 'ORDERS/READ_ORDERS_REQUESTED';
export const READ_ORDERS_SUCCEEDED = 'ORDERS/READ_ORDERS_SUCCEEDED';
export const READ_ORDERS_FAILED = 'ORDERS/READ_ORDERS_FAILED';

export const REFRESH_ORDERS = 'ORDERS/REFRESH_ORDERS';

export const RESEND_INVOICE_REQUESTED = 'ORDERS/RESEND_INVOICE_REQUESTED';
export const RESEND_INVOICE_SUCCEEDED = 'ORDERS/RESEND_INVOICE_SUCCEEDED';
export const RESEND_INVOICE_FAILED = 'ORDERS/RESEND_INVOICE_FAILED';

export const SEND_SUMMARY_REQUESTED = 'ORDERS/SEND_SUMMARY_REQUESTED';
export const SEND_SUMMARY_SUCCEEDED = 'ORDERS/SEND_SUMMARY_SUCCEEDED';
export const SEND_SUMMARY_FAILED = 'ORDERS/SEND_SUMMARY_FAILED';

export const SORT_ORDERS = 'ORDERS/SORT_ORDERS';
