import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  li: {
    color: 'black',
    marginBottom: '0.5rem',
    fontSize: '1rem',
  },
  container: {
    padding: '4rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0rem',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

const Disclaimer = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid
        container
        style={{ width: '100%', margin: 0 }}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
        className={classes.container}
      >
        <Grid item>
          <Typography variant="h5" style={{ textDecoration: 'underline' }}>
            Myles High Bakehouse - Disclaimer{' '}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ol>
            <li className={classes.li}>
              Myles High Bakehouse is the official website of MYLES HIGH HEALTH CONCEPTS PRIVATE
              LIMITED which is registered with government of Goa
            </li>
            <li className={classes.li}>
              You must respect the site's copyright in terms of product details, pictures etc. and
              do not reproduce them anywhere without the consent of Myles High Bakehouse. All
              exceptions to these are liable for legal proceedings in IPC.
            </li>
            <li className={classes.li}>
              Accepting all orders lies under the preview of Myles High Bakehouse. We can refuse to
              serve any order without giving any reason to the specified party.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse commits delivery date but if they are not met because of some
              unforeseen circumstances e.g. unfounded recipient, extreme weather conditions, we
              cannot be held responsible for that.
            </li>
            <li className={classes.li}>
              All complaints related to faulty deliveries have to be reported at the time of the
              delivery of the product.{' '}
            </li>
            <li className={classes.li}>
              For all Cancellation related policies please refer our link on cancellation policy.{' '}
            </li>
            <li className={classes.li}>
              All the points given in our Terms and Conditions shall be read for terms and
              conditions for the delivery.
            </li>
            <li className={classes.li}>
              If there is no one available at the shipping address (to accept the delivery of your
              order) or recipient phone is wrong or unreachable at the time of delivery, the order
              will not be considered late. Hence in such cases, no refunds, cancellations, liability
              can be made.
            </li>
            <li className={classes.li}>
              Delivery of certain items may not be possible/delayed under certain uncontrollable
              circumstances such as product is not available in the market or discontinued by the
              manufacturer. In such cases, similar product, nearest to the description shall be
              delivered. Conformance would be sought from the customer for the same through e-mail.
              However, if there is no response from the customer within 24 hrs., it shall be assumed
              that s/he has agreed to the delivery of the alternate product.
            </li>
            <li className={classes.li}>
              Orders have to be placed before 4:00pm on the previous day. All orders made after that
              will be delivered latest by following day.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse may amend its Disclaimer from time to time. If we make any
              substantial changes in the way we use your personal information we will notify you by
              email or an announcement on the website.
            </li>
            <li className={classes.li}>
              If you have any questions/comments about our Disclaimer, please email us at{' '}
              <span style={{ color: 'blue' }}>Corporate@myleshigh.in</span>
            </li>
          </ol>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Disclaimer;
