import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/addresses';

export const doReadAddresses = createAction(actionTypes.READ_ADDRESSES_REQUESTED);
export const doReadAddressesSuccess = createAction(actionTypes.READ_ADDRESSES_SUCCEEDED);
export const doReadAddressesFailure = createAction(actionTypes.READ_ADDRESSES_FAILED);

export const doAddAddress = createAction(actionTypes.ADD_ADDRESS_REQUESTED);
export const doAddAddressSuccess = createAction(actionTypes.ADD_ADDRESS_SUCCEEDED);
export const doAddAddressFailure = createAction(actionTypes.ADD_ADDRESS_FAILED);

export const doRemoveAddress = createAction(actionTypes.REMOVE_ADDRESS_REQUESTED);
export const doRemoveAddressSuccess = createAction(actionTypes.REMOVE_ADDRESS_SUCCEEDED);
export const doRemoveAddressFailure = createAction(actionTypes.REMOVE_ADDRESS_FAILED);

export const doUpdateAddress = createAction(actionTypes.UPDATE_ADDRESS_REQUESTED);
export const doUpdateAddressSuccess = createAction(actionTypes.UPDATE_ADDRESS_SUCCEEDED);
export const doUpdateAddressFailure = createAction(actionTypes.UPDATE_ADDRESS_FAILED);

export const doSetActiveAddress = createAction(actionTypes.SET_ACTIVE_ADDRESS);
export const doClearActiveAddress = createAction(actionTypes.CLEAR_ACTIVE_ADDRESS);

export const doOpenAddressDialog = createAction(actionTypes.OPEN_ADDRESS_DIALOG);
export const doCloseAddressDialog = createAction(actionTypes.CLOSE_ADDRESS_DIALOG);
