import * as yup from 'yup';

const registerSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Email id is Required'),
  password: yup.string().required('Password is Required'),
  firstName: yup.string().required('First Name is Required'),
  lastName: yup.string().required('Last Name is Required'),
  phone: yup.string().min(10).required('Valid Phone number is Required'),
  locationId: yup.number().required('Location is Required'),
});

export default registerSchema;
