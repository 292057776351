import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/locations';

export const doReadLocations = createAction(actionTypes.READ_LOCATIONS_REQUESTED);
export const doReadLocationsSuccess = createAction(actionTypes.READ_LOCATIONS_SUCCEEDED);
export const doReadLocationsFailure = createAction(actionTypes.READ_LOCATIONS_FAILED);

export const doReadPickupLocation = createAction(actionTypes.READ_PICKUP_LOCATION_REQUESTED);
export const doReadPickupLocationSuccess = createAction(actionTypes.READ_PICKUP_LOCATION_SUCCEEDED);
export const doReadPickupLocationFailure = createAction(actionTypes.READ_PICKUP_LOCATION_FAILED);
