import React from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../../config/routes';

import TermsAndConditions from '../../pages/TermsAndConditions';

import { doManageNotification } from '../../redux/actionCreators/miscellaneous';
import { doRefreshAuthToken } from '../../redux/actionCreators/authentication';

import { selectAuthToken } from '../../redux/selectors/authentication';

const AuthenticationContainer = () => {
  const location = useRouteMatch().path;
  switch (location) {
    case routes.public.termsAndConditions:
      return <TermsAndConditions />;
    default:
      return false;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    manageNotification: (payload) => dispatch(doManageNotification(payload)),
    refreshAuthToken: () => dispatch(doRefreshAuthToken()),
  };
};

const mapStateToProps = (state) => {
  return {
    authToken: selectAuthToken(state),
  };
};

AuthenticationContainer.propTypes = {
  manageNotification: PropTypes.func.isRequired,
  refreshAuthToken: PropTypes.func.isRequired,
  authToken: PropTypes.string,
};

AuthenticationContainer.defaultProps = {
  authToken: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer);
