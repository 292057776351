import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import routes from '../../config/routes';
import { menuCategory } from './menuCategory';
// MUI
import {
  makeStyles,
  SwipeableDrawer,
  Grid,
  Button,
  IconButton,
  useTheme,
  Divider,
  Typography,
} from '@material-ui/core';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useDispatch } from 'react-redux';
import { doReadSelCategory } from '../../redux/selectors/categories';
import { doSetSelCategory } from '../../redux/actionCreators/categories';

const useStyles = makeStyles(() => ({
  closeDrawer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },

  drawerPaper: {
    width: '70%',
  },
  drawer: {
    width: '70%',
    flexShrink: 0,
  },
  drawerHeader: {
    margin: '20px 0',
    fontFamily: 'Glacial Indifference',
  },
  divider: {
    width: '100%',
  },
  shopItem: {
    textAlign: 'left',
    margin: '20px 15px',
    fontFamily: 'Glacial Indifference',
    cursor: 'pointer',
    '&:hover': {
      color: '#b48972 !important',
    },
  },

  menuItem: {
    margin: '20px',
  },

  // buttonLabel: {
  //   color: '#ffffff',
  // },
}));

const Drawer = ({ open, close }) => {
  // Hook
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  // States
  const [isShopOpen, setShopOpen] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  // Handlers
  const handleShopDrawer = () => {
    setHideHeader(true);
    setShopOpen(true);
  };

  const handleClose = () => {
    setHideHeader(false);
    setShopOpen(false);
  };
  // Route to shop item
  const setCat = (type) => {
    close();
    setHideHeader(false);
    setShopOpen(false);
    if (doReadSelCategory) {
      dispatch(doSetSelCategory(null));
    }
    dispatch(doSetSelCategory([type]));
    history.push(routes.dashboard.order);
  };
  return (
    <>
      {!hideHeader ? (
        <SwipeableDrawer
          anchor="right"
          onClose={close}
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{ padding: '10px 0', margin: '10px 0' }}
          >
            <IconButton className={classes.closeDrawer} onClick={close}>
              <CloseIcon />
            </IconButton>

            <Grid item className={classes.menuItem}>
              <Button onClick={close} className={classes.buttonLabel} component={Link} to="/">
                Home
              </Button>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item className={classes.menuItem}>
              <Button onClick={handleShopDrawer} className={classes.buttonLabel} component={Link}>
                Shop Online
              </Button>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item className={classes.menuItem}>
              <Button
                onClick={close}
                className={classes.buttonLabel}
                component={Link}
                to={routes.dashboard.menu}
              >
                Cafe Menu
              </Button>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item className={classes.menuItem}>
              <Button
                onClick={close}
                className={classes.buttonLabel}
                component={Link}
                to={routes.dashboard.gallery}
              >
                Gallery
              </Button>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item className={classes.menuItem}>
              <Button
                onClick={close}
                className={classes.buttonLabel}
                component={Link}
                to={routes.dashboard.about}
              >
                About us
              </Button>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item className={classes.menuItem}>
              <Button
                onClick={close}
                className={classes.buttonLabel}
                component={Link}
                to={routes.dashboard.contact}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      ) : (
        <SwipeableDrawer
          variant="persistent"
          anchor="right"
          open={isShopOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}>
              <IconButton onClick={handleClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.drawerHeader}>Shop Online</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container direction="row" style={{ maxWidth: '100%' }}>
            {menuCategory.map(({ name, id, category }) => (
              <Grid key={id} item xs={12} onClick={() => setCat(category)}>
                <Typography className={classes.shopItem}>{name}</Typography>
                <Divider className={classes.divider} />
              </Grid>
            ))}
            <Grid item xs={12} onClick={() => setCat('')}>
              <Typography className={classes.shopItem}>View All</Typography>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Drawer;
