// import { addDays, startOfToday, getMinutes } from 'date-fns';
import moment from 'moment';

const isFollowingDayDelivery = () => {
  const now = moment();

  const start = moment().hour(16).minute(0);
  // const start = moment().hour(20).minute(28);
  const end = moment().endOf('day');

  const isFollowingDay = now.isBetween(start, end);

  // console.log(
  //   '🚀 ~ file: isFollowingDayDelivery.js ~ line 6 ~ isFollowingDayDelivery ~ now',
  //   now,
  //   start,
  //   end,
  //   isFollowingDay,
  // );

  return isFollowingDay;

  // const now = new Date();
  // const isFollowingDay = now.getHours() >= 16 && now.getHours() < 24;
  // return isFollowingDay;
};

export default isFollowingDayDelivery;
