import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// import style from "./style.module.scss";

const NotificationSnackbar = ({ isVisible, message, createNotification }) => {
  const handleClose = () => {
    createNotification({ isVisible: false, message: "" });
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isVisible}
        autoHideDuration={6000}
        onClose={() => handleClose()}
        message={message}
        action={
          <Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      />
    </Fragment>
  );
};

NotificationSnackbar.propTypes = {
  createNotification: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

NotificationSnackbar.defaultProps = {};

export default NotificationSnackbar;
