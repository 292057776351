import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/items';
import * as actionCreators from '../actionCreators/items';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';

const restructureItems = async (itemList) =>
  itemList.map((item) => {
    const restructuredItem = { ...item, ...item.item };
    delete restructuredItem['item'];
    return restructuredItem;
  });

function* readItems({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(APIService.readItems, payload);
    //! data[0] contains items for default pricelist
    if (data && data.length) {
      const fetchedItems = yield call(restructureItems, data[0].itemPriceList);
      yield put(actionCreators.doReadItemsSuccess(fetchedItems));
    } else {
      yield put(actionCreators.doReadItemsSuccess([]));
    }
  } catch (error) {
    const message = error.response.data.message || "Couldn't fetch items";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doReadItemsFailure());
  }
}

const itemsSaga = [takeLatest(actionTypes.READ_ITEMS_REQUESTED, readItems)];

export default itemsSaga;
