import { call, put, takeLatest, debounce, select } from 'redux-saga/effects';
import { doClearCart, doReadCart } from '../actionCreators/cart';
import * as actionCreators from '../actionCreators/authentication';
import * as actionTypes from '../actionTypes/authentication';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';
import * as firebaseHelper from '../../utils/firebase';
import { doHideLoginModal, doHideRegisterModal, doShowLoginModal } from '../actionCreators/ui';
import { selectUserData } from '../selectors/authentication';
import routes from '../../config/routes';
import { push } from 'react-router-redux';

function* requestLogin({ payload }) {
  // yield put(misc.doToggleLoadingBar({ isLoadingVisible: true }));
  try {
    yield put(
      misc.doManageNotification({
        isVisible: true,
        message: 'Logging you in',
      }),
    );

    const authUser = yield call(
      firebaseHelper.loginUser,
      payload.email,
      payload.password,
      payload.remember,
    );
    const idToken = yield call(firebaseHelper.fetchToken, authUser.user);
    yield call(APIService.authenticateUser, idToken);
    const {
      data: {
        data: { user },
      },
    } = yield call(APIService.fetchUserData);

    yield put(actionCreators.doLoginSuccess(user));
    yield put(doHideLoginModal());
    yield put(actionCreators.doRefreshAuthToken());
    yield put(doReadCart());

    yield put(push(routes.dashboard.order));

    yield put(
      misc.doManageNotification({
        isVisible: true,
        message: 'Successfully logged in',
        autoHideDuration: 1000,
      }),
    );
  } catch (error) {
    yield call(firebaseHelper.logout);
    yield put(misc.doManageNotification({ isVisible: true, message: error.message }));
    yield put(actionCreators.doLoginFailure(error));
  } finally {
    // yield put(misc.doToggleLoadingBar({ isLoadingVisible: false }));
  }
}

function* refreshAuthToken() {
  try {
    const currentUser = yield call(firebaseHelper.fetchCurrentUser);
    const token = yield call(firebaseHelper.fetchToken, currentUser);
    yield put(actionCreators.doRefreshAuthTokenSuccess(token));
  } catch (error) {
    yield put(actionCreators.doRefreshAuthTokenFailure(error));
  }
}

function* requestLogout() {
  try {
    yield call(firebaseHelper.logout);
    yield put(doClearCart());
    yield put(actionCreators.doLogoutSuccess());
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't log you out" }));
    yield put(actionCreators.doLogoutFailure(error));
  }
}

function* requestRegister({ payload }) {
  try {
    const data = {
      ...payload,
      dob: new Date(payload.dob).toISOString(),
      phone: payload.phone.startsWith('+91') ? payload.phone : `+91${payload.phone}`,
      role: 'customer',
    };
    yield call(APIService.registerUser, data);

    yield put(actionCreators.doRegisterSuccess());

    yield put(doHideRegisterModal());
    yield put(misc.doManageNotification({ isVisible: true, message: 'Successfully Registered' }));
    yield put(doShowLoginModal());
  } catch (error) {
    let message =
      error.response && error.response.status === 403
        ? 'User with this email already exists'
        : "Couldn't Register";

    if (error.response.status === 500) {
      yield put(actionCreators.doEnableOtpField());
      message = 'Phone number already exists, please try with another mobile number ';
    }

    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doRegisterFailure(error));
  }
}

function* updateProfile({ payload }) {
  try {
    const { id } = yield select(selectUserData);
    yield call(APIService.updateUserProfile, id, payload);
    yield put(
      misc.doManageNotification({ isVisible: true, message: 'Updated profile successfully' }),
    );
    yield put(actionCreators.doUpdateProfileSuccess(payload));
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't Update Profile" }));
    yield put(actionCreators.doUpdateProfileFailure());
  }
}

function* changePassword({ payload }) {
  try {
    const { id } = yield select(selectUserData);
    yield call(APIService.changePassword, id, payload);
    yield put(
      misc.doManageNotification({ isVisible: true, message: 'Change password successfully' }),
    );
    yield put(actionCreators.doChangePasswordSuccess(payload));
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't change password" }));
    yield put(actionCreators.doChangePasswordFailure());
  }
}

const authenticationSaga = [
  takeLatest(actionTypes.AUTH_LOGIN_REQUESTED, requestLogin),
  takeLatest(actionTypes.AUTH_REFRESH_FIREBASE_TOKEN, refreshAuthToken),
  takeLatest(actionTypes.AUTH_LOGOUT_REQUESTED, requestLogout),
  takeLatest(actionTypes.AUTH_REGISTER_REQUESTED, requestRegister),
  takeLatest(actionTypes.CHANGE_PASSWORD_REQUESTED, changePassword),
  debounce(800, actionTypes.UPDATE_PROFILE_REQUESTED, updateProfile),
];

export default authenticationSaga;
