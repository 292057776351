export const menuCategory = [
  {
    id: 1,
    name: 'Cakes',
    image: 'images/Cakes.png',
    category: 'Cakes',
  },
  {
    id: 2,
    name: 'Bread',
    image: 'images/Bread.png',
    category: 'bread',
  },
  {
    id: 3,
    name: 'Savouries',
    image: 'images/Savouries.png',
    category: 'savouries',
  },
  {
    id: 4,
    name: 'Desserts',
    image: 'images/Desserts.jpg',
    category: 'desserts',
  },
  {
    id: 5,
    name: 'Tea Cakes & Cookies',
    image: 'images/TeaCakeCookies.png',
    category: 'tea cakes & cookies',
  },
  {
    id: 6,
    name: 'Platters',
    image: 'images/Platter.jpg',
    category: 'platters',
  },
];
