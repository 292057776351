import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    maxWidth: 350,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 350,
    },
  },
  media: {
    width: 350,
    objectFit: 'cover',
    [theme.breakpoints.between('sm', 'md')]: {
      width: 300,
    },
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  text: {
    textAlign: 'center',
  },
}));
