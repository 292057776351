import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import _ from 'lodash';
import shortid from 'shortid';
import voca from 'voca';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
// import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { doReadItems } from '../../redux/actionCreators/items';
import { doShowLoginModal } from '../../redux/actionCreators/ui';
import { doReadCategories, doSetSelCategory } from '../../redux/actionCreators/categories';
import { doReadSelCategory } from '../../redux/selectors/categories';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import Filters from '../../components/Filters';
import SearchBar from '../../components/SearchBar';
import ItemCard from '../../components/ItemCard';
import ItemModal from '../../components/ItemModal';
import QuickCartItemCard from '../../components/QuickCartItemCard';
import routes from '../../config/routes';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  searchButton: {
    height: '3rem',
    // [theme.breakpoints.down('md')]: {
    //   display: 'none',
    // },
  },
  container: {
    width: '100%',
  },
  topPadding: {
    paddingTop: '1rem',
  },
  filterCard: {
    // display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  drawer: {
    width: '20rem',
    height: '90%',
  },
  drawerHeader: {
    padding: '0.5rem 0 0.5rem 1rem',
    backgroundColor: theme.palette.primary.main,
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  drawerTitle: {
    color: theme.palette.secondary.dark,
  },
  drawerContent: {
    height: '100%',
  },
  drawerButton: {
    padding: '1rem',
  },
  itemWrapper: {
    marginTop: '2rem',
  },
  cartPopup: {
    zIndex: '10',
  },
  cartPopupContent: {
    maxHeight: '25rem',
    overflow: 'auto',
    paddingBottom: 60,
  },
  cartPopupAppBar: {
    top: 'auto',
    bottom: 0,
  },
}));

const defaultPriceRange = { min: 0, max: 100000 };

const sortOptions = [
  { key: 'DEF', label: 'Default' },
  { key: 'NLH', label: 'Name A-Z' },
  { key: 'PLH', label: 'Price Low-High' },
];

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Dashboard = ({
  items,
  categories,
  addToCartHandler,
  cartData,
  removeFromCartHandler,
  updateFromCartHandler,
  authToken,
  manageNotification,
  refreshAuthToken,
}) => {
  const query = useQuery();
  const queryCategory = query.get('category');
  const classes = useStyles();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [disableAddToCart, setDisableAddToCart] = useState(false);
  const [isQuickCartViewOpen, setQuickCartViewOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [priceRange, setPriceRange] = useState([defaultPriceRange.min, defaultPriceRange.max]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [isOnlyVeg, setOnlyVeg] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [openNotificationModal, setNotificationModalOpen] = useState(
    authToken === null ? false : true && new Date().getHours() >= 15 && new Date().getHours() < 24,
  );

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleQuickCartViewToggle = () => {
    setQuickCartViewOpen(!isQuickCartViewOpen);
  };

  const addItemToCart = (order) => {
    addToCartHandler(order);
  };

  const removeItemFromCart = (order) => {
    removeFromCartHandler(order);
  };

  const updateItemFromCart = (order) => {
    updateFromCartHandler(order);
  };

  const handleQuickCartViewClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setQuickCartViewOpen(false);
  };

  const handleOpenLoginModal = () => {
    dispatch(doShowLoginModal());
  };

  const areFiltersActive = () => {
    let isPriceRangeUpdated = false;
    if (priceRange[0] !== defaultPriceRange.min || priceRange[1] !== defaultPriceRange.max) {
      isPriceRangeUpdated = true;
    }
    return categoriesSelected.length > 0 || isPriceRangeUpdated;
  };

  const filteredItems = () => {
    const categoryIds = categoriesSelected.map((category) => {
      return category.value;
    });
    let sortArray = [];
    const unfilteredItems = items.filter((item) => item.availability);
    switch (sortOption.key) {
      case 'NLH':
        sortArray = ['name'];
        break;
      case 'PLH':
        sortArray = ['price'];
        break;
      default:
        sortArray = [];
    }

    let sortedItemsArray = [];

    if (JSON.stringify(sortArray) === JSON.stringify(['price'])) {
      sortedItemsArray = _.sortBy(unfilteredItems, [(item) => Number(item.price)]);
    } else sortedItemsArray = _.sortBy(unfilteredItems, sortArray);

    return sortedItemsArray
      .filter((item) => {
        if (categoriesSelected.length === 0) {
          return true;
        } else {
          return categoryIds.includes(item.categoryId);
        }
      })
      .filter((item) => {
        return item.price >= priceRange[0] && item.price <= priceRange[1];
      })
      .filter((item) => {
        return item.name.toLowerCase().search(searchTerm.toLowerCase()) !== -1;
      })
      .filter((item) => {
        if (isOnlyVeg) {
          return item.veg || item.isVeg;
        } else {
          return true;
        }
      });
  };

  const cartTotalItems = () => {
    let initialValue = 0;
    cartData.map((item) => (initialValue += item.quantity));
    return initialValue;
  };

  const selCat = useSelector(doReadSelCategory);

  useEffect(
    function loadPublicData() {
      const url = authToken ? '/menu' : '/publicmenu';
      dispatch(doReadItems(url));
      dispatch(doReadCategories());
      let arr = [];
      const arrLabel = [];
      if (selCat) {
        categories.forEach((cat) => {
          selCat.forEach((selCatN) => {
            if (cat.name.toLowerCase() === selCatN.toLowerCase()) {
              arr.push({ label: cat.name, value: cat.id });
              arrLabel.push(cat.name);
            }
          });
        });

        if (queryCategory) {
          const cleanedCategoryArray = voca.chain(queryCategory).split('-').value().join(' ');
          const cleanedCategory = voca.titleCase(cleanedCategoryArray);

          categories.forEach((cat) => {
            if (cat.name.toLowerCase() === cleanedCategory.toLowerCase()) {
              arr.push({ label: cat.name, value: cat.id });
            }
          });
        }
        arr = _.uniqWith(arr, _.isEqual);

        setCategoriesSelected([...arr]);
        dispatch(doSetSelCategory([...arrLabel]));
      }
    }, // eslint-disable-next-line
    [dispatch, authToken],
  );

  useEffect(() => {
    let arrLabel = [];
    arrLabel = categoriesSelected.map((cat) => cat.label);
    dispatch(doSetSelCategory([...arrLabel]));
  }, [categoriesSelected, dispatch]);

  useEffect(() => {
    if (authToken !== null && new Date().getHours() >= 15 && new Date().getHours() < 24) {
      setNotificationModalOpen(true);
    }
  }, [authToken]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container direction="column" className={classes.topPadding}>
            <Grid item xs={12} container direction="row" spacing={2}>
              <Grid item xs={12} md={10}>
                <SearchBar
                  toggleDrawer={toggleDrawer}
                  areFiltersActive={areFiltersActive()}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={12} md={2}>
                  <div>
                    <ButtonGroup
                      variant="contained"
                      color="primary"
                      aria-label="split button"
                      className={classes.searchButton}
                      ref={anchorRef}
                    >
                      <Button
                        component={RouterLink}
                        to={routes.dashboard.cart}
                        fullWidth
                        startIcon={<ShoppingCartIcon />}
                        color="secondary"
                        size="medium"
                        onClick={() => {}}
                        disabled={authToken === null}
                      >
                        Cart
                      </Button>
                      <Button
                        color="secondary"
                        size="medium"
                        aria-controls={isQuickCartViewOpen ? 'split-button-menu' : undefined}
                        aria-expanded={isQuickCartViewOpen ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleQuickCartViewToggle}
                        disabled={cartData.length === 0}
                      >
                        {cartTotalItems()}
                      </Button>
                    </ButtonGroup>
                    <Popper
                      open={isQuickCartViewOpen}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      className={classes.cartPopup}
                      placement="bottom-end"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper className={classes.cartPopupContent}>
                            <ClickAwayListener onClickAway={handleQuickCartViewClose}>
                              <MenuList id="split-button-menu">
                                {cartData.map((item, index) => (
                                  <QuickCartItemCard
                                    style={{ padding: '10px' }}
                                    key={shortid.generate()}
                                    data={item}
                                    removeFromCartHandler={removeItemFromCart}
                                    cartData={cartData}
                                  />
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div className={cx(classes.container, classes.filterCard)}>
                <Filters
                  categories={categories}
                  priceRange={priceRange}
                  setPriceRange={setPriceRange}
                  categoriesSelected={categoriesSelected}
                  setCategoriesSelected={setCategoriesSelected}
                  defaultPriceRange={defaultPriceRange}
                  isOnlyVeg={isOnlyVeg}
                  setOnlyVeg={setOnlyVeg}
                  sortOptions={sortOptions}
                  setSortOption={setSortOption}
                />
              </div>
            </Grid>

            <Grid className={classes.itemWrapper} item xs={12}>
              <Grid container spacing={4}>
                {filteredItems().map((item) => {
                  return (
                    <>
                      {item.price === null || (
                        <ItemCard
                          key={item.uuId}
                          data={item}
                          setSelectedItem={setSelectedItem}
                          setModalOpen={setModalOpen}
                          addItemToCart={addItemToCart}
                          updateItemFromCart={updateItemFromCart}
                          cartData={cartData}
                          authToken={authToken}
                          manageNotification={manageNotification}
                          refreshAuthToken={refreshAuthToken}
                          handleOpenLoginModal={handleOpenLoginModal}
                          disableAddToCart={disableAddToCart}
                          setDisableAddToCart={setDisableAddToCart}
                        />
                      )}
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer()}
        onOpen={() => {}}
      >
        <Box className={classes.drawer}>
          <Grid
            container
            justify="space-between"
            alignContent="center"
            className={classes.drawerHeader}
          >
            <div>
              <Typography component="span" variant="h6" className={classes.drawerTitle}>
                Filters
              </Typography>
            </div>

            <IconButton
              color="secondary"
              className={cx(classes.iconButton)}
              aria-label="close drawer"
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            className={classes.drawerContent}
            container
            direction="column"
            justify="space-between"
          >
            <Filters
              categories={categories}
              categoriesSelected={categoriesSelected}
              setCategoriesSelected={setCategoriesSelected}
              defaultPriceRange={defaultPriceRange}
              priceRange={priceRange}
              setPriceRange={setPriceRange}
              isOnlyVeg={isOnlyVeg}
              setOnlyVeg={setOnlyVeg}
              sortOptions={sortOptions}
              setSortOption={setSortOption}
            />
            <Grid item className={classes.drawerButton}>
              <Button onClick={() => toggleDrawer()} fullWidth variant="contained" color="primary">
                Done
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
      <ItemModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        selectedItem={selectedItem}
        addItemToCart={addItemToCart}
        auth={authToken}
        disableAddToCart={disableAddToCart}
        setDisableAddToCart={setDisableAddToCart}
      />
      <Dialog
        open={openNotificationModal}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order before 4pm for next day delivery</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* Please ensure orders are placed before 4pm to avail of our Next-Day-Delivery. Orders
            placed after 4pm will be delivered on the second day after order is placed. */}
          </DialogContentText>
          <Typography variant="caption" color="secondary">
            * Delivery date is subject to quantity ordered
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNotificationModalOpen(false);
            }}
            color="primary"
          >
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Dashboard;
