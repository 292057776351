import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    type: 'light',
    primary: {
      light: '#F3F0E8',
      main: '#F2BD82',
      dark: '#E28C2B',
      contrastText: '#62321E',
    },
    secondary: {
      light: '#B48972',
      main: '#B25D30',
      dark: '#62321E',
      contrastText: '#fff',
    },
  },
};

const muiTheme = createMuiTheme(theme);

export default muiTheme;
