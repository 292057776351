import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import history from './history';
import persistedRootReducer from './reducers';
import rootSaga from './sagas';
import LogRocket from 'logrocket';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middlewares.push(logger);
}

const configureStore = (preloadedState = undefined) => {
  const middlewareEnhancer = applyMiddleware(...middlewares, LogRocket.reduxMiddleware());
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);

  const store = createStore(persistedRootReducer, preloadedState, composedEnhancers);

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export default configureStore;
