import { createAction } from 'redux-actions';

import * as actionTypes from '../actionTypes/authentication';

export const doLogin = createAction(actionTypes.AUTH_LOGIN_REQUESTED);
export const doLoginSuccess = createAction(actionTypes.AUTH_LOGIN_SUCCEEDED);
export const doLoginFailure = createAction(actionTypes.AUTH_LOGIN_FAILED);

export const doRegister = createAction(actionTypes.AUTH_REGISTER_REQUESTED);
export const doRegisterSuccess = createAction(actionTypes.AUTH_REGISTER_SUCCEEDED);
export const doRegisterFailure = createAction(actionTypes.AUTH_REGISTER_FAILED);

export const doLogout = createAction(actionTypes.AUTH_LOGOUT_REQUESTED);
export const doLogoutSuccess = createAction(actionTypes.AUTH_LOGOUT_SUCCEEDED);
export const doLogoutFailure = createAction(actionTypes.AUTH_LOGOUT_FAILED);

export const doEmailVerification = createAction(actionTypes.AUTH_EMAIL_VERIFICATION_REQUESTED);
export const doEmailVerificationSuccess = createAction(
  actionTypes.AUTH_EMAIL_VERIFICATION_SUCCEEDED,
);
export const doEmailVerificationFailure = createAction(actionTypes.AUTH_EMAIL_VERIFICATION_FAILED);

export const doRegistrationPayment = createAction(actionTypes.AUTH_REGISTRATION_PAYMENT_REQUESTED);
export const doRegistrationPaymentSuccess = createAction(
  actionTypes.AUTH_REGISTRATION_PAYMENT_SUCCEEDED,
);
export const doRegistrationPaymentFailure = createAction(
  actionTypes.AUTH_REGISTRATION_PAYMENT_FAILED,
);

export const doPaymentNullifier = createAction(actionTypes.AUTH_PAYMENT_NULLIFIER);
export const doEmailVerificationStatusNullifier = createAction(
  actionTypes.AUTH_EMAIL_VERIFICATION_STATUS,
);

export const doRefreshAuthToken = createAction(actionTypes.AUTH_REFRESH_FIREBASE_TOKEN);
export const doRefreshAuthTokenSuccess = createAction(
  actionTypes.AUTH_REFRESH_FIREBASE_TOKEN_SUCCEEDED,
);
export const doRefreshAuthTokenFailure = createAction(
  actionTypes.AUTH_REFRESH_FIREBASE_TOKEN_FAILED,
);

export const doUpdateProfile = createAction(actionTypes.UPDATE_PROFILE_REQUESTED);
export const doUpdateProfileSuccess = createAction(actionTypes.UPDATE_PROFILE_SUCCEEDED);
export const doUpdateProfileFailure = createAction(actionTypes.UPDATE_PROFILE_FAILED);

export const doEnableOtpField = createAction(actionTypes.ENABLE_OTP_FIELD);
export const doDisableOtpField = createAction(actionTypes.DISABLE_OTP_FIELD);

export const doChangePassword = createAction(actionTypes.CHANGE_PASSWORD_REQUESTED);
export const doChangePasswordSuccess = createAction(actionTypes.CHANGE_PASSWORD_SUCCEEDED);
export const doChangePasswordFailure = createAction(actionTypes.CHANGE_PASSWORD_FAILED);
