import { all } from 'redux-saga/effects';

import authenticationSaga from './authenticationSaga';
import itemsSaga from './itemsSaga';
import cartSaga from './cartSaga';
import categoriesSaga from './categoriesSaga';
import locationsSaga from './locationsSaga';
import ordersSaga from './ordersSaga';
import addressesSaga from './addressesSaga';

function* watchAll() {
  yield all([
    ...authenticationSaga,
    ...itemsSaga,
    ...cartSaga,
    ...categoriesSaga,
    ...locationsSaga,
    ...ordersSaga,
    ...addressesSaga,
  ]);
}

export default watchAll;
