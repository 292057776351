import React from 'react';
import { useHistory } from 'react-router-dom';

// Mui
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Invoices from './Invoices';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { doAddMultipleToCart, doUpdateMultipleCart } from '../../redux/actionCreators/cart';
import { doReadCartItems } from '../../redux/selectors/cart';
import { doReadItems } from '../../redux/selectors/items';
import { doResendInvoice, doSendSummary } from '../../redux/actionCreators/orders';

import calculateDeliveryDate from '../../utils/calculateDeliveryDate';
// import { sleep } from '../../utils/misc';
import './index.css';
const useStyles = makeStyles((theme) => ({
  card: {
    border: '1px solid #62321E',
    borderRadius: '0.25rem',
  },
  image: {
    maxHeight: '60px',
    objectFit: 'cover',
    maxWidth: '60px',
  },
}));

const OrderCard = (props) => {
  const classes = useStyles();
  const {
    id,
    orderItems,
    orderDate,
    price,
    tax,
    customer,
    location,
    specialRequest,
    invoices,
  } = props;
  const cartData = useSelector(doReadCartItems);
  const itemsData = useSelector(doReadItems);
  const dispatch = useDispatch();
  const history = useHistory();

  // To add image urls to the order items
  const filteredItems = [];
  let isDeliveryPending = false;
  for (let i = 0; i < orderItems.length; i++) {
    for (let j = 0; j < props.items.length; j++) {
      if (orderItems[i].itemId === props.items[j].itemId) {
        filteredItems[i] = {
          ...orderItems[i],
          images: props.items[j].images,
        };
        break;
      }
    }
  }

  function handleInvoice(invoiceNo) {
    dispatch(doResendInvoice({ invoiceNo }));
  }

  function handleOrderSummary() {
    dispatch(doSendSummary(orderItems[0].orderId));
  }

  // for (let i = 0; i < filteredItems.length; i++) {
  //   if (filteredItems[i].deliveryStatus === 'pending') {
  //     isDeliveryPending = true;
  //   }
  // }

  isDeliveryPending = filteredItems.some(({ deliveryStatus }) => deliveryStatus === 'pending');

  const handleOrderAgain = (event) => {
    event.stopPropagation();
    const requestPayload = [];
    const updatePayload = [];
    orderItems.forEach((itemOrder, indexOrder) => {
      let item = itemOrder;
      let itemAvailable = false;
      itemsData.forEach((itemObj, indexItem) => {
        if (itemOrder.itemId === itemObj.itemId) {
          if (itemObj.availability) itemAvailable = true;
          item = itemObj;
          return;
        }
      });
      let itemUpdateStatus = false;

      if (!itemAvailable) return;

      //let midObject = {};
      if (cartData.length === 0) {
        // midObject = {
        //   ...item,
        //   quantity: itemOrder.quantity,
        // };
        requestPayload.push({
          ...item,
          quantity: itemOrder.quantity,
          deliveryDate: calculateDeliveryDate(item.item, itemOrder.quantity),
        });
      } else {
        for (let i = 0; i < cartData.length; i++) {
          if (cartData[i].itemId === item.itemId) {
            itemUpdateStatus = true;
            // midObject = {
            //   ...item,
            //   quantity: cartData[i].quantity + itemOrder.quantity,
            // };

            // const arr = cartData.filter((prev) => prev.itemId !== item.itemId);
            // arr.push(midObject);
            const addedQuantity = cartData[i].quantity + itemOrder.quantity;
            updatePayload.push({
              ...item,
              quantity: addedQuantity,
              deliveryDate: calculateDeliveryDate(item.item, addedQuantity),
            });
            break;
          }
        }

        if (itemUpdateStatus) {
        } else {
          // midObject = {
          //   ...item,
          //   quantity: itemOrder.quantity,
          // };
          requestPayload.push({
            ...item,
            quantity: itemOrder.quantity,
            deliveryDate: calculateDeliveryDate(item.item, itemOrder.quantity),
          });
        }
      }
    });

    //if (cartData.length === 0) {
    if (requestPayload.length) {
      dispatch(
        doAddMultipleToCart({
          data: requestPayload,
          history,
        }),
      );
    }

    if (updatePayload.length) {
      dispatch(doUpdateMultipleCart({ data: updatePayload }));
    }
    //}

    // setTimeout(function redirectToCart() {
    //   dispatch(doReadCart());
    //   history.push('/cart');
    // }, 5000);
  };

  return (
    <div className="order-div" style={isDeliveryPending ? { border: '4px solid #b25d30' } : null}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Typography variant="button">Order Placed</Typography>
          <Typography variant="caption" component="p">
            {new Date(orderDate).toDateString()}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Deliver To</Typography>
          <Typography variant="caption" component="p">
            {customer.firstName}, {location.name}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Total</Typography>
          <Typography variant="caption" component="p">
            ₹ {Math.round(Number(price) + Number(tax))}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Order ID</Typography>
          <Typography variant="caption" component="p">
            #{id}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Payment Status</Typography>
          <Typography variant="caption" component="p">
            {orderItems[0] === undefined ? '' : orderItems[0].paymentStatus}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Payment Method</Typography>
          <Typography variant="caption" component="p">
            {orderItems[0] === undefined ? '' : orderItems[0].paymentMethod}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Order Type</Typography>
          <Typography variant="caption" component="p">
            {orderItems[0] === undefined ? '' : orderItems[0].orderType}
          </Typography>
        </Grid>

        <Grid item xs={6} md={3}>
          <Typography variant="button">Special Request</Typography>
          <Typography variant="caption" component="p">
            {specialRequest === undefined ? '' : specialRequest}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid container item xs={12} md={9}>
          <Grid container item spacing={2} className="py-2">
            {filteredItems.map((orderItem) => {
              return (
                <>
                  <Grid item xs={6} sm={4} md={2}>
                    <img
                      src={
                        orderItem.images && orderItem.images.length > 0 && orderItem.images[0]
                          ? `${orderItem.images[0].key}`
                          : 'https://picsum.photos/600/400'
                      }
                      alt={orderItem.item.name}
                      className={classes.image}
                      loading="lazy"
                    />
                  </Grid>

                  <Grid item xs={6} sm={8} md={10}>
                    <Typography>{`${orderItem.quantity} x ${orderItem.item.name}`}</Typography>
                    {/* <Typography variant="caption"> */}
                    {orderItem.deliveryStatus === 'pending' ? (
                      <h4 class="badge badge-pill badge-primary">Delivery Pending</h4>
                    ) : orderItem.deliveryStatus === 'cancelled' ? (
                      <h4 class="badge badge-pill badge-danger">Cancelled</h4>
                    ) : orderItem.deliveryStatus === 'incomplete' ? (
                      <h4 class="badge badge-pill badge-danger">Incomplete</h4>
                    ) : (
                      <Typography variant="caption">Delivered</Typography>
                    )}
                    {/* </Typography> */}
                    <Grid item xs={6} md={3}>
                      <Typography variant="button">Delivery Date</Typography>
                      <Typography variant="caption" component="p">
                        {new Date(orderItem.deliveryDate).toDateString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          {/* {filteredItems.map((orderItem) => ( */}

          <>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="my-1"
              onClick={() => handleOrderSummary()}
            >
              Resend Order Summary
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="my-1"
              onClick={handleOrderAgain}
            >
              ORDER AGAIN
            </Button>{' '}
          </>
          {/* ))} */}
        </Grid>
      </Grid>
      {invoices.length === 0 || (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Invoices invoices={invoices} handleInvoice={handleInvoice} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default OrderCard;
