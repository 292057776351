import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';
import routes from './config/routes';

import AuthenticationContainer from './containers/AuthenticationContainer';
import DashboardContainer from './containers/DashboardContainer';
import CartContainer from './containers/CartContainer';
import ProfileContainer from './containers/UserProfile';
import OrdersPage from './pages/Orders';
import LandingPage from './pages/LandingPage';

import LandingPageLayout from './layouts/LandingPageLayout';
import CustomerDashboardLayout from './layouts/CustomerDashboardLayout';

import LoginModal from './components/LoginModal';
import RegisterModal from './components/RegisterModal';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';
import CancellationAndRefunds from './pages/CancellationAndRefunds';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Menu from './pages/Menu';

const Router = () => {
  return (
    <Fragment>
      <Switch>
        <CustomerDashboardLayout
          exact
          path={routes.public.termsAndConditions}
          component={AuthenticationContainer}
        />
        <CustomerDashboardLayout
          exact
          path={routes.public.cancellationAndRefnds}
          component={CancellationAndRefunds}
        />
        <CustomerDashboardLayout
          exact
          path={routes.public.privacyPolicy}
          component={PrivacyPolicy}
        />
        <CustomerDashboardLayout exact path={routes.public.disclaimer} component={Disclaimer} />
        <LandingPageLayout exact path={routes.dashboard.home} component={LandingPage} />
        <CustomerDashboardLayout
          exact
          path={routes.dashboard.order}
          component={DashboardContainer}
        />
        <LandingPageLayout exact path={routes.dashboard.about} component={About} />

        <LandingPageLayout exact path={routes.dashboard.contact} component={Contact} />

        <LandingPageLayout exact path={routes.dashboard.gallery} component={Gallery} />

        <LandingPageLayout exact path={routes.dashboard.menu} component={Menu} />

        <CustomerDashboardLayout exact path={routes.dashboard.cart} component={CartContainer} />

        <CustomerDashboardLayout
          exact
          path={routes.dashboard.account}
          component={DashboardContainer}
        />

        <CustomerDashboardLayout exact path={routes.dashboard.orders} component={OrdersPage} />

        <CustomerDashboardLayout exact path={routes.user.profile} component={ProfileContainer} />
      </Switch>

      <LoginModal />
      <RegisterModal />
    </Fragment>
  );
};

export default Router;
