import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { configureStore, history } from './redux';
import muiTheme from './config/theme';
import Router from './Router';
import './App.scss';
import Auth from './components/Auth';
import LogRocketWrapper from './components/LogRocket';

const { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={muiTheme}>
            <LogRocketWrapper>
              <CssBaseline>
                <Auth>
                  <Router />
                </Auth>
              </CssBaseline>
            </LogRocketWrapper>
          </MuiThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
