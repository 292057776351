import React from 'react';

// Mui
import { Grid, makeStyles } from '@material-ui/core';
import MenuItems from '../../components/MenuItems';
import LandingPageFooter from '../../components/LandingPageFooter';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  bannerImg: {
    width: '100vw',
    objectFit: 'cover',
    marginTop: '80px',
  },
}));

function Menu() {
  // Hooks
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        className={classes.root}
        direction="column"
        justify="center"
        alignItems="center"
      >
        {/* banner */}
        <Grid item xs={12} style={{ margin: '10px 0' }}>
          <img className={classes.bannerImg} src="/images/menu-banner.png" alt="banner" />
        </Grid>

        {/* Sub-Header */}
        <Grid item xs={12}>
          <MenuItems />
        </Grid>
      </Grid>
      <LandingPageFooter />
    </>
  );
}

export default Menu;
