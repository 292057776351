export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const isRelease = ['development', 'production'].includes(process.env.ENVIRONMENT);
export const isRelease = ['production'].includes(ENVIRONMENT);

export const VERSION = process.env.REACT_APP_VERSION || `local-${new Date().toISOString()}`;

export const KEY_AUTH_COOKIE = 'ofm-auth-cookie';
export const RZP_API_KEY = process.env.REACT_APP_RZP_API_KEY;
export const LOG_ROCKET_ID = process.env.REACT_APP_LOG_ROCKET_ID;

export const ORDER_DATE = 'ORDER DATE DESC';
export const ORDER_DATE_ASC = 'ORDER DATE ASC';
export const PRICE = 'PRICE DESC';
export const PRICE_ASC = 'PRICE ASC';
export const DEFAULT = 'DEFAULT';

// Delivery Types
export const deliveryTypes = {
  delivery: 'delivery',
  pickup: 'pickup',
};

export const paymentTypes = {
  cod: 'cod',
  card: 'card',
  credit: 'credit',
};

export const creditLimitPerc = 0.8;
export const codLocationId = 56;
export const codAddress = 'Patto, Goa';

const SOUTH_GOA_ROUTE_ID = 14;
const SOUTH_GOA_2_ROUTE_ID = 17;

export const INFO_ROUTE_IDS = [SOUTH_GOA_ROUTE_ID, SOUTH_GOA_2_ROUTE_ID];

export const footerItems = [
  [
    'Bread Crumb',
    'Assorted Croutons',
    'Bread Crumbs (1000 Gms)',
    'Bread Stick Methi',
    'Bread Sticks (Plain)',
    'Brown Baguette',
    'Chocolate Brioche',
    'Curry Leaves & Mustard Loaf',
    'Foccacia Onion & Garlic Bread',
  ],
  [
    'Hot Dog',
    'Jumbo Brown Bread',
    'Goan Poee',
    'Jumbo White Bread',
    'Loaf Spinach',
    'Masala Loaf',
    'Methi Ajwain Loaf',
    'Milk Bread',
    'Multigrain Loaf',
  ],
  [
    'Slice White Bread',
    'Whole Wheat Bread',
    'Butter Pao',
    'Panini Herb',
    'Pita Bread',
    'Plain Baguette',
    'Plain Pizza Base',
    'Premium English Butter Bread',
    'Rustica Country Loaf',
  ],
  [
    'Sandwich Bread',
    'XL Sandwich Bread',
    'Slice Healthy Whole Wheat',
    'Slice Meditterenean S/W Bread',
    'Slice Muesli S/W Bread',
    'Slice Sandwich Loaf',
    'Mini Pizza',
    'Dinner Rolls - Plain Buns',
  ],
];
