import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/locations';

const defaultState = {
  isLoading: false,
  locationsData: [],
  pickupLocation: null,
};

const locationsReducer = handleActions(
  {
    [actionCreators.doReadLocations]: (state) => {
      return { ...state, isLoading: true };
    },
    [actionCreators.doReadLocationsSuccess]: (state, { payload }) => {
      return { ...state, isLoading: false, locationsData: payload };
    },
    [actionCreators.doReadLocationsFailure]: (state) => {
      return { ...state, isLoading: false };
    },

    [actionCreators.doReadPickupLocation]: (state) => {
      return { ...state, isLoading: true };
    },
    [actionCreators.doReadPickupLocationSuccess]: (state, { payload }) => {
      return { ...state, isLoading: false, pickupLocation: payload };
    },
    [actionCreators.doReadPickupLocationFailure]: (state) => {
      return { ...state, isLoading: false };
    },
  },
  defaultState,
);

export default locationsReducer;
