import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  bannerImg: {
    width: '100vw',
    marginTop: '70px',
    objectFit: 'cover',
  },

  title: {
    color: '#e3c57a',
    fontFamily: 'Playfair Display',
    fontSize: '5rem',
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: 500,
    padding: '10px 0',
    margin: '10px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  text: {
    color: '#623912',
    fontSize: '25px',
    fontFamily: 'Glacial Indifference',
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: '3px',
    opacity: 0.7,
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      letterSpacing: '2px',
    },
  },

  gallery: {
    margin: '2em 0',
  },
}));
