import React, { useState } from 'react';
// import PropTypes from "prop-types";
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Reusable comps
import Drawer from './drawer';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { doReadCartItems } from '../../redux/selectors/cart';
import routes from '../../config/routes';
import { doLogout } from '../../redux/actionCreators/authentication';
import { selectAuthToken, selectUserData } from '../../redux/selectors/authentication';
import { doShowLoginModal } from '../../redux/actionCreators/ui';
import { Menu as MenuIcon } from '@material-ui/icons';
import MenuDropdown from './menuDropdown';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  // appbar: {
  //   background: 'none',
  // },
  toolbar: {
    // minHeight: 128,
    backgroundColor: '#F8F8F8',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    marginLeft: '0.2rem',
    height: '3rem',
  },
  hiddenBannerContainer: {
    display: 'flex',
    backgroundColor: '#603813',
    position: 'relative',
  },
  hiddenBannerLip: {
    position: 'absolute',
    bottom: '-1.4rem',
    right: '5rem',
    zIndex: '100',
    backgroundColor: '#603813',
    padding: '2px 10px',
    borderRadius: '0 0 6px 6px',
    cursor: 'pointer',
  },
  hiddenBannerLipText: {
    color: '#FFF',
  },
  banner: {
    padding: '2rem',
  },
  hiddenBannerContentTitle: {
    color: '#FFF',
  },
  hiddenBannerContentDescription: {
    color: '#e0e0e0',
    marginBottom: '2rem',
  },
  hiddenBannerDivider: {
    background: '#969696',
  },
  hiddenBannerDividerFW: {
    width: '100%',
  },

  // buttonLabel: {
  //   color: '#ffffff',
  // },
}));

const Header = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHiddenBannerShown, setHiddenBannerShown] = useState(false);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('sm'));

  const cartData = useSelector(doReadCartItems);
  const authToken = useSelector(selectAuthToken);
  const userData = useSelector(selectUserData);
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [menuDropdown, setMenuDropdown] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(doLogout());
    handleMenuClose();
  };

  const handleOpenLoginModal = () => {
    dispatch(doShowLoginModal());
  };

  const handleOpenHiddenBanner = () => {
    setHiddenBannerShown(!isHiddenBannerShown);
  };

  // const handleOpenRegisterModal = () => {
  //   dispatch(doShowRegisterModal());
  // };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDropdown = (event) => {
    setMenuDropdown(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setMenuDropdown(null);
  };

  return (
    <>
      <AppBar className={classes.appbar} elevation={0}>
        <div className={classes.hiddenBannerContainer}>
          <Collapse in={isHiddenBannerShown} collapsedHeight={7}>
            <Grid
              container
              direction={isMdDown ? 'column' : 'row'}
              spacing={isMdDown ? 2 : 0}
              justify="center"
              className={classes.banner}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
                justify="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.hiddenBannerContentTitle}
                    variant="h6"
                    gutterBottom
                  >
                    Myles High Health Concepts
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.hiddenBannerContentDescription}
                    gutterBottom
                  >
                    Myles High Bakehouse is your very own Goan Brand established in 2007 as a Myles
                    High Venture. It was the first Live Gourmet Bakery to be introduced in Goa, and
                    ever since then, it has been known to provide the highest quality products with
                    sincere service to its clients and guests while also setting new benchmarks in
                    this sector.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button href="/about" variant="outlined" color="primary">
                    About Us
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} container justify="center">
                <Divider
                  orientation={isMdDown ? 'horizontal' : 'vertical'}
                  className={cx(
                    classes.hiddenBannerDivider,
                    isMdDown ? classes.hiddenBannerDividerFW : '',
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                container
                direction="column"
                justify="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.hiddenBannerContentTitle}
                    variant="h6"
                    gutterBottom
                  >
                    Myles High Hotels & Towers
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.hiddenBannerContentDescription}
                    gutterBottom
                  >
                    We build family-oriented boutique hotels and vacation homes, allowing guests to
                    stay with their loved ones and enjoy the holiday altogether in this beautiful
                    state of Goa. We have also set high standards and benchmarks in the commercial
                    real estate of Goa by building exclusive and regal Corporate offices for all
                    industry leaders to be easily located and lavishly showcased.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button href="#" variant="outlined" color="primary">
                    Visit Website
                  </Button>
                </Grid>
              </Grid>
              {/*<Grid item xs={12} sm={12} md={1} lg={1} container justify="center">
                <Divider
                  orientation={isMdDown ? 'horizontal' : 'vertical'}
                  className={cx(
                    classes.hiddenBannerDivider,
                    isMdDown ? classes.hiddenBannerDividerFW : '',
                  )}
                />
              </Grid>
               <Grid
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                container
                direction="column"
                justify="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.hiddenBannerContentTitle}
                    variant="h6"
                    gutterBottom
                  >
                    Myles High Event Promoter
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.hiddenBannerContentDescription}
                    gutterBottom
                  >
                    Myles High Group is passionate about events & promotions and cater to the major
                    corporate & entertainment events in Goa. Look out for this section – Coming
                    Soon…
                  </Typography>
                </Grid>
                <Grid item>
                  <Button href="#" variant="outlined" color="primary">
                    Visit Website
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>
          </Collapse>
          <div className={classes.hiddenBannerLip} onClick={() => handleOpenHiddenBanner()}>
            <Typography variant="body2" className={classes.hiddenBannerLipText}>
              Group companies
            </Typography>
          </div>
        </div>

        <Toolbar className={classes.toolbar}>
          <RouterLink to="/">
            <img src="/images/logo5.png" className={classes.logo} alt="Myles High Bakehouse" />
          </RouterLink>
          <div className={classes.grow} />
          {authToken ? (
            <>
              <Hidden smDown>
                <Button className={classes.buttonLabel} component={Link} to="/">
                  Home
                </Button>
                <Button className={classes.buttonLabel} component={Link} onClick={handleDropdown}>
                  Shop Online
                </Button>
                <Button className={classes.buttonLabel} component={Link} to={routes.dashboard.menu}>
                  Cafe Menu
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  to={routes.dashboard.gallery}
                >
                  Gallery
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  to={routes.dashboard.about}
                >
                  About us
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  to={routes.dashboard.contact}
                >
                  Contact Us
                </Button>
                <MenuDropdown anchorEl={menuDropdown} close={handleDropdownClose} />
              </Hidden>
              <div className={classes.sectionDesktop}>
                <IconButton
                  component={RouterLink}
                  edge="end"
                  aria-label="cart of current user"
                  to={routes.dashboard.cart}
                  // onClick={handleQuickCartViewToggle}
                  color="inherit"
                >
                  <Badge badgeContent={cartData.length} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                <ButtonBase
                  style={{ marginLeft: '20px' }}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                >
                  <AccountCircleIcon />
                  <Typography variant="body2">{userData && userData.firstName}</Typography>
                </ButtonBase>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  component={RouterLink}
                  edge="end"
                  aria-label="cart of current user"
                  to={routes.dashboard.cart}
                  // onClick={handleQuickCartViewToggle}
                  color="inherit"
                >
                  <Badge badgeContent={cartData.length} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircleIcon />
                </IconButton>

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              {/* Hamburger menu */}
              <Hidden mdUp>
                <>
                  {/* Reuable comp */}
                  <Drawer
                    open={isDrawerOpen}
                    close={handleDrawerClose}
                    authToken={authToken}
                    modal={handleOpenLoginModal}
                  />
                </>
              </Hidden>
            </>
          ) : (
            <>
              <Hidden smDown>
                <Button className={classes.buttonLabel} component={Link} to="/">
                  Home
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  onClick={handleDropdown}
                  // to={routes.dashboard.order}
                >
                  Shop Online
                </Button>
                <Button className={classes.buttonLabel} component={Link} to={routes.dashboard.menu}>
                  Cafe Menu
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  to={routes.dashboard.gallery}
                >
                  Gallery
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  to={routes.dashboard.about}
                >
                  About us
                </Button>
                <Button className={classes.buttonLabel} onClick={handleOpenLoginModal}>
                  Sign In
                </Button>
                <Button
                  className={classes.buttonLabel}
                  component={Link}
                  to={routes.dashboard.contact}
                >
                  Contact Us
                </Button>
                <MenuDropdown anchorEl={menuDropdown} close={handleDropdownClose} />
              </Hidden>
              {/* Hamburger menu */}
              <Hidden mdUp>
                <>
                  {!authToken && (
                    <>
                      <Button className={classes.buttonLabel} onClick={handleOpenLoginModal}>
                        Sign in
                      </Button>
                      <IconButton
                        component={RouterLink}
                        edge="end"
                        aria-label="cart of current user"
                        to={routes.dashboard.order}
                        // onClick={handleQuickCartViewToggle}
                        color="inherit"
                      >
                        <Badge badgeContent={cartData.length} color="secondary">
                          <ShoppingCartIcon />
                        </Badge>
                      </IconButton>
                    </>
                  )}

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon />
                  </IconButton>

                  {/* Reuable comp */}
                  <Drawer open={isDrawerOpen} close={handleDrawerClose} authToken={authToken} />
                </>
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem component={RouterLink} to={routes.user.profile} onClick={handleMenuClose}>
          Profile
        </MenuItem>
        <MenuItem component={RouterLink} to={routes.dashboard.cart} onClick={handleMenuClose}>
          Cart
        </MenuItem>
        <MenuItem component={RouterLink} to={routes.dashboard.orders} onClick={handleMenuClose}>
          Orders
        </MenuItem>
        <MenuItem onClick={handleLogout} component={RouterLink} to={routes.dashboard.home}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
