import React, { Fragment } from 'react';
// import PropTypes from "prop-types";
// import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';

import SearchIcon from '@material-ui/icons/Search';
// import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  hideOnDesktop: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const SearchBar = ({ toggleDrawer, areFiltersActive, searchTerm, setSearchTerm }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Paper component="form" className={classes.root}>
        <IconButton className={classes.iconButton} aria-label="menu">
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Search for Products"
          inputProps={{ 'aria-label': 'search products' }}
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
        {/* <Divider className={cx(classes.divider, classes.hideOnDesktop)} orientation="vertical" /> */}
        {/* 
        <IconButton
          color="primary"
          className={cx(classes.iconButton, classes.hideOnDesktop)}
          aria-label="directions"
          onClick={() => {
            toggleDrawer();
          }}
        >
          <Badge color="secondary" variant="dot" invisible={!areFiltersActive}>
            <FilterListIcon />
          </Badge>
        </IconButton> */}
      </Paper>
    </Fragment>
  );
};

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
