import React from 'react';
import ContactComp from '../../components/Contact';
// import GoogleMapReact from 'google-map-react';
// MUI
import {
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
  Link,
} from '@material-ui/core';
import LandingPageFooter from '../../components/LandingPageFooter';
import {
  LocationOnOutlined,
  EmailOutlined,
  PhoneOutlined,
  Facebook,
  Instagram,
} from '@material-ui/icons';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },

  container: {
    width: '100vw',
    marginTop: '100px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '60px',
    },
  },
  footerDiv: {},
  direction: {
    width: '100%',
    objectFit: 'cover',
    height: '60vh',
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
  },

  text: {
    fontSize: '14px',
    textAlign: 'justify',
    textTransform: 'initial',
    fontFamily: 'Glacial Indifference',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },

  info: {
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginBottom: '10px',
      marginTop: '0',
    },
  },

  gridItem: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  },
  mapContainer: {
    height: '60vh',
    margin: '90px 10px 10px 10px',
  },
  social: {
    color: '#111111',
    '&:hover': {
      color: '#111111 !important',
    },
  },
}));

function Contact() {
  const classes = useStyles();

  // Map
  // const location = {
  //   address: 'Myles High Bakehouse, Patto Centre, Panaji, Goa 403001',
  //   lat: 15.4966249,
  //   lng: 73.8332561,
  // };
  // const zoomLevel = 17;
  // helpers
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid container justify="center" alignItems="center" className={classes.root}>
        {/* google map directions */}
        {/* <Grid item md={12} className={classes.mapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
            defaultCenter={location}
            defaultZoom={zoomLevel}
          ></GoogleMapReact>
        </Grid> */}

        <Grid item className={classes.container}>
          <Grid
            item
            container
            spacing={!isSmDown ? 2 : 0}
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.container}
          >
            <Grid item xs={12} sm={7} md={8} lg={6} className={classes.contactForm}>
              <ContactComp />
            </Grid>

            {/* Contact info */}
            <Grid item xs={12} sm={5} md={4} lg={6}>
              <Grid
                item
                container
                spacing={2}
                direction="column"
                justify="center"
                className={classes.info}
              >
                {/* address */}
                <Grid item className="address">
                  <Grid
                    item
                    className={classes.gridItem}
                    container
                    direction="row"
                    justify="center"
                  >
                    <Grid item xs={2}>
                      <LocationOnOutlined />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.text}>
                        Myles High Bakehouse Patto Plaza, Patto, Panaji, Goa 403001
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* email */}
                <Grid item className="email">
                  <Grid
                    item
                    className={classes.gridItem}
                    container
                    direction="row"
                    justify="center"
                  >
                    <Grid item xs={2}>
                      <EmailOutlined />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.text}>bakehouse@myleshigh.in</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* contact */}
                <Grid item className="email">
                  <Grid
                    item
                    className={classes.gridItem}
                    container
                    direction="row"
                    justify="center"
                  >
                    <Grid item xs={2}>
                      <PhoneOutlined />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.text}>+91 832 – 2438117</Typography>
                      <Typography className={classes.text}>+91 832 – 2437978</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* social */}
                <Grid item className="email">
                  <Grid
                    item
                    className={classes.gridItem}
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12}>
                      <IconButton>
                        <Link
                          href="https://www.facebook.com/BakehouseGoa"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.social}
                        >
                          <Facebook />
                        </Link>
                      </IconButton>
                      <IconButton>
                        <Link
                          href="https://www.instagram.com/bakehousegoa/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.social}
                        >
                          <Instagram />
                        </Link>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.footerDiv}>
        <LandingPageFooter />
      </Grid>
    </>
  );
}

export default Contact;
