import firebase, { auth } from '../config/firebase';

export const logout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    throw error || new Error('Error logging out');
  }
};

export const loginUser = async (email, password, rememberMe = false) => {
  try {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.SESSION
      : firebase.auth.Auth.Persistence.LOCAL;
    await auth.setPersistence(persistence);
    const authUser = await auth.signInWithEmailAndPassword(email, password);

    return authUser;
  } catch (error) {
    let message = '';
    switch (error.code) {
      case 'auth/invalid-email':
        message = 'Invalid Email Id';
        break;
      case 'auth/user-disabled':
        message = 'User is temporarily disabled';
        break;
      case 'auth/too-many-requests':
        message = error.message;
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        message = 'Invalid username or password';
        break;
      default:
        message = 'Error logging in';
        break;
    }
    throw new Error(message);
  }
};

/**
 * function that returns the current signed in user
 * It will return a promise that will resolve to the current signed in user or null
 *
 * Internally it uses the onAuthStateChanged function but we do not want multiple instances of
 * onAuthStateChanged all over the page firing at the same time so unsubscribing from the current one
 * and only using it to retrieve the current user
 *
 */
export const fetchCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export const fetchToken = async (currentUser) => {
  const token = await currentUser.getIdToken(true);
  localStorage.setItem('token', token);
  return token;
};

export default firebase;
