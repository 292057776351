import { handleActions } from 'redux-actions';
import * as actionCreators from '../actionCreators/orders';
import { ORDER_DATE, ORDER_DATE_ASC, PRICE, PRICE_ASC } from '../../config/constants';

const defaultState = {
  isLoading: false,
  ordersData: [],
  noMoreData: false,
};

const ordersReducer = handleActions(
  {
    [actionCreators.doReadOrders]: (state) => ({ ...state, isLoading: true }),
    [actionCreators.doReadOrdersSuccess]: (state, { payload }) => {
      let newState = {
        isLoading: false,
      };
      if (state.ordersData.length) {
        const newArray = [...state.ordersData, ...payload];
        newState = {
          ordersData: newArray,
        };
      } else {
        newState = {
          ordersData: payload,
        };
      }

      if (payload && payload.length === 0) {
        newState.noMoreData = true;
      }

      return {
        ...state,
        ordersData: newState.ordersData,
        isLoading: newState.isLoading,
        noMoreData: newState.noMoreData,
      };
    },
    [actionCreators.doReadOrdersFailure]: (state) => ({ ...state, isLoading: false }),

    //Clear orders locally
    [actionCreators.doRefreshOrders]: (state) => {
      return {
        ...state,
        ordersData: [],
      };
    },

    [actionCreators.doSortOrders]: (state, { payload }) => {
      const orders = [...state.ordersData];

      if (payload === PRICE) orders.sort((a, b) => Number(b.price) - Number(a.price));
      else if (payload === PRICE_ASC) orders.sort((a, b) => Number(a.price) - Number(b.price));
      else if (payload === ORDER_DATE)
        orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
      else if (payload === ORDER_DATE_ASC)
        orders.sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
      else return { ...state };

      return {
        ...state,
        ordersData: orders,
      };
    },
    [actionCreators.doResendInvoice]: (state) => ({ ...state, isLoading: true }),
    [actionCreators.doResendInvoiceSuccess]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [actionCreators.doResendInvoiceFailure]: (state) => ({
      ...state,
      isLoading: false,
    }),

    [actionCreators.doSendSummary]: (state) => ({ ...state, isLoading: true }),
    [actionCreators.doSendSummarySuccess]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [actionCreators.doSendSummaryFailure]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  defaultState,
);

export default ordersReducer;
