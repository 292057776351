import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/orders';
import * as actionTypes from '../actionTypes/orders';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';

function* readOrders({ payload }) {
  try {
    const {
      data: { data },
    } = yield call(APIService.readOrders, payload.pagination, payload.skip);
    // Sort by latest order at top
    data.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
    yield put(actionCreators.doReadOrdersSuccess(data));
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't fetch orders" }));
    yield put(actionCreators.doReadOrdersFailure(error));
  }
}

function* resendInvoice({ payload }) {
  try {
    yield call(APIService.resendInvoice, payload);
    yield put(actionCreators.doResendInvoiceSuccess());
    yield put(
      misc.doManageNotification({
        isVisible: true,
        message: 'Resent Invoice Successfully',
      }),
    );
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't Resend Invoice" }));
    yield put(actionCreators.doResendInvoiceFailure(error));
  }
}

function* sendOrderSummary({ payload }) {
  try {
    yield call(APIService.sendOrderSummary, payload);
    yield put(actionCreators.doSendSummarySuccess());
    yield put(
      misc.doManageNotification({
        isVisible: true,
        message: 'Order Summary Sent!',
      }),
    );
  } catch (error) {
    yield put(misc.doManageNotification({ isVisible: true, message: error.message }));
    yield put(actionCreators.doSendSummaryFailure(error));
  }
}

const ordersSaga = [
  takeLatest(actionTypes.READ_ORDERS_REQUESTED, readOrders),
  takeLatest(actionTypes.RESEND_INVOICE_REQUESTED, resendInvoice),
  takeLatest(actionTypes.SEND_SUMMARY_REQUESTED, sendOrderSummary),
];

export default ordersSaga;
