import React from 'react';
import SimpleTable from '../../components/Checkout';

const Cart = ({ removeFromCartHandler, updateFromCartHandler, cartData }) => {
  return (
    <>
      <SimpleTable items={cartData} />
    </>
  );
};

export default Cart;
