export const READ_CART_REQUESTED = 'cart/READ_CART_REQUESTED';
export const READ_CART_SUCCEEDED = 'cart/READ_CART_SUCCEEDED';
export const READ_CART_FAILED = 'cart/READ_CART_FAILED';

export const ADD_CART_REQUESTED = 'cart/ADD_CART_REQUESTED';
export const ADD_CART_SUCCEEDED = 'cart/ADD_CART_SUCCEEDED';
export const ADD_CART_FAILED = 'cart/ADD_CART_FAILED';

export const REMOVE_CART_REQUESTED = 'cart/REMOVE_CART_REQUESTED';
export const REMOVE_CART_SUCCEEDED = 'cart/REMOVE_CART_SUCCEEDED';
export const REMOVE_CART_FAILED = 'cart/REMOVE_CART_FAILED';

export const UPDATE_CART_REQUESTED = 'cart/UPDATE_CART_REQUESTED';
export const UPDATE_CART_SUCCEEDED = 'cart/UPDATE_CART_SUCCEEDED';
export const UPDATE_CART_FAILED = 'cart/UPDATE_CART_FAILED';

export const UPDATE_MULTIPLE_CART_REQUESTED = 'cart/UPDATE_MULTIPLE_CART_REQUESTED';
export const UPDATE_MULTIPLE_CART_SUCCEEDED = 'cart/UPDATE_MULTIPLE_CART_SUCCEEDED';
export const UPDATE_MULTIPLE_CART_FAILED = 'cart/UPDATE_MULTIPLE_CART_FAILED';

export const CLEAR_CART = 'cart/CLEAR_CART';
export const SET_DELIVERY_TYPE = 'cart/SET_DELIVERY_TYPE';
export const SET_PAYMENT_TYPE = 'cart/SET_PAYMENT_TYPE';

export const ADD_MULTIPLE_TO_CART_REQUESTED = 'cart/ADD_MULTIPLE_TO_CART_REQUESTED';
export const ADD_MULTIPLE_TO_CART_SUCCEEDED = 'cart/ADD_MULTIPLE_TO_CART_SUCCEEDED';
export const ADD_MULTIPLE_TO_CART_FAILED = 'cart/ADD_MULTIPLE_TO_CART_FAILED';
