import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
// import { Input } from '@material-ui/core';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { useDispatch, useSelector } from 'react-redux';
import { doReadCartItems } from '../../redux/selectors/cart';
import { selectAuthToken } from '../../redux/selectors/authentication';
import { doAddCart, doUpdateCart } from '../../redux/actionCreators/cart';
import { doShowLoginModal } from '../../redux/actionCreators/ui';

import calculateDeliveryDate from '../../utils/calculateDeliveryDate';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: '20rem',
    backgroundRepeat: 'no-repeat',
    // backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: theme.shape.borderRadius,
  },
  modal: {
    padding: '0 1rem 2rem 1rem',
  },
  stepper: {
    padding: '0',
  },
  content: {
    padding: '0 2rem 0 2rem',
    height: '100%',
  },
  quantity: {
    color: theme.palette.secondary.dark,
  },
  quantityButton: {
    margin: '0 1rem',
  },
  price: {
    color: theme.palette.secondary.dark,
  },
  editable: {
    marginTop: '1rem',
  },
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ItemModal = ({
  isModalOpen,
  setModalOpen,
  selectedItem,
  addItemToCart,
  auth,
  disableAddToCart,
  setDisableAddToCart,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const cartData = useSelector(doReadCartItems);
  const authToken = useSelector(selectAuthToken);

  const [activeStep, setActiveStep] = useState(0);
  const [itemQuantity, setItemQuantity] = useState(1);
  const maxSteps = selectedItem ? selectedItem.images.length : 0;

  useEffect(() => {
    setItemQuantity(1);
  }, [selectedItem]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClose = () => {
    if (itemQuantity === 0);
    setItemQuantity(1);
    setModalOpen(false);
    setActiveStep(0);
  };

  const removeQuantity = () => {
    if (itemQuantity > 1) {
      setItemQuantity(itemQuantity - 1);
    }
  };

  const addQuantity = () => {
    setItemQuantity(itemQuantity + 1);
  };

  const totalPrice = () => {
    return selectedItem.price * itemQuantity;
  };

  const resetAddButton = () => {
    setDisableAddToCart(false);
  };

  function addItem() {
    if (disableAddToCart) {
      return;
    }

    setDisableAddToCart(true);

    if (authToken === null || authToken === undefined) {
      dispatch(doShowLoginModal());
    } else {
      let itemUpdateStatus = false;
      let midObject = {};
      if (cartData.length === 0) {
        midObject = {
          ...selectedItem,
          quantity: itemQuantity,
        };

        addItemToCart({
          ...selectedItem,
          quantity: itemQuantity,
          deliveryDate: calculateDeliveryDate(selectedItem, itemQuantity),
          resetAddButton,
        });
      } else {
        for (let i = 0; i < cartData.length; i++) {
          if (cartData[i].uuId === selectedItem.uuId) {
            itemUpdateStatus = true;
            midObject = {
              ...selectedItem,
              quantity: cartData[i].quantity + itemQuantity,
            };

            const arr = cartData.filter((prev) => prev.uuId !== selectedItem.uuId);
            arr.push(midObject);

            dispatch(
              doUpdateCart({
                ...selectedItem,
                quantity: cartData[i].quantity + itemQuantity,
                deliveryDate: calculateDeliveryDate(
                  selectedItem,
                  cartData[i].quantity + itemQuantity,
                ),
                resetAddButton,
              }),
            );
            break;
          }
        }

        if (itemUpdateStatus) {
          return;
        } else {
          midObject = {
            ...selectedItem,
            quantity: itemQuantity,
          };

          dispatch(
            doAddCart({
              ...selectedItem,
              quantity: itemQuantity,
              deliveryDate: calculateDeliveryDate(selectedItem, itemQuantity),
              resetAddButton,
            }),
          );
        }
      }
    }
    setModalOpen(false);
  }

  // function handleBackspace(e) {
  //   if(String(itemQuantity).length === 1)
  //   {
  //     if (e.keyCode === 8) {
  //       e.preventDefault();
  //       setItemQuantity(1);
  //     }
  //   }
  // }

  return (
    <>
      <Dialog
        open={isModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        {selectedItem ? (
          <>
            <DialogContent className={classes.modal}>
              <Grid container justify="space-between">
                <Typography variant="h6">{selectedItem.name}</Typography>
                <IconButton
                  color="secondary"
                  aria-label="close modal"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {selectedItem.images?.map((step, index) => (
                      <div key={step.uuId}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          <div
                            className={classes.img}
                            style={{ backgroundImage: `url(${step.key})` }}
                            // src={`${step.key}`}
                            // alt={`${selectedItem.name} - ${step.id}`}
                          />
                        ) : null}
                      </div>
                    ))}
                  </AutoPlaySwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    className={classes.stepper}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </Button>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    direction="column"
                    justify="space-between"
                    className={classes.content}
                  >
                    <Grid item>
                      <Typography variant="body1">{selectedItem.description}</Typography>
                      <Grid container justify="space-between" className={classes.editable}>
                        <Grid item container xs={12} md={8}>
                          <Grid item xs={3} container alignContent="flex-end" justify="center">
                            <Fab
                              size="small"
                              color="primary"
                              aria-label="remove"
                              disabled={itemQuantity === 1 || itemQuantity === 0}
                              onClick={() => {
                                removeQuantity();
                              }}
                            >
                              <RemoveIcon />
                            </Fab>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="standard-basic"
                              type="number"
                              inputProps={{
                                style: {
                                  textAlign: 'center',
                                },
                              }}
                              label=""
                              value={itemQuantity}
                              onChange={(e) => setItemQuantity(Number(e.target.value))}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {selectedItem.unit}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={3} container alignContent="flex-end" justify="center">
                            <Fab
                              size="small"
                              color="primary"
                              aria-label="add"
                              onClick={() => {
                                addQuantity();
                              }}
                            >
                              <AddIcon />
                            </Fab>
                          </Grid>

                          {/* <span style={{width: '50px'}}><Input value={String(itemQuantity)} onChange={e => setItemQuantity(Number(e.target.value))} error={itemQuantity === 0 ? true : false} onKeyDown={e => handleBackspace(e)}/></span>
                           */}

                          {/* <span style={{ width: '50px' }}>
                            <input
                              value={itemQuantity}
                              onChange={(e) => setItemQuantity(Number(e.target.value))}
                              style={{
                                width: '50px',
                                padding: '10px 0px 10px 5px',
                                borderBottom: '2px solid #f2bd82',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                              }}
                            />
                          </span> */}
                          {/* <Typography variant="h6" className={classes.quantity}>
                            {itemQuantity}
                          </Typography> */}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          container
                          justify="center"
                          alignContent="flex-end"
                        >
                          <Typography variant="h6" align="right" className={classes.price}>
                            {auth === null ? '' : `₹${totalPrice().toFixed(2)}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item className="py-8" />
                    </Grid>
                    <Grid item>
                      {disableAddToCart ? (
                        <Grid container className="w-full" justify="center">
                          <Grid item xs={2}>
                            <CircularProgress size={40} />
                          </Grid>
                        </Grid>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={addItem}
                          disabled={itemQuantity === 0 || disableAddToCart}
                        >
                          Add to cart
                        </Button>
                      )}

                      <Typography variant="caption" align="center">
                        You can select/change the delivery date in the cart
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <Typography variant="h6">No Item Selected</Typography>
        )}
      </Dialog>
    </>
  );
};

ItemModal.propTypes = {};

ItemModal.defaultProps = {};

export default ItemModal;
