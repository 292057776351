import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
  palette: {
    type: 'light',
    primary: {
      light: '#F8E9D9',
      main: '#F2BD82',
      dark: '#E28C2B',
      contrastText: '#62321E',
    },
    secondary: {
      light: '#B48972',
      main: '#B25D30',
      dark: '#62321E',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Playfair Display',
      'Glacial Indifference',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: ['Playfair Display'],
    },
    h2: {
      fontFamily: ['Playfair Display'],
    },
    h3: {
      fontFamily: ['Playfair Display'],
    },
    h4: {
      fontFamily: ['Playfair Display'],
    },
    h5: {
      fontFamily: ['Playfair Display'],
    },
    h6: {
      fontFamily: ['Playfair Display'],
    },
    subtitle1: {
      fontFamily: ['Glacial Indifference'],
    },
    subtitle2: {
      fontFamily: ['Glacial Indifference'],
    },
    body1: {
      fontFamily: ['Glacial Indifference'],
    },
    body2: {
      fontFamily: ['Glacial Indifference'],
    },
  },
};

const muiTheme = createMuiTheme(theme);

export default muiTheme;
