import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import TagManger from 'react-gtm-module';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as vars from './config/constants';

// Google tag manager

const tagMangerArgs = {
  gtmId: 'G-FN4EJSPGZR',
};

if (vars.isRelease) {
  TagManger.initialize(tagMangerArgs);
  LogRocket.init(vars.LOG_ROCKET_ID, {
    release: vars.ENVIRONMENT,
  });
}

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
