import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../redux/selectors/authentication';
import LogRocket from 'logrocket';

function LogRocketWrapper(props) {
  const userData = useSelector(selectUserData);
  const { children } = props;
  const user = {
    email: userData?.email,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    username: userData?.username,
    role: userData?.role,
    phone: userData?.phone,
    userId: userData?.id,
  };
  if (user && user.userId) {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID);
  }

  useEffect(() => {
    if (!user && !user.userId) {
      return;
    } else {
      try {
        LogRocket.identify(user?.userId, user);
      } catch (error) {
        throw new error(error);
      }
    }
  }, [user, userData]);

  return <>{children}</>;
}

export default LogRocketWrapper;
