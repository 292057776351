import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },

  title: {
    padding: '10px',
    fontSize: '10em',
    textAlign: 'center',
    fontWeight: '700',
    // fontFamily: 'Glacial Indifference',
    color: '#603813',
    textTransform: 'uppercase',
    lineHeight: 1,
    opacity: 0.9,
    [theme.breakpoints.down('md')]: {
      fontSize: '8em',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '6em',
    },
  },
  subContainer: {
    height: '100%',
  },
  logo: {
    width: '120px',
    height: '110px',
    borderRadius: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
    },
  },

  text: {
    fontSize: '30px',
    maxWidth: '400px',
    textAlign: 'center',
    fontFamily: 'Glacial Indifference',
    lineHeight: 1,
    padding: '15px 10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      maxWidth: '300px',
      lineHeight: '1.5',
    },
  },

  itemContainer: {
    padding: '10px',
    position: 'relative',
    margin: '20px 0',
  },

  bar: {
    background: '#62321E',
    height: '200px',
    position: 'absolute',
    width: '100vw',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',
    opacity: '0.8',
  },
  itemCont: {
    [theme.breakpoints.down('xs')]: {
      width: 160,
    },
  },
  itemInfo: {
    padding: '10px',
    zIndex: '100',
  },

  imgGridItem: {
    zIndex: '100',
  },
  itemImg: {
    objectFit: 'cover',
    width: '80%',
    display: 'block',
    margin: '0 auto',
    height: '300px',
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      height: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '500px',
    },
  },
  rightBoxShadow: {
    boxShadow: '20px -20px rgb(129, 178, 20,0.7)',
    [theme.breakpoints.down('xs')]: {
      boxShadow: '10px -10px rgb(129, 178, 20,0.7)',
    },
  },
  leftBoxShadow: {
    boxShadow: '-20px -20px rgb(129, 178, 20 ,0.7) ',
    [theme.breakpoints.down('xs')]: {
      boxShadow: '-10px -10px rgb(129, 178, 20,0.7)',
    },
  },
  itemName: {
    fontSize: '30px',
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Glacial Indifference',
    textTransform: 'uppercase',
    color: '#ffffff',

    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      letterSpacing: '2px',
    },
  },

  itemDesc: {
    fontSize: '15px',
    textAlign: 'center',
    letterSpacing: '2px',
    fontWeight: '700',
    fontFamily: 'Glacial Indifference',
    textTransform: 'uppercase',
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      fontWeight: 500,
    },
  },
  btnContainer: {
    width: '300px',
  },
  btn: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
      marginRight: '0',
    },
  },
  viewMenuBtn: {
    borderRadius: '50px',
    background: '#F2BD82',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#F2BD82',
    },
  },
  zomatoBtn: {
    background: '#E23744',
    borderRadius: '50px',
    color: '#ffffff',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#E23744',
    },
  },
  swiggyBtn: {
    background: '#f68934',
    borderRadius: '50px',
    color: '#ffffff',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#f68934',
    },
  },

  btnText: {
    fontSize: '15px',
    textAlign: 'center',
    fontFamily: 'Glacial Indifference',
    fontWeight: 'bold',
    textTransform: 'unset',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  menuCont: {
    width: '100vw',
    margin: '20px',
  },
  menuContBg: {
    backgroundColor: 'rgba(98,50,30,0.8)',
  },
  menuCardImg: {
    width: '100%',
    margin: '20px 0',
    borderRadius: '50px',
  },
  imgContainer: {
    width: '80vw',
  },
}));
