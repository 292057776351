import moment from 'moment';
import isFollowingDayDelivery from './isFollowingDayDelivery';

const calculateDeliveryDate = (item, quantity) => {
  // const startDate = startOfToday();
  const startDate = moment().startOf('day');
  let addDeliveryDays = 1;
  const isFollowingDay = isFollowingDayDelivery();

  // After 4 postMessage, default is 2 days
  if (isFollowingDay) {
    addDeliveryDays += 1;
  }
  const bulkItems = item?.bulkItemDuration?.filter((duration) => {
    return duration.status ? duration.status === 1 : true;
  });
  // To check in which range does the quantity lie and set the duration
  if (item) {
    for (let i = 0; i < bulkItems.length; i++) {
      if (quantity >= bulkItems[i].minQuantity && quantity <= bulkItems[i].maxQuantity) {
        addDeliveryDays += bulkItems[i].duration;
      } else if (
        i !== bulkItems.length - 1 &&
        quantity > bulkItems[i].maxQuantity &&
        quantity < bulkItems[i + 1].minQuantity
      ) {
        addDeliveryDays += bulkItems[i].duration;
      }
    }
  }
  return startDate.add(addDeliveryDays, 'days');
};

export default calculateDeliveryDate;
