import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Invoices = ({ invoices, handleInvoice }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Invoice Number</TableCell>
            <TableCell align="right">Number of Items</TableCell>
            <TableCell align="right">Delivery Date</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((row) => (
            <TableRow key={row.invoiceNo}>
              <TableCell component="th" scope="row">
                {row.invoiceNo}
              </TableCell>
              <TableCell align="right">{row.invoiceItems.length}</TableCell>
              {/* <TableCell align="right">{moment(row.deliveryDate).format('DD-MM-YYYY')}</TableCell> */}
              <TableCell align="right">{new Date(row.deliveryDate).toDateString()}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleInvoice(row.invoiceNo);
                  }}
                >
                  Send Invoice
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Invoices;
