import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actionCreators/cart';
import * as actionTypes from '../actionTypes/cart';
import * as misc from '../actionCreators/miscellaneous';
import * as APIService from '../../services/APIService';

const restructureItems = async (itemList, itemPriceList) =>
  itemList.map((item) => {
    const restructuredItem = { ...item, ...item.item };
    delete restructuredItem['item'];

    // The Cart API was returning the total price instead of the price of a single item, this was causing a two-fold increase in price as it would quantity * price, where price was already quantity * price

    restructuredItem.price /= restructuredItem.quantity;

    const foundItem = itemPriceList.find((pricelistItem) => pricelistItem.itemId === item.itemId);
    if (foundItem) restructuredItem.minOrderQty = foundItem.minOrderQty;
    else restructuredItem.minOrderQty = null;

    return restructuredItem;
  });

function* addToCart({ payload }) {
  try {
    yield call(
      APIService.addToCart,
      payload.itemId,
      payload.quantity,
      new Date(payload.deliveryDate).toISOString(),
    );
    yield put(misc.doManageNotification({ isVisible: true, message: 'Added To Cart' }));
    if (payload.resetAddButton) {
      payload.resetAddButton();
    }

    yield put(actionCreators.doAddCartSuccess(payload));
  } catch (error) {
    if (payload.resetAddButton) {
      payload.resetAddButton();
    }
    const message = error.response.data.message || "Couldn't add to cart";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doAddCartFailure());
  }
}

function* addMultipleToCart({ payload }) {
  try {
    const { data } = payload;
    for (let i = 0; i < data.length; i++) {
      yield call(
        APIService.addToCart,
        data[i].itemId,
        data[i].quantity,
        new Date(data[i].deliveryDate).toISOString(),
      );
      yield put(actionCreators.doAddCartSuccess(data[i]));
    }
    // yield put(actionCreators.doReadCart());
    // history.push('/cart');
    yield put(misc.doManageNotification({ isVisible: true, message: 'Added To Cart' }));
  } catch (error) {
    const message = error.response.data.message || "Couldn't add to cart";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doAddCartFailure());
  }
}

function* fetchCart() {
  try {
    const {
      data: { data },
    } = yield call(APIService.fetchCart);

    if (!data.cart) {
      yield put(actionCreators.doReadCartSuccess([]));
      return;
    }

    const {
      cart: { cartItems },
      priceList: { itemPriceList },
    } = data;

    const cartData = yield call(restructureItems, cartItems, itemPriceList);
    yield put(actionCreators.doReadCartSuccess(cartData));
  } catch (error) {
    // yield put(misc.doManageNotification({ isVisible: true, message: "Couldn't fetch your cart" }));
    yield put(actionCreators.doReadCartFailure());
  }
}

function* updateCart({ payload }) {
  try {
    const cartItem = yield call(
      APIService.updateCart,
      payload.itemId,
      payload.quantity,
      new Date(payload.deliveryDate).toISOString(),
    );
    yield put(misc.doManageNotification({ isVisible: true, message: 'Updated Cart' }));
    if (payload.resetAddButton) {
      payload.resetAddButton();
    }
    yield put(actionCreators.doUpdateCartSuccess(cartItem));
  } catch (error) {
    if (payload.resetAddButton) {
      payload.resetAddButton();
    }
    const message = error.response.data.message || "Couldn't update your cart";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doUpdateCartFailure());
  }
}

function* updateMultipleCart({ payload }) {
  try {
    const { data } = payload;
    for (let i = 0; i < data.length; i++) {
      yield call(
        APIService.updateCart,
        data[i].itemId,
        data[i].quantity,
        new Date(data[i].deliveryDate).toISOString(),
      );
      yield put(actionCreators.doUpdateMultipleCartSuccess(data[i]));
    }
    yield put(misc.doManageNotification({ isVisible: true, message: 'Updated Cart' }));
  } catch (error) {
    const message = error.response.data.message || "Couldn't update your cart";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doUpdateMultipleCartFailure());
  }
}

function* deleteFromCart({ payload }) {
  try {
    yield call(APIService.deleteFromCart, payload.itemId);
    yield put(misc.doManageNotification({ isVisible: true, message: 'Removed Cart' }));
    yield put(actionCreators.doRemoveCartSuccess(payload));
  } catch (error) {
    const message = error.response.data.message || "Couldn't remove cart";
    yield put(misc.doManageNotification({ isVisible: true, message }));
    yield put(actionCreators.doRemoveCartFailure());
  }
}

const cartSaga = [
  takeEvery(actionTypes.ADD_CART_REQUESTED, addToCart),
  takeLatest(actionTypes.READ_CART_REQUESTED, fetchCart),
  takeEvery(actionTypes.UPDATE_CART_REQUESTED, updateCart),
  takeEvery(actionTypes.UPDATE_MULTIPLE_CART_REQUESTED, updateMultipleCart),
  takeEvery(actionTypes.REMOVE_CART_REQUESTED, deleteFromCart),
  takeEvery(actionTypes.ADD_MULTIPLE_TO_CART_REQUESTED, addMultipleToCart),
];

export default cartSaga;
