import { include } from 'named-urls';

export default {
  public: include('/', {
    login: 'login',
    register: 'register',
    termsAndConditions: 'terms-and-conditions',
    privacyPolicy: 'privacy-policy',
    disclaimer: 'disclaimer',
    cancellationAndRefnds: 'cancellations-refunds',
  }),
  dashboard: include('/', {
    home: '',
    cart: 'cart',
    account: 'account',
    orders: 'orders',
    order: 'order',
    about: 'about',
    contact: 'contact',
    gallery: 'gallery',
    menu: 'menu',
  }),
  user: include('/user', {
    profile: '',
  }),
};
