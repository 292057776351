import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import history from '../history';

import authenticationReducer from './authenticationReducer';
import miscellaneousReducer from './miscellaneousReducer';
import categoriesReducer from './categoriesReducer';
import itemsReducer from './itemsReducer';
import cartReducer from './cartReducer';
import ui from './uiReducer';
import locations from './locationsReducer';
import orders from './ordersReducer';
import addresses from './addressesReducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['router', 'auth', 'miscellaneous'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['miscellaneous'],
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: persistReducer(authPersistConfig, authenticationReducer),
  miscellaneous: miscellaneousReducer,
  categories: categoriesReducer,
  items: itemsReducer,
  cart: cartReducer,
  ui,
  locations,
  orders,
  addresses,
});

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedRootReducer;
