import React from 'react';

// Mui
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(/images/about-2.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  overlay: {
    padding: '40px 0',
    background: 'rgba(255,255,255,0.7)',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  container: {
    maxWidth: '65vw',
    background: 'white',
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
      minHeight: '50vh',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50vw',
      minHeight: '80vh',
    },
  },

  gridItem: {
    margin: '25px',
    padding: '20px',
  },

  text: {
    color: '#7E563C',
    textAlign: 'justify',
    textIndent: '30px',
    textTransform: 'initial',
    fontStyle: 'italic',
    fontFamily: 'Glacial Indifference',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '17px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },

  title: {
    fontStyle: 'italic',
    fontWeight: '700',
    paddingTop: '20px',
    marginTop: '20px',
    fontFamily: 'Playfair Display',
    color: '#7E563C',
  },

  curve: {
    width: '200px',
    height: '20px',
    margin: '5px',
  },
}));

function AboutUs() {
  // Hooks
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          container
          item
          className={classes.overlay}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              direction="column"
              className={classes.container}
            >
              <Grid item>
                <Grid item container direction="column" justify="center" alignItems="center">
                  <Grid item>
                    <Typography variant="h3" className={classes.title}>
                      ABOUT US
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src="/images/golden-curve.png"
                      alt="curve line"
                      className={classes.curve}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.gridItem}>
                <Typography className={classes.text}>
                  Myles High Bakehouse is your very own Goan Brand established in 2007 as a Myles
                  High Venture. It was the first <strong>Live Gourmet Bakery</strong> to be
                  introduced in Goa, and ever since then, it has been known to provide the highest
                  quality products with sincere service to its clients and guests while also setting
                  new benchmarks in this sector.
                </Typography>
                <br />
                <Typography className={classes.text}>
                  We are very well recognized for our <strong>custom cakes</strong> which we cater
                  for birthdays, weddings and all other events in between. The wide variety of
                  Artisan Bread baked by us is also of top-notch quality. We offer our services from
                  homemakers to the best hoteliers. Myles High Bakehouse is a brand for everyone.
                </Typography>
                <br />
                <Typography className={classes.text}>
                  Our flagship <strong>Bistro & Cafe</strong> in Patto Panjim provides the freshest
                  quality salads and drool-worthy butter croissants, sandwiches, burgers etc. All of
                  which are baked in-house. We pride ourselves in providing mouthwatering dishes
                  from around the world and hence our customers refer to Myles High Bakehouse as a
                  <strong> "Great place to Meet and Eat"</strong>.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AboutUs;
