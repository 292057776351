import React from 'react';
// MUI
import {
  makeStyles,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
// MUI ICONS
import { Instagram, Facebook, Close } from '@material-ui/icons';
// Styles
const useStyles = makeStyles((theme) => ({
  imgCont: {
    maxHeight: '500px',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
    },
  },
  itemImg: {
    objectFit: 'contain',
    maxHeight: '100%',
    width: '100%',
  },
  rightCont: {
    height: '100%',
    textAlign: 'center',
    maxWidth: '350px',
    margin: '0 auto',
  },

  heading: {
    fontWeight: '700',
    fontSize: '3rem',
    fontFamily: 'Glacial Indifference',
  },
  subheading: {
    fontSize: '13px',
    fontFamily: 'Glacial Indifference',
    fontStyle: 'italic',
  },
  gridItem: {
    marginBottom: '30px',
  },
  text: {
    fontFamily: 'Playfair Display',
    fontSize: '1rem',
    fontStyle: 'italic',
  },
  brand: {
    background: 'black',
    color: '#ffffff',
    padding: '5px',
  },
  nameCont: {
    marginBottom: '5px',
  },
  socials: {
    width: '70px',
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '0',
    [theme.breakpoints.down('xs')]: {
      right: 0,
      padding: '10px 5px',
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0',
    },
  },
}));
function GalleryModal({ isModalOpen, setModalOpen, selectedItem }) {
  const classes = useStyles();
  // Handlers
  const handleClose = () => {
    setModalOpen(false);
  };

  // Breakpoints
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Dialog
        open={isModalOpen}
        maxWidth={`${isXsDown ? 'sm' : 'md'}`}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <Grid
            container
            direction={`${isXsDown ? 'column' : 'row'}`}
            alignItems={`${isXsDown ? 'center' : ''}`}
            className={classes.container}
          >
            <Grid item xs={12} sm={6} className={classes.imgCont}>
              <img className={classes.itemImg} src={selectedItem.itemImage} alt="" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                item
                container
                className={classes.rightCont}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item className={classes.gridItem}>
                  <Typography variant="h2" className={classes.heading}>
                    Like what you see?
                  </Typography>
                </Grid>
                <Grid item className={classes.gridItem}>
                  <Typography className={classes.subheading}>
                    Get in touch with us Via <b>WhatsApp</b> on <b>+91-8380038810 </b> or book an
                    appointment by calling us <b>+91-832-2437978</b>.
                  </Typography>
                </Grid>
                <Grid item style={{ width: '90%' }}>
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    className={classes.brand}
                  >
                    <Grid item className={classes.nameCont}>
                      <Typography className={classes.text}>@BakehouseGoa</Typography>
                    </Grid>
                    <Grid item className={classes.socials}>
                      <Grid item container direction="row" justify="space-between">
                        <Grid item>
                          <Facebook />
                        </Grid>
                        <Grid item>
                          <Instagram />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>{' '}
            <IconButton className={classes.close} onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default GalleryModal;
