import axios from 'axios';
import { API_BASE_URL as baseURL, VERSION } from '../../config/constants';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import * as firebaseHelper from '../../utils/firebase';

const headers = {
  'Content-Type': 'application/json',
  'X-Deploy-Version': VERSION,
};

const refreshAuthToken = async (failedRequest) => {
  try {
    const user = await firebaseHelper.fetchCurrentUser();
    if (!user) {
      throw Error('User not found!');
    }

    const token = await firebaseHelper.fetchToken(user);
    if (token) {
      failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
    }
    return Promise.resolve();
  } catch (error) {
    throw error;
  }
};

const axiosInstance = axios.create({
  baseURL,
  headers,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

createAuthRefreshInterceptor(axiosInstance, refreshAuthToken, {
  statusCodes: [401, 403], // default: [ 401 ]
});

// Auth

export const sendOTP = (phoneNumber) =>
  axiosInstance({ method: 'GET', url: `/auth/send-otp/${phoneNumber}` });

export const verifyOTP = (sessionId, otp) =>
  axiosInstance({ method: 'GET', url: `auth/verify-otp/${sessionId}/${otp}` });

export const registerUser = (data) => {
  // console.log('Data', data)
  const url = `/auth/register`;
  return axiosInstance({
    method: 'POST',
    url,
    data,
  });
};

export const authenticateUser = (token) =>
  axiosInstance({
    method: 'POST',
    url: 'auth/login',
    data: {
      idToken: token,
      userType: 'customer',
    },
  });

export const fetchUserData = () => {
  const url = `/auth/me`;
  return axiosInstance({
    method: 'GET',
    url,
  });
};

// Items

export const readItems = (currentURL) => {
  const url = currentURL ? currentURL : `/publicmenu`;
  return axiosInstance({
    method: 'GET',
    url,
  });
};

// CART

export const addToCart = (itemId, quantity, deliveryDate) => {
  const url = `/cart`;
  return axiosInstance({
    method: 'POST',
    data: {
      itemId,
      quantity,
      deliveryDate,
    },
    url,
  });
};

export const fetchCart = () => {
  const url = `/cart`;
  return axiosInstance({
    method: 'GET',
    url,
  });
};

export const updateCart = (itemId, quantity, deliveryDate) => {
  const url = `/cart/${itemId}`;
  return axiosInstance({
    method: 'PUT',
    data: { quantity, deliveryDate },
    url,
  });
};

export const deleteFromCart = (itemId) => {
  const url = `/cart/${itemId}`;
  return axiosInstance({
    method: 'DELETE',
    url,
  });
};

export const confirmCart = (totalPrice, totalTax, paymentMethod) => {
  const url = `/cart/confirm`;
  return axiosInstance({
    method: 'POST',
    url,
    data: {
      totalPrice,
      totalTax,
      paymentMethod,
    },
  });
};

// Product report

export const productrequest = (email, subject, body) => {
  const url = `/enquiries`;
  return axiosInstance({
    method: 'POST',
    url,
    data: {
      email,
      subject,
      body,
    },
  });
};

// PAYMENT

export const capturePayment = (data) => {
  const url = `/payment/capture`;
  return axiosInstance({
    method: 'POST',
    url,
    data,
  });
};

export const captureCod = (data) => {
  const url = `/payment/cod`;
  return axiosInstance({
    method: 'POST',
    url,
    data,
  });
};

export const captureCredit = (data) =>
  axiosInstance({ method: 'POST', url: `payment/credit`, data });

// Categories

export const readCategories = () => {
  const url = `/publiccategory`;
  return axiosInstance({
    method: 'GET',
    url,
  });
};

export const updateUserProfile = (customerId, data) => {
  const url = `/customer/${customerId}`;
  return axiosInstance({
    method: 'PUT',
    url,
    data,
  });
};

export const changePassword = (customerId, data) => {
  const url = `/customer/${customerId}/change_password`;
  return axiosInstance({
    method: 'PUT',
    url,
    data,
  });
};

// locations

export const readLocations = () => {
  const url = `/publiclocation`;
  return axiosInstance({
    method: 'GET',
    url,
  });
};

//Patto location for pickup
export const readPickupLocation = () => {
  const url = `/publiclocation/pickup`;
  return axiosInstance({
    method: 'GET',
    url,
  });
};

// orders

export const readOrders = (pagination, skip) => {
  let url = `/orders`;
  if (pagination) {
    url = `/orders?pagination=${true}&skip=${Number(skip)}`;
  }
  return axiosInstance({
    method: 'GET',
    url,
  });
};

// Addresses

export const addAddress = (data) => axiosInstance({ method: 'POST', url: `/addresses`, data });

export const readAddresses = (customerId) =>
  axiosInstance({ method: 'GET', url: `/addresses/${customerId}` });

export const updateAddress = (customerId, addressId, data) =>
  axiosInstance({ method: 'PUT', url: `addresses/${customerId}/${addressId}`, data });

export const removeAddress = (customerId, addressId) =>
  axiosInstance({ method: 'PUT', url: `addresses/archive/${customerId}/${addressId}` });

//Invoice
export const resendInvoice = (payload) => {
  const url = `/orders/invoice`;
  return axiosInstance({
    method: 'POST',
    url,
    data: payload,
  });
};

//Order summary
export const sendOrderSummary = (id) => {
  const url = `/orders/${id}/overview`;
  return axiosInstance({
    method: 'POST',
    url,
  });
};
