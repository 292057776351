import React from 'react';
// MENU CATEGORY
import { menuCategory } from './menuCategory';
// MUI
import { Menu, makeStyles, Grid, MenuItem, Typography } from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { doSetSelCategory } from '../../redux/actionCreators/categories';
import routes from '../../config/routes';
import { doReadSelCategory } from '../../redux/selectors/categories';

// Styles
const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: '100%',
    maxWidth: '100%',
    height: '70%',
    maxHeight: 'unset',
    left: '0 !important',
    top: '95px !important',
  },

  menuContainer: {
    '& ul': {
      height: '100%',
    },
  },
  menuItems: {
    '& li': {
      height: '100%',
    },
  },
  container: {
    height: '100%',
    padding: '40px 25px',
  },
  itemContainer: {
    width: '225px',
    margin: '0 auto',
  },
  item: {
    width: '100%',
  },
  itemImg: {
    width: '100%',
    objectFit: 'cover',
    height: '130px',
  },

  viewAll: {
    width: '80px',
    padding: '20px 0',
    background: '#603813',
    borderRadius: '50%',
  },
  text: {
    fontSize: '1rem',
    fontFamily: 'Glacial Indifference',
  },
}));
function MenuDropdown({ anchorEl, close }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // handlers
  const setCat = (type) => {
    close();
    if (doReadSelCategory) {
      dispatch(doSetSelCategory(null));
    }
    dispatch(doSetSelCategory([type]));
    history.push(routes.dashboard.order);
  };

  return (
    <>
      <Menu
        PopoverClasses={{ paper: classes.popoverPaper }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top' }}
        open={Boolean(anchorEl)}
        onClose={close}
        className={classes.menuContainer}
      >
        <Grid container direction="row" className={classes.container}>
          {menuCategory.map(({ id, image, name, category }) => (
            <Grid item md={3} key={id} onClick={() => setCat(category)}>
              <MenuItem>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.itemContainer}
                >
                  <Grid item md={12} className={classes.item}>
                    <img className={classes.itemImg} src={image} alt="" />
                  </Grid>
                  <Grid item md={12}>
                    <Typography className={classes.text}>{name}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            </Grid>
          ))}
          <Grid item md={3} onClick={() => setCat('')}>
            <MenuItem className={classes.menuItems}>
              <Grid
                item
                container
                style={{ padding: '10px' }}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item style={{ margin: '10px' }}>
                  <Grid item container justify="center" className={classes.viewAll}>
                    <Grid>
                      <ArrowRightAlt color="primary" fontSize="large" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>View All</Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
}

export default MenuDropdown;
