import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  li: {
    color: 'black',
    marginBottom: '0.5rem',
    fontSize: '1rem',
  },
  container: {
    padding: '4rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0rem',
    },
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

const CancellationsAndRefunds = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid
        container
        style={{ width: '100%', margin: 0 }}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
        className={classes.container}
      >
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            No Cancellation Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>Orders once placed cannot be cancelled</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Return Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              All complaints regarding shortage/ defect of any kind with regards to the products
              should be lodged with the company at the time of receipt itself and no complaints of
              any nature shall be accepted thereafter.
            </li>
            <li className={classes.li}>
              The disputed/ defected items shall be returned at the time of receiving the stock at
              the delivery location.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Refund Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              After we receive back the product, our team of professionals will inspect it and
              process your refund. The money will be refunded to your bank account within 7 working
              days, based on the bank account details emailed to us
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Mishandled deliveries{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              We will cater to any reports of damaged products/ cakes only at the time of delivery
              i.e at the collection point of delivery. The problem report must be supported with
              substantial proofs of the damage made to the product.
            </li>
            <li className={classes.li}>
              If any objection, claim has not been received in writing at the time of receipt or
              acceptance of the item, no claims/ objections will be entertained thereafter.
            </li>
          </ul>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CancellationsAndRefunds;
