import React from 'react';
import { Route } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import NotificationContainer from '../../containers/NotificationContainer';
import LandingPageFooter from '../../components/LandingPageFooter';
import Header from '../../components/Header';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.light,
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      padding: '0.1rem 0.5rem',
      marginTop: '7rem',
    },
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      padding: '0.1rem 2rem',
      marginTop: '7rem',
    },
  },
}));

const CustomerDashboardLayout = ({ component: Component, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Route
      render={(props) => (
        <div className={classes.container}>
          <Header />
          <div className={classes.content}>
            <Component {...props} />
          </div>
          <NotificationContainer />
          <br />
          <LandingPageFooter />
        </div>
      )}
      {...rest}
    />
  );
};

export default CustomerDashboardLayout;
