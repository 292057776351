const calculateMaxOrderable = (item) => {
  const bulkItems = item?.bulkItemDuration?.filter((duration) => {
    return duration.status ? duration.status === 1 : true;
  });
  let maxOrderable = undefined;
  if (bulkItems.length === 0) {
    maxOrderable = item.maxOrderable;
  } else {
    maxOrderable = Math.max.apply(
      Math,
      bulkItems.map(function (range) {
        return range.maxQuantity;
      }),
    );
  }

  return maxOrderable;
};

export default calculateMaxOrderable;
