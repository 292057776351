import { handleActions } from 'redux-actions';
import faker from 'faker';
import * as actionCreators from '../actionCreators/items';

const defaultState = {
  itemsData: [
    {
      uuId: faker.random.uuid(),
      categoryId: '374db82c-93c8-4369-82b4-d1205dd53323',
      name: 'Ciabatta',
      description:
        'Ciabatta (which translates to slipper!) is an Italian bread made with wheat flour, salt, yeast, and water. Ciabatta is best for sandwiches and paninis, naturally.',
      veg: true,
      price: 75,
      maxOrderable: 100,
      duration: 2,
      bulkItemDurations: [
        {
          duration: 3,
          minQuantity: 5,
          maxQuantity: 10,
        },
        {
          duration: 7,
          minQuantity: 15,
          maxQuantity: 100,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?Ciabatta',
        'https://source.unsplash.com/720x460?baking',
        'https://source.unsplash.com/720x460?bread',
      ],
    },
    {
      uuId: faker.random.uuid(),
      categoryId: '374db82c-93c8-4369-82b4-d1205dd53323',
      name: 'Whole Wheat Bread',
      description:
        'Unlike white bread, whole-wheat bread is made from flour that uses almost the entire wheat grain—with the bran and germ in tact. This means more nutrients and fiber per slice! This bread is also great for sandwiches or, my personal favorite, egg in a hole.',
      veg: true,
      price: 25,
      maxOrderable: 50,
      duration: 1,
      bulkItemDurations: [
        {
          duration: 2,
          minQuantity: 5,
          maxQuantity: 50,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?baking',
        'https://source.unsplash.com/720x460?bread',
        'https://source.unsplash.com/720x460?oven',
      ],
    },
    {
      uuId: faker.random.uuid(),
      categoryId: '374db82c-93c8-4369-82b4-d1205dd53323',
      name: 'Sourdough',
      description:
        'Sourdough is a yeasted bread made from a starter — a fermented mixture of flour and water that makes many batches of bread. In fact, it can be kept for a VERY long time! The resulting loaf has a substantial crust with a soft, chewy center and large air bubbles. It makes a BOMB grilled cheese!',
      veg: false,
      price: 100,
      duration: 2,
      maxOrderable: 50,
      bulkItemDurations: [
        {
          duration: 4,
          minQuantity: 10,
          maxQuantity: 50,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?sourdough',
        'https://source.unsplash.com/720x460?baker',
        'https://source.unsplash.com/720x460?bakery',
      ],
    },

    {
      uuId: faker.random.uuid(),
      categoryId: 'df3f6ce5-414b-4257-98f1-edd1054785bc',
      name: 'Vanilla Cake',
      description:
        'Simple delicious vanilla cake with a buttercream frosting. Classics cant go wrong',
      veg: true,
      price: 350,
      duration: 2,
      maxOrderable: 5,
      bulkItemDurations: [
        {
          duration: 4,
          minQuantity: 3,
          maxQuantity: 5,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?vanilla',
        'https://source.unsplash.com/720x460?cake',
        'https://source.unsplash.com/720x460?bakery',
      ],
    },
    {
      uuId: faker.random.uuid(),
      categoryId: 'df3f6ce5-414b-4257-98f1-edd1054785bc',
      name: 'Tres Leches Cake',
      description:
        'The most important part of my recipe is that the structure of the sponge cake is able to hold up while absorbing the three kinds of milk. This means the sponge needs to be full of air bubbles! These air bubbles will then be filled with liquid without becoming weighed down or soggy.',
      veg: true,
      price: 200,
      duration: 3,
      maxOrderable: 10,
      bulkItemDurations: [
        {
          duration: 5,
          minQuantity: 3,
          maxQuantity: 5,
        },
        {
          duration: 7,
          minQuantity: 6,
          maxQuantity: 8,
        },
        {
          duration: 10,
          minQuantity: 9,
          maxQuantity: 12,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?tres+leches',
        'https://source.unsplash.com/720x460?milk',
        'https://source.unsplash.com/720x460?cream',
      ],
    },

    {
      uuId: faker.random.uuid(),
      categoryId: '19115b1f-0309-4564-8c28-a4de532c0764',
      name: 'Chicken Samosa',
      description:
        'The most favourite tea time snack across the country, Samosas are Indian snacks with delicious pockets of dough stuffed with a chicken filling',
      veg: false,
      price: 30,
      duration: 1,
      maxOrderable: 50,
      bulkItemDurations: [
        {
          duration: 2,
          minQuantity: 10,
          maxQuantity: 50,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?samosa',
        'https://source.unsplash.com/720x460?samosas',
        'https://source.unsplash.com/720x460?bakery',
      ],
    },
    {
      uuId: faker.random.uuid(),
      categoryId: '19115b1f-0309-4564-8c28-a4de532c0764',
      name: 'Mushroom Puffs',
      description:
        'Puff pastry triangles with a savory wild mushroom and cheese filling make an elegant and easy appetizer. If you love mushrooms, this is your recipe.',
      veg: true,
      price: 50,
      duration: 1,
      maxOrderable: 100,
      bulkItemDurations: [
        {
          duration: 2,
          minQuantity: 10,
          maxQuantity: 50,
        },
      ],
      images: [
        'https://source.unsplash.com/720x460?puffs',
        'https://source.unsplash.com/720x460?mushroom',
        'https://source.unsplash.com/720x460?bakery',
      ],
    },
  ],
};

const itemsReducer = handleActions(
  {
    [actionCreators.doReadItems]: (state) => {
      return state;
    },
    [actionCreators.doReadItemsSuccess]: (state, { payload }) => {
      return {
        ...state,
        itemsData: payload,
      };
    },
    [actionCreators.doReadItemsFailure]: (state) => {
      return state;
    },
  },
  defaultState,
);

export default itemsReducer;
