import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '15px 0',
  },
  typographyStyle: {
    color: '#62321E',
    fontSize: '30px',
    fontWeight: '500',
  },
  text: {
    fontSize: '14px',
    textAlign: 'justify',
    textTransform: 'initial',
    fontFamily: 'Glacial Indifference',
  },
  textArea: {
    // height: '150px',
    background: '#ffffff',
    // [theme.breakpoints.up('lg')]: {
    //   height: '200px',
    // },
  },
  gridItem: {
    width: '40vw',
    [theme.breakpoints.down('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      padding: '10px 20px',
    },
  },
  inputLabel: {
    color: 'black',
  },

  inputField: {
    background: '#ffffff',
  },

  button: {
    border: '1px solid black',
  },
  btnGrid: {
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginRight: '20px',
    },
  },
}));
