import React, { useState } from 'react';

// MUI
import { Card, CardMedia } from '@material-ui/core';
// Styles
import { useStyles } from './styles';
import GalleryModal from '../GalleryModal';

function GalleryComp({ image, id }) {
  const [open, setOpen] = useState(false);
  // hooks
  const classes = useStyles();

  // handlers
  const handleImagePopup = () => {
    setOpen(true);
  };

  return (
    <>
      <Card className={classes.root} onClick={handleImagePopup}>
        <CardMedia>
          <img className={classes.media} src={image} alt="" />
        </CardMedia>
      </Card>
      <GalleryModal isModalOpen={open} selectedItem={{ itemImage: image }} setModalOpen={setOpen} />
    </>
  );
}

export default GalleryComp;
