import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
  },
  cartPopupAppBar: {
    top: 'auto',
    bottom: 0,
  },
}));

const QuickCartItemCard = ({ data, removeFromCartHandler, cartData }) => {
  const classes = useStyles();

  const removeItemFromCart = (item) => {
    removeFromCartHandler(item);
  };

  const cartTotal = () => {
    let initialValue = 0;
    cartData.map((item) => (initialValue += item.price * item.quantity));
    return initialValue;
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={
          data.images && data.images.length > 0 && data.images[0] ? `${data.images[0].key}` : ''
        }
        title={data.name}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="body1">
            {`${data.name} X ${data.quantity}`}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`₹${data.price}`}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="small"
            onClick={() => {
              removeItemFromCart(data);
            }}
          >
            Remove
          </Button>
        </CardActions>
      </div>
      <AppBar position="fixed" color="primary" className={classes.cartPopupAppBar}>
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6">Total:{` ₹${cartTotal()}`}</Typography>
            </Grid>
            <Grid item>
              <Button size="medium" variant="outlined" color="secondary" href="/cart">
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Card>
  );
};

QuickCartItemCard.propTypes = {};

QuickCartItemCard.defaultProps = {};

export default QuickCartItemCard;
