import React, { useState } from 'react';
// Data
import { Items } from './demoData';
// MUI
import { Grid, Typography, Button, Link } from '@material-ui/core';
import { useStyles } from './styles';

function MenuItems() {
  // Hooks
  const classes = useStyles();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  // handlers
  const viewMenuHandler = (key) => {
    if (selected === key + 1) {
      setSelected(null);
      setMenuOpen(false);
    } else {
      setSelected(key + 1);
      setMenuOpen(true);
    }
  };
  return (
    <Grid container spacing={3} direction="column">
      {/* sub-header */}
      <Grid item xs={12}>
        <Grid
          container
          item
          direction="row"
          className={classes.titleContainer}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={5} md={6}>
            <Typography variant="h5" className={classes.title}>
              Cafe Menu
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <Grid
              container
              item
              direction="column"
              className={classes.subContainer}
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classes.text}>
                  Order Your Favourites from Our Menu on
                </Typography>
              </Grid>
              {/* BUTTONS */}
              <Grid item>
                <Grid item container direction="row" justify="space-evenly">
                  {/* Button 1 */}
                  <Link
                    href="https://www.swiggy.com/restaurants/myles-high-bakehouse-panaji-central-goa-225301"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Grid item className="swiggy-btn" style={{ marginRight: '10px' }}>
                      <img
                        className={classes.logo}
                        src="/images/swiggy-logo.png"
                        alt="Swiggy logo"
                      />
                    </Grid>
                  </Link>
                  {/* BUTTON-2 */}
                  <Link
                    href="https://www.zomato.com/goa/myles-high-bakehouse-panaji/order"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Grid item className="zomato-btn">
                      <img
                        className={classes.logo}
                        src="/images/zomato-logo.png"
                        alt="Zomato logo"
                      />
                    </Grid>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {Items.map(({ name, description, image, menuItem }, idx) => (
        <Grid item xs={12} style={{ width: '100vw', maxWidth: '100vw' }} key={idx}>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            style={{ width: '100vw', position: 'relative' }}
          >
            {selected === idx + 1 ? '' : <Grid xs={12} className={classes.bar}></Grid>}

            <Grid item style={{ zIndex: 100 }}>
              <Grid
                container
                direction={idx % 2 === 0 ? 'row' : 'row-reverse'}
                alignItems="center"
                item
                className={`${isMenuOpen && selected === idx + 1 ? classes.menuContBg : ''} ${
                  classes.menuCont
                }`}
              >
                <Grid item xs={6}>
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={12} className={classes.itemCont}>
                      <Typography className={classes.itemName}>{name}</Typography>
                      <Typography className={classes.itemDesc}>{description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item>
                        <Button
                          onClick={() => viewMenuHandler(idx)}
                          className={`${classes.viewMenuBtn} ${classes.btnText}`}
                        >
                          View Menu
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: '10px' }}>
                      <Grid item container direction="row" justify="center" alignItems="center">
                        <Grid item className={classes.btn}>
                          <Link
                            href="https://www.zomato.com/goa/myles-high-bakehouse-panaji/order"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button className={`${classes.zomatoBtn} ${classes.btnText}`}>
                              Order on Zomato
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={classes.btn}>
                          <Link
                            href="https://www.swiggy.com/restaurants/myles-high-bakehouse-panaji-central-goa-225301"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button className={`${classes.swiggyBtn} ${classes.btnText}`}>
                              Order on Swiggy
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid item>
                    <img
                      className={`${classes.itemImg} ${
                        idx % 2 === 0 ? classes.rightBoxShadow : classes.leftBoxShadow
                      }`}
                      src={image}
                      alt=""
                    />
                  </Grid>
                </Grid>

                {isMenuOpen && selected === idx + 1 && (
                  <Grid item xs={12}>
                    <Grid item justify="center" container alignItems="center">
                      {menuItem.map(({ menuImg }, imgIdx) => (
                        <Grid item key={imgIdx} className={classes.imgContainer}>
                          <img src={menuImg} alt="" className={classes.menuCardImg} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}

      {/* Accordian */}
    </Grid>
  );
}

export default MenuItems;
