import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import routes from '../../config/routes';
import style from '../../pages/Register/style.module.scss';
import Select from 'react-select';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _ from 'lodash';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { selectRegisterModal } from '../../redux/selectors/ui';
import { selectAllLocations } from '../../redux/selectors/locations';
import { selectOtp } from '../../redux/selectors/authentication';
import { doHideRegisterModal, doShowLoginModal } from '../../redux/actionCreators/ui';
import { doDisableOtpField, doRegister } from '../../redux/actionCreators/authentication';
import { doManageNotification } from '../../redux/actionCreators/miscellaneous';

import { sendOTP, verifyOTP } from '../../services/APIService';
import registerSchema from './config';

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

export default function RegisterModal() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectRegisterModal);
  const locations = useSelector(selectAllLocations);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [OTP, setOTP] = useState({ sessionId: '', code: '' });

  const [isOpenOTP, setIsOpenOTP] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [locationId, setLocationId] = useState('');

  const otp = useSelector(selectOtp);

  const handleClose = () => {
    dispatch(doHideRegisterModal());
  };

  const handleCloseOTP = () => {
    setIsOpenOTP(false);
  };

  const handleOpenLogin = () => {
    handleClose();
    dispatch(doShowLoginModal());
  };

  const handleSubmit = (values, { setSubmitting }) => {
    values.email = values.email.toLowerCase();
    setEmail(values.email);
    setPassword(values.password);
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setPhone(values.phone);
    setDob(values.dob);
    setLocationId(values.locationId);
    setSubmitting(false);
    handleClose();
    handleSendOTP(values.phone);
    setIsOpenOTP(true);
  };

  const handleSubmitFinal = (valuesSent, { setSubmitting }) => {
    const values = {};
    values.email = email;
    values.password = password;
    values.firstName = firstName;
    values.lastName = lastName;
    values.phone = phone;
    values.dob = dob;
    values.locationId = locationId;
    setSubmitting(false);
    handleVerifyOTP(values);
  };

  const handleSendOTP = async (phoneNumber) => {
    try {
      const {
        data: {
          data: { Details },
          message,
        },
      } = await sendOTP(phoneNumber);
      setOTP((prevState) => ({ ...prevState, sessionId: Details }));
      dispatch(doManageNotification({ isVisible: true, message }));
    } catch (error) {
      dispatch(doManageNotification({ isVisible: true, message: "Couldn't send OTP" }));
    }
  };

  const handleVerifyOTP = async (values) => {
    try {
      const {
        data: { message },
      } = await verifyOTP(OTP.sessionId, OTP.code);
      // setOTP((prevState) => ({ ...prevState, verified: true }));

      dispatch(doDisableOtpField());
      dispatch(doRegister(values));
      dispatch(doManageNotification({ isVisible: true, message }));
      handleCloseOTP();
    } catch (error) {
      dispatch(doManageNotification({ isVisible: true, message: "Couldn't verify OTP" }));
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '',
            dob: '2000-01-01',
            locationId: '',
          }}
          validationSchema={registerSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form className={style.container__form}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Create an Account
                </Typography>
                <Typography variant="caption" gutterBottom align="center">
                  Already have an account?{' '}
                  <RouterLink className={style['inline-link']} onClick={handleOpenLogin}>
                    Sign In
                  </RouterLink>
                </Typography>
                <Field
                  component={TextField}
                  name="firstName"
                  type="text"
                  label="First Name"
                  fullWidth
                  variant="filled"
                />
                <br />
                <Field
                  component={TextField}
                  name="lastName"
                  type="text"
                  label="Last Name"
                  fullWidth
                  variant="filled"
                />
                <Typography variant="caption" gutterBottom align="center">
                  First and Last Name must match payment method
                </Typography>
                <br />
                <Field
                  component={TextField}
                  type="text"
                  label="Contact Number"
                  name="phone"
                  fullWidth
                  variant="filled"
                  required
                />
                <Typography variant="caption" gutterBottom align="center">
                  Enter your number without adding +91 or 0
                </Typography>
                <Field
                  component={TextField}
                  type="date"
                  label="Date of Birth"
                  name="dob"
                  fullWidth
                  variant="filled"
                />
                <br />
                <Box width="100%">
                  <InputLabel htmlFor="select-locations">Location</InputLabel>
                  <Select
                    id="select-locations"
                    options={_.sortBy(locations, [(location) => location.name])}
                    placeholder="Select your location"
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.id}
                    onChange={(item) => setFieldValue('locationId', item.id)}
                    styles={selectStyles} // Fix for transparent options
                  />
                  <ErrorMessage name="locationId" />
                </Box>
                <br />
                <Field
                  component={TextField}
                  name="email"
                  type="text"
                  label="Email"
                  fullWidth
                  variant="filled"
                />
                <br />
                <Field
                  component={TextField}
                  type="password"
                  label="Password"
                  name="password"
                  fullWidth
                  variant="filled"
                />
                <br />
                <label htmlFor="checkbox-accept-tnc">
                  <Checkbox
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                    color="primary"
                    id="checkbox-accept-tnc"
                    inputProps={{ 'aria-label': 'Agree to Terms and Conditions' }}
                  />
                  <Typography variant="caption" gutterBottom align="center">
                    Agree to{' '}
                    <RouterLink
                      to={routes.public.termsAndConditions}
                      className={style['inline-link']}
                    >
                      Terms and Conditions
                    </RouterLink>
                  </Typography>
                </label>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitForm}
                  disabled={!acceptedTerms}
                  fullWidth
                >
                  Proceed To Phone Verification
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Dialog open={isOpenOTP} onClose={handleCloseOTP} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            phone: phone,
            otpCode: '',
          }}
          onSubmit={handleSubmitFinal}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form className={style.container__form}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Verify Phone Number
                </Typography>
                Enter the OTP received on the registered phone number below and click Verify and
                Complete.
                <Field
                  component={TextField}
                  type="text"
                  label="Contact Number"
                  name="phone"
                  value={phone}
                  fullWidth
                  variant="filled"
                  required
                  disabled={true}
                  style={{ marginTop: '24px' }}
                />
                <br />
                <MuiTextField
                  placeholder="Enter OTP"
                  variant="filled"
                  fullWidth
                  value={OTP.code}
                  onChange={(e) => {
                    e.persist();
                    setOTP((prevState) => ({ ...prevState, code: e.target.value }));
                  }}
                  InputProps={{
                    endAdornment: otp.isDisable ? (
                      <InputAdornment>
                        <CheckCircleIcon />
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Button color="primary" onClick={() => handleSendOTP(phone)}>
                    Resend OTP
                  </Button>
                  {/* <Button color="secondary" onClick={handleVerifyOTP}>
                    Verify
                  </Button> */}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitForm}
                  fullWidth
                  style={{ marginTop: '24px' }}
                >
                  Verify and Complete Registration
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
