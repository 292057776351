import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import NotificationSnackbar from "../../components/NotificationSnackbar";

import { doManageNotification } from "../../redux/actionCreators/miscellaneous";
import {
  selectIsVisible,
  selectNotificationMessage,
} from "../../redux/selectors/miscallaneous";

const NotificationContainer = ({ isVisible, message, createNotification }) => {
  return <NotificationSnackbar isVisible={isVisible} message={message} createNotification={createNotification}/>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNotification: (payload) => dispatch(doManageNotification(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isVisible: selectIsVisible(state),
    message: selectNotificationMessage(state),
  };
};

NotificationContainer.propTypes = {
  createNotification: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

NotificationContainer.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
