import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  li: {
    color: 'black',
    marginBottom: '0.5rem',
    fontSize: '1rem',
  },
  container: {
    padding: '4rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0rem',
    },
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid
        container
        style={{ width: '100%', margin: 0 }}
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
        className={classes.container}
      >
        <Grid item id="termAndConditions">
          <Typography variant="h5" style={{ textDecoration: 'underline' }}>
            Myles High Bakehouse - Terms and Conditions{' '}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Why choose Myles High Bakehouse?
          </Typography>
          <Typography>
            Myles High Bakehouse was Goa’s first live bakery that started a trend of freshly
            bakedartisan bread which were easily accessible. This website now extends the decade
            oldgoodness to everyone in Goa. This website is registered with government of Goa
            andcontains all terms and conditions that stand for all orders placed on this site.{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} id="deliveryTiming">
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Delivery timings
          </Typography>
          <ul>
            <li className={classes.li}>
              Orders placed on day 1 between 11:00 am to 04:00 Pm will be delivered on the nextday
              between 10:00 am to 6:00 pm. All orders made after the mentioned timing shallbe
              considered for delivery on day 3.
            </li>
            <li className={classes.li}>
              Our regular delivery timings are between 10:00 am to 6:00 pm however clients
              canrequest specific timings in the Special Instructions section of payment form, we
              willtry our best to honour the client's request but cannot commit 100% efficiency in
              thetiming of delivery.
            </li>
            <li className={classes.li}>
              Delivery timings if promised cannot be guaranteed in case of special circumstanceslike
              extreme weather conditions, riots, strikes, elections, bandhs, during rush hourdays
              like Valentine's Day, Mother’s Day, Christmas, New year Eve etc
            </li>
            <li className={classes.li}>
              All bulk orders to be placed 48 hours in advance from the delivery date
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Address Change
          </Typography>
          <ul>
            <li className={classes.li}>
              Any changes to the recipient address would be permitted free of cost if they
              arerequested before 48hrs of the actual delivery. Changes after that will not
              beentertained and order would not be cancelled.
            </li>
            <li className={classes.li}>
              Change of address would not be possible for products after dispatch
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Order Change
          </Typography>
          <ul>
            <li className={classes.li}>
              Any changes to the order would be entertained with monetary adjustments ifrequested
              before 48hrs of the actual delivery. Changes after that will not beentertained and
              order would not be cancelled
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            No Cancellation Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>Orders once placed cannot be cancelled</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Return Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              All complaints regarding shortage/ defect of any kind with regards to the products
              should be lodged with the company at the time of receipt itself and no complaints of
              any nature shall be accepted thereafter.
            </li>
            <li className={classes.li}>
              The disputed/ defected items shall be returned at the time of receiving the stock at
              the delivery location.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Refund Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              After we receive back the product, our team of professionals will inspect it and
              process your refund. The money will be refunded to your bank account within 7 working
              days, based on the bank account details emailed to us
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Mishandled deliveries{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              We will cater to any reports of damaged products/ cakes only at the time of delivery
              i.e at the collection point of delivery. The problem report must be supported with
              substantial proofs of the damage made to the product.
            </li>
            <li className={classes.li}>
              If any objection, claim has not been received in writing at the time of receipt or
              acceptance of the item, no claims/ objections will be entertained thereafter.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Delivery Product
          </Typography>
          <ul>
            <li className={classes.li}>
              The Cake designs cannot be guaranteed to be exact replicas of the ones shown on site,
              although the quantity of product will be the same if there have to be substitutions
              because of non-availability of products. These substitutions will be of equal or
              higher quality. The icing and design of the cake may vary from the image depending
              upon local availability.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Rules for delivery{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              Delivery will be made as per the delivery instructions. e.g. if delivery instructions
              state that the product must be delivered to the recipient only, it will not be given
              to any one else and shall be considered delivered if recipient is not available. If
              recipient doesn't accept the delivery, we shall consider the order to be delivered.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse executives will be able to attempt delivery of your items only
              ONCE. In the event the delivery is not executed during the attempt; the customer shall
              still be charged for the order and no redelivery will be possible. We will consider
              the order executed in the below cases:
              <ol style={{ marginTop: '1rem' }}>
                <li className={classes.li}>Wrong shipping/delivery address</li>
                <li className={classes.li}>Recipient not available.</li>
                <li className={classes.li}>Premises locked. </li>
                <li className={classes.li}>
                  Wrong landlines / Mobile number or Extension number which may render us unable to
                  reach the recipient for the delivery.
                </li>
                <li className={classes.li}>Recipient refusing to accept the delivery.</li>
                <li className={classes.li}>
                  Delivered the product at the Gate/ Reception/ Neighbour.
                </li>
              </ol>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Quality Check & Storage of Products{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              All products are thoroughly checked for quality specifications and standards before
              leaving the bakery premises.
            </li>
            <li className={classes.li}>
              Breads, dry items and tea cakes are to be stored in a cool dry place away from direct
              sunlight, not to be stacked on top of each other, to be handled with care and not to
              be frozen.
            </li>
            <li className={classes.li}>Cakes and desserts are to be refrigerated at 5°c - 7°c.</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Substitution Policy{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              Occasionally, substitutions of packaging or containers are necessary due to temporary,
              regional availability issues. If this is the case with the item you have selected, our
              experienced staff will ensure that we will only substitute items of equal or higher
              value.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Rules for Duplicate Orders{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              If Myles High Bakehouse finds that the same order has been made twice we will try to
              contact the customer and get that clarified. If the customer is not contactable we
              will take the decision on our behalf and that will be final.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Dealing with Fraudulent Customers{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              If we find out any fraudulent behaviour from any client in terms of wrong address,
              unethical message, odd delivery timings or any other reason, we hold the power to
              reject that order or ban that customer without being answerable to anyone.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Rules for unfounded recipient{' '}
          </Typography>
          <ul>
            <li className={classes.li}>
              In case the final customer is not available for receiving the placed order and we have
              strict instructions to deliver only to the intended customer the order shall be
              considered delivered and no refund will be made.
            </li>
            <li className={classes.li}>
              We hold the right to extend our services to any individual, organization, group or
              business unit. We may refuse to serve any individual, organization, group or business
              unit solely on our own will and we will not be answerable to anyone.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse may amend its Terms & Conditions from time to time. These terms
              shall be governed by and constructed in accordance with the laws of India without
              reference to conflict of laws principles and disputes arising in relation hereto shall
              be subject to the exclusive jurisdiction of the courts at Goa.
            </li>
            <li className={classes.li}>
              I understand that Myles High Bakehouse can send transactional and promotional messages
              on my registered phone number.
            </li>
            <li className={classes.li}>
              Myles High Bakehouse operates with the objective to provide the best service and
              quality products, and to ensure the same, Myles High Bakehouse might contact the
              receiver seeking the feedback .
            </li>
            <li className={classes.li}>
              If you have any questions/comments about our Terms & Conditions, please email us at
              <span style={{ color: 'blue' }}> Bakehouse@MylesHigh.in</span>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Fragment>
  );
};

TermsAndConditions.propTypes = {
  // loginHandler: PropTypes.func.isRequired,
  // userData: PropTypes.object,
};

TermsAndConditions.defaultProps = {
  // userData: [],
};

export default TermsAndConditions;
