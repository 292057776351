import { handleActions } from 'redux-actions';

import * as actionCreators from '../actionCreators/ui';

const defaultState = {
  loginModal: { isOpen: false },
  registerModal: { isOpen: false },
};

const uiReducer = handleActions(
  {
    [actionCreators.doShowLoginModal]: (state) => {
      return { ...state, loginModal: { isOpen: true } };
    },
    [actionCreators.doHideLoginModal]: (state) => {
      return { ...state, loginModal: { isOpen: false } };
    },
    [actionCreators.doShowRegisterModal]: (state) => {
      return { ...state, registerModal: { isOpen: true } };
    },
    [actionCreators.doHideRegisterModal]: (state) => {
      return { ...state, registerModal: { isOpen: false } };
    },
  },
  defaultState,
);

export default uiReducer;
