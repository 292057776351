import React, { Fragment, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import * as yup from 'yup';
import * as PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
  makeStyles,
  InputLabel,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import MuiAlert from '@material-ui/lab/Alert';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import style from '../../pages/Login/style.module.scss';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { doLogin } from '../../redux/actionCreators/authentication';
import {
  doHideLoginModal,
  doShowLoginModal,
  doShowRegisterModal,
} from '../../redux/actionCreators/ui';
import { selectLoginModal } from '../../redux/selectors/ui';

import { auth } from '../../config/firebase';
import { Close } from '@material-ui/icons';

const loginSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup.string().required('Required'),
});

const forgotSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required'),
});

const useStyles = makeStyles((theme) => ({
  forgotText: {
    width: '100%',
    margin: '10px 0',
    fontSize: '12px',
    cursor: 'pointer',
    color: '#62321E',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function LoginModal() {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectLoginModal);
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotDialog, setForgotDialog] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [successed, setSuccessed] = useState(false);

  const onLogin = (values, { setSubmitting }) => {
    values.email = values.email.toLowerCase();
    dispatch(doLogin(values));
    setSubmitting(false);
  };

  const handleClose = () => {
    dispatch(doHideLoginModal());
  };

  const handleOpenRegister = () => {
    handleClose();
    dispatch(doShowRegisterModal());
  };

  const handleForgot = () => {
    setForgotDialog(true);
    dispatch(doHideLoginModal());
  };

  const forgotHandler = async (values) => {
    const { email } = values;
    if (email) {
      const user = await auth.fetchSignInMethodsForEmail(email);

      if (user && user.length > 0) {
        try {
          await auth.sendPasswordResetEmail(email);
          setVisible(true);
          setMessage('Password reset link sent to the email!');
          setForgotDialog(false);
          setSuccessed(true);
          dispatch(doShowLoginModal());
        } catch (error) {
          setSuccessed(false);
          setMessage('Something went wrong!');
        }
      } else {
        setSuccessed(false);
        setMessage('User Not found!');
        setVisible(true);
      }
    }
  };

  const handleSnackClose = () => {
    setVisible(false);
    setMessage('');
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isVisible}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={handleSnackClose} severity={`${successed ? 'success' : 'error'}`}>
          {message}
        </Alert>
      </Snackbar>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="login-dialog-title">
        <Formik
          initialValues={{
            email: '',
            password: '',
            remember: false,
          }}
          validationSchema={loginSchema}
          onSubmit={onLogin}
        >
          {({ submitForm, isSubmitting }) => (
            <Form
              className={style['container__form']}
              onSubmit={(event) => {
                event.preventDefault();
                submitForm(event);
              }}
            >
              <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Sign In
                </Typography>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <br />
                <Field
                  component={TextField}
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  name="password"
                  fullWidth
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VpnKeyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          <Icon className="text-20" color="action">
                            {showPassword ? 'visibility' : 'visibility_off'}
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Field
                  component={CheckboxWithLabel}
                  name="remember"
                  color="primary"
                  type="checkbox"
                  Label={{ label: 'Remember Me' }}
                /> */}
                <Typography onClick={handleForgot} className={classes.forgotText}>
                  Forgot password?
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isSubmitting}
                  fullWidth
                >
                  Sign In & View Menu
                </Button>
                <div className="spacing__0-25" />
                {/* <Button href="#text-buttons" color="primary" disabled={isSubmitting}>
                  Reset Password
                </Button> */}
                <div className="spacing__0-25" />
                <br />
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  fullWidth
                  disabled={isSubmitting}
                  onClick={handleOpenRegister}
                >
                  Don't have an Account?
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Dialog
        open={openForgotDialog}
        onClose={() => setForgotDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={forgotSchema}
          onSubmit={forgotHandler}
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <Form className={style.container__form}>
              <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
                <Typography variant="h6" gutterBottom align="center">
                  Account Recovery
                </Typography>
                <br />
                <InputLabel style={{ marginTop: '12px', width: '100%' }} htmlFor="password">
                  Enter your email
                </InputLabel>
                <Field
                  component={TextField}
                  name="email"
                  type="text"
                  label="Email"
                  fullWidth
                  variant="filled"
                />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={submitForm}
                  fullWidth
                  style={{ marginTop: '12px' }}
                >
                  Submit
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Fragment>
  );
}

LoginModal.propTypes = {
  authToken: PropTypes.string,
  manageNotification: PropTypes.func.isRequired,
  refreshAuthToken: PropTypes.func.isRequired,
};

LoginModal.defaultProps = {
  authToken: null,
};
